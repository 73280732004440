.btn {
    @apply rounded px-5 text-sm font-semibold cursor-pointer;
    padding-top: 14px;
    padding-bottom: 14px;
    &.btn-book {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    line-height: 14px;
    transition: background 0.4s;
}

.btn.btn-blue {
    @apply bg-blue-400 text-white;
    &:hover {
        @apply bg-blue-600;
    }
}

.btn.btn-blue-dark {
    @apply bg-blue-800 text-white;
    border: 1px solid #0A3F8A;
    transition: background .3s, color .3s, border .3s;
    &:hover {
        @apply bg-blue-600;
        background: #fff;
        border: 1px solid #0A3F8A;
        color: #0A3F8A;
    }
}

.btn.btn-blue-light {
    transition: background-color .3s, color .3s;
    &:hover {
        background: #fff;
        color: #2B3A5A;
    }
}
