/* purgecss start ignore */
@tailwind base;

* {
    @apply font-sans;
}

body {
    @apply bg-grey-100 text-grey-600;
}

@tailwind components;

/* purgecss end ignore */

@tailwind utilities;