.home-resources {
    width: 100%;
    max-width: 1230px;
    margin: 0 auto 180px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1310px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (max-width: 1024px) {
        padding-left: 40px;
        padding-right: 40px;
        margin: 0 auto 60px;
    }
    @media (max-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    .resource-posts {
        flex: 62% 0 0;
        padding-right: 90px;
        @media (max-width: 1310px) {
            padding-right: 40px;
        }
        @media (max-width: 992px) {
            flex: 55% 0 0;
            padding-right: 30px;
        }
        @media (max-width: 768px) {
            flex: 100% 0 0;
            padding-right: 0px;
        }
        .posts-heading {
            color: rgb(43, 58, 90);
            margin-bottom: 65px;
            @media (max-width: 992px) {
                line-height: 40px;
                font-size: 28px;
                margin-bottom: 16px;
            }
        }
        .all-link-mobile {
            display: none;
            margin-bottom: 40px;
            font-size: 16px;
            font-family: Inter;
            font-weight: 600;
            letter-spacing: 0px;
            @media (max-width: 992px) {
                display: block;
            }
        }
        .posts-wrapper {
            @media (max-width: 768px) {
                margin-bottom: 40px;
            }
            a {
                display: block;
                padding-bottom: 28px;
                border-bottom: 1px solid rgba(#858e99, 0.1);
                &:last-child {
                    border-bottom: 0px;
                }
                &:hover {
                    h4 {
                        span {
                            background-size: 100% 1px;
                        }
                    }
                }
                h4 {
                    margin-bottom: 12px;
                    span {
                        color: rgb(43, 58, 90);
                        font-size: 24px;
                        font-family: Barlow;
                        font-weight: 600;
                        letter-spacing: 0px;
                        line-height: 32px;
                        // underline animation stuff
                        background-repeat: no-repeat;
                        background-image: linear-gradient(to right, rgb(43, 58, 90) 0%, rgb(43, 58, 90) 100%);
                        background-position: 0px 95%;
                        background-size: 0px 1px;
                        transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                        padding: 0.6% 0px;
                    }
                    @media (max-width: 768px) {
                        font-size: 20px;
                        line-height: 30px;
                        margin-bottom: 8px;
                    }
                }
                .post-summary {
                    color: rgb(73, 75, 80);
                    font-size: 16px;
                    font-family: Inter;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 28px;
                    margin-bottom: 16px;
                    @media (max-width: 768px) {
                        margin-bottom: 16px;
                    }
                }
                .post-category {
                    color: rgb(73, 75, 80);
                    font-size: 12px;
                    font-family: Inter;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    display: inline-block;
                    margin-right: 12px;
                }
                .post-date {
                    color: rgb(133, 142, 153);
                    font-size: 12px;
                    font-family: Inter;
                    font-weight: normal;
                    letter-spacing: 0.2px;
                }
            }
            a + a {
                padding-top: 28px;
            }
            a:last-child {
                padding-bottom: 0px;
            }
        }
        .loading-block {
            margin-bottom: 35px;
            line-height: 0px;
            .loading-title,
            .loading-content,
            .loading-meta {
                background: #F7F8FC;
                border-radius: 8px;
            }
            .loading-title {
                height: 40px;
                margin-bottom: 12px;
                display: inline-block;
                width: 80%;
            }
            .loading-content {
                height: 60px;
                margin-bottom: 12px;
            }
            .loading-meta {
                display: inline-block;
                width: 40%;
                height: 28px;
            }
        }
    }
    .resource-featured {
        flex: 38% 0 0;
        @media (max-width: 992px) {
            flex: 45% 0 0;
        }
        @media (max-width: 768px) {
            flex: 100% 0 0;
        }
        .all-link-desktop {
            text-align: right;
            line-height: 54px;
            margin-bottom: 60px;
            @media (max-width: 992px) {
                display: none;
            }
        }
        .featured-wrapper {
            display: block;
            background: rgb(255, 255, 255);
            border: 0.8px solid rgb(234, 239, 246);
            box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.06);
            border-radius: 8px;
            transition: all 0.3s;
            overflow: hidden;
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0px 22px 50px 0px  rgba(0, 0, 0, 0.10);
            }
            @media (max-width: 768px) {
                border: 0.8px solid rgb(246, 248, 251);
            }
            img {
                display: block;
                width: 100%;
            }
            .featured-content {
                padding: 30px;
                h4 {
                    color: rgb(43, 58, 90);
                    font-size: 24px;
                    font-family: Barlow;
                    font-weight: 600;
                    letter-spacing: 0px;
                    margin-bottom: 12px;
                }
                p {
                    color: rgb(73, 75, 80);
                    font-size: 16px;
                    font-family: Inter;
                    font-weight: 300;
                    letter-spacing: 0px;
                    line-height: 28px;
                    margin-bottom: 20px;
                }
                .link-text {
                    color: rgb(73, 75, 80);
                    font-size: 12px;
                    font-family: Inter;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                }
            }
        }
    }
}