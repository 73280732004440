.matrix-team {
    @apply bg-grey-100;
    padding: 70px 0;
    h4 {
        letter-spacing: 2px;
    }
    h1 {
        max-width: 500px;
        font-size: 48px;
        line-height: 60px;
        margin-top: 27px;
    }
    .members {
        margin-top: 40px;
        @screen lg {
            margin-top: -145px;
        }
    }
}