@keyframes scrollIcons {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(-50%);
    }
}
.matrix-icons {
    position: relative;
    overflow: hidden;
    .scrolling-icons-block {
        width: 1230px;
        padding-bottom: 77px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .text {
            width: 500px;
            margin: 40px 0 0;
            @media (max-width: 768px) {
                margin-top: 0px;
            }
            padding-bottom: 92px;

            font-family: Helvetica;
            font-size: 16px;
            color: #494B50;
            letter-spacing: 0;
            line-height: 28px;
        }
        .scrolling-icons {
            width: 500px;
            height: 550px;
            margin-bottom: 90px;
            margin-right: 13px;
            overflow: hidden;
            .icon-column {
                display: inline-flex;
                flex-direction: column;
                margin-right: 42px;
                animation: scrollIcons 40s infinite linear;
                &:nth-child(2) {
                    animation-direction: reverse;
                }
                &:nth-child(3) {
                    animation-delay: 1s;
                }

                .scrolling-icon {
                    border-radius: 8px;
                    height: 105px;
                    width: 105px;

                    margin-bottom: 46px;
    
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding: 16px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        @media (max-width: 1230px) {
            width: 100%;
            flex-direction: column-reverse;
            .text {
                width: 100%;
                padding: 40px;
                @media (max-width: 768px) {
                    padding: 20px;
                }
                padding-bottom: 20vw;
            }
            .scrolling-icons {
                width: 100%;
                height: auto;
                padding-top: 60px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                margin: 0;
                .icon-column {
                    display: flex;
                    flex-direction: row;
                    animation: none;
                    width: 100%;
                    margin-right: 0;
                    justify-content: center;
                    .scrolling-icon {
                        flex-shrink: 0;
                        margin-right: 20px;
                        margin-bottom: 20px;
                        height: 48px;
                        width: 48px;
                        padding: 7px;
                        border-radius: 4px;
                        &.duplicate-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .the-slant-tm {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 2000px;
        min-width: 100%;
        margin-left: -1000px;
        @media (min-width: 2000px), (max-width: 768px) {
            left: 0;
            right: 0;
            width: 100%;
            margin-left: 0;
        }
        svg {
            width: 100%;
            height: auto;
        }
    }
}