.home-testimonials {
    width: 100%;
    max-width: 1230px;
    margin: auto;
    @media (max-width: 1400px) {
        width: calc(100% - 80px);
        margin: 0 40px;
    }
    @media (max-width: 768px) {
        width: calc(100% - 40px);
        margin: 0 20px;
    }
    .testimonial-slides {
        position: relative;
        width: 100%;
        height: 500px;
        @media (max-width: 1023px) {
            height: 730px;
        }
        @media (max-width: 640px) {
            height: 700px;
        }
        @media (max-width: 520px) {
            height: 640px;
        }
        @media (max-width: 450px) {
            height: 580px;
        }
        .testimonial {
            width: 100%;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
            @media (max-width: 1023px) {
                flex-direction: column;
            }
            &.active {
                opacity: 1;
                pointer-events: initial;
            }
            .video {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                @media (max-width: 1023px) {
                    width: 100%;
                }
                .video-thumbnail {
                    height: 476px;
                    width: 599px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-radius: 8px;
                    overflow: hidden;
                    @media (max-width: 750px) {
                        height: auto;
                    }

                    .background-image {
                        // position: absolute;
                        // top: 0;
                        // left: 0;
                        // right: 0;
                        // bottom: 0;
                        // background-size: cover;
                        // background-position: center;
                        // background-repeat: no-repeat;
                        transition: transform 0.3s;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(#000, 0.3);
                            opacity: 0;
                            transition: opacity 0.3s;
                        }
                    }
                    .play-icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -37px;
                        margin-left: -37px;
                        svg {
                            height: 74px;
                            width: 74px;
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: 6px solid white;
                            border-radius: 50%;
                            transition: transform 0.3s 0.05s;
                        }
                        @media (max-width: 768px) {
                            margin: -8px 0 0 0;
                            transform: translate(-50%, -50%);
                            svg {
                                height: 43px;
                                width: 43px;
                            }
                        }
                    }
                    .play-case {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 88px;
                        margin-top: 65px;
                        margin-left: -44px;
                        text-align: center;
                        text-transform: uppercase;
                        color: rgb(255, 255, 255);
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: bold;
                        height: 20px;
                        letter-spacing: 2px;
                        line-height: 20px;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 0%;
                            height: 1px;
                            background: white;
                            transition: width 0.3s;
                        }
                        @media (max-width: 768px) {
                            margin: 35px 0 0 0;
                            transform: translate(-50%, -50%);
                            font-size: 10px;
                            letter-spacing: 1.67px;
                        }
                    }
                }
            }
            .testimonial-content {
                display: flex;
                width: 50%;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-left: 70px;
                @media (max-width: 1023px) {
                    width: 100%;
                    margin-left: 0;
                }
                .logo {
                    @media (max-width: 1023px) {
                        margin: 43px 0 22px;
                    }
                    @media (max-width: 768px) {
                        margin: 22px 0 4px;
                    }
                    img {
                        &.logo-desktop {
                            width: auto;
                            height: 69px;
                        }
                        &.logo-mobile {
                            display: none;
                        }
                        @media (max-width: 768px) {
                            min-height: 47px;
                            &.logo-mobile {
                                display: block;
                            }
                            &.logo-desktop {
                                display: none;
                            }
                        }
                    }
                }
                .quote {
                    color: rgb(40, 58, 93);
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 50px;
                    margin: 16px 0 36px;
                    font-family: Inter;
                    min-height: 280px;
                    @media (max-width: 1023px) {
                        font-size: 22px;
                        line-height: 35px;
                        min-height: 0;
                    }
                    @media (max-width: 768px) {
                        font-size: 18px;
                        line-height: 30px;
                        margin-bottom: 20px;
                    }
                }
                .testimonial-person-name {
                    color: rgb(73, 75, 80);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 28px;
                }
                .testimonial-person-title {
                    color: rgb(73, 75, 80);
                    font-size: 16px;
                    line-height: 28px;
                }
            }
            &.has-video {
                .video {
                    .video-thumbnail {
                        cursor: pointer;
                        &:hover {
                            .background-image {
                                transform: scale(1.03);
                                &:before {
                                    opacity: 1;
                                }
                            }
                            .play-icon {
                                &:before {
                                    transform: scale(1.1);
                                }
                            }
                            .play-case {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            &.no-video {
                .video {
                    .video-thumbnail {
                        .play-icon {
                            display: none;
                        }
                        .play-case {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.single-slide {
        .testimonial-controls {
            display: none;
        }
        .testimonial-slides {
            height: auto;
            margin-bottom: 180px;
            @media (max-width: 1024px) {
                margin-bottom: 120px;
            }
            @media (max-width: 768px) {
                margin-bottom: 80px;
            }
            .testimonial {
                position: initial;
            }
        }
    }
    .testimonial-controls {
        margin-top: 120px;
        margin-bottom: 180px;
        @media (max-width: 768px) {
            margin-top: 60px;
            margin-bottom: 80px;
        }
        @media (max-width: 600px) {
            margin-top: 20px;
            margin-bottom: 40px;
        }
        display: flex;
        .control {
            cursor: pointer;
            width: 33.33%;
            padding-right: 20px;
            transition: transform 0.4s;
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:only-child {
                display: none;
            }
            .school {
                color: rgb(73, 75, 80);
                font-family: Barlow;
                font-size: 21px;
                font-weight: 600;
                line-height: 30px;
                margin-bottom: 12px;
            }
            .description {
                color: rgb(73, 75, 80);
                font-size: 16px;
                line-height: 28px;
            }
            @media (max-width: 1023px) {
                .school,
                .description {
                    display: none;
                }
            }
            .line-element {
                transform: translateY(0px);
                position: relative;
                height: 2px;
                background-color: #e7e8eb;
                margin-bottom: 42px;
                &:before {
                    content: '';
                    position: absolute;
                    background-color: #3e89f6;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 0%;
                    transition: width 0.1s 0.4s;
                }
                &:after {
                    content: '';
                    position: absolute;
                    background-color: #e7e8eb;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    transition: width 0.4s linear;
                }
            }
            &.active {
                transform: translateY(-20px);
                @media (max-width: 1023px) {
                    transform: none;
                }
                .line-element {
                    &:before {
                        transition: width 5s linear;
                        width: 100%;
                    }
                    &:after {
                        transition: width 0.1s;
                        width: 0%;
                    }
                }
            }
            &:hover {
                transform: translateY(-20px);
                @media (max-width: 1023px) {
                    transform: none;
                }
            }
        }
    }
}
