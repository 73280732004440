.matrix-featureText {
    letter-spacing: 0;
    line-height: 36px;
    p {
        @apply font-barlow text-2xl text-grey-400;
        max-width: 705px;
    }
    .justify-center {
        @screen md {
            padding-left: 100px;
        }
    }
}