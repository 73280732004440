.matrix-contactPage {
    .contact-container {
        width: 1230px;
        margin: 0px auto 130px;
        @media (max-width: 1230px) {
            width: calc(100% - 80px);
            margin: 0px 40px 60px;
        }
        @media (max-width: 768px) {
            width: calc(100% - 40px);
            margin: 0px 20px 60px;
        }
        .columns-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .column {
                @media (max-width: 768px) {
                    flex-basis: 100% !important;
                }
                &.column-one {
                    flex: 0 0 calc(66.3% - 55px);
                    padding-right: 55px;
                    .text {
                        margin-bottom: 30px;
                    }
                    @media (max-width: 768px) {
                        order: 2;
                        padding-right: 0px;
                    }
                }
                &.column-two {
                    flex: 0 0 31.8%;
                    @media (max-width: 768px) {
                        order: 1;
                        margin-bottom: 60px;
                    }
                    .sidebar-title.subheading {
                        margin-top: 175px;
                        color: #A4ABB3;
                        @media (max-width: 768px) {
                            margin-top: 65px;
                            margin-bottom: 24px;
                        }
                    }
                    .sidebar-links {
                        a {
                            display: block;
                            padding: 45px 0px;
                            border-top: 1px solid rgba(133, 142, 153, 0.1);
                            color: #494b50;
                            font-size: 21px;
                            font-weight: 500;
                            font-family: Barlow;
                            letter-spacing: 0px;
                            position: relative;
                            @media (max-width: 768px) {
                                font-size: 18px;
                                padding: 32px 0px;
                            }
                            span.internal-link {
                                position: absolute;
                                right: 21px;
                                top: 50%;
                                transform: translateY(-50%);
                                transition: right .3s;
                                svg {
                                    width: 20px;
                                    color: #838e9a;
                                }
                            }
                            &:hover {
                                span.internal-link {
                                    right: 15px;
                                }
                            }
                        }
                    }
                    .sidebar-content {
                        border-top: 1px solid rgba(133, 142, 153, 0.1);
                        border-bottom: 1px solid rgba(133, 142, 153, 0.1);
                        padding: 45px 0px;
                        @media (max-width: 768px) {
                            padding: 32px 0px;
                            p + p {
                                margin-top: 12px;
                            }
                        }
                        a {
                            color: rgb(43, 58, 90);
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0px;
                            text-decoration: none;
                            position: relative;
                            padding-bottom: 4px;;
                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 100%;
                                width: 0%;
                                height: 1px;
                                background: currentColor;
                                transition: width .3s;
                            }
                            &:hover {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .form {
        input,
        select,
        textarea {
            border-radius: 4px;
            border: 1px solid rgb(210, 214, 220);
            line-height: 46px;
            &[type="text"],
            &[type="email"],
            &[type="tel"] {
                padding: 2px 15px 0px;
                // remove default input shadow on ios
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
            &:hover {
                border-color: #9fa6b2;
            }
            &:focus,
            &:active {
                outline-style: none;
                box-shadow: 0 0 0 3px rgba(62,138,246,.2);
                border-color: #3e8af6;
                &.error {
                    outline-color: rgba(244, 86, 86, 0.16);
                    box-shadow: 0 0 0 3px rgba(244, 86, 86, 0.16);
                }
            }
            &::placeholder {
                color: rgb(73, 75, 80);
                opacity: 0.4;
                font-weight: 300;
            }
        }
        textarea {
            min-height: 161px;
            padding: 0 15px;
            // remove default input shadow on ios
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        select {
            height: 50px;
        }
        .input {
            margin-right: 0px;
            input {
                width: 100%;
            }
        }
        .submitted-message {
            background: rgb(246, 248, 251);
            border-radius: 0px;
            padding: 40px;
            @media (max-width: 768px) {
                padding: 30px;
            }
            p {
                color: rgb(73, 75, 80);
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 28px;
                strong {
                    color: rgb(73, 75, 80);
                    font-family: Barlow;
                    font-size: 21px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 30px;
                }
                @media (max-width: 768px) {
                    strong {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
            p + p {
                margin-top: 10px;
            }
        }
        .hs-form {
            fieldset {
                max-width: 100%;
                & > div {
                    margin-bottom: 20px;
                }
                label {
                    > span {
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                }
                &.form-columns-2 {
                    display: flex;
                    justify-content: space-between;
                    > div {
                        flex: 0 0 48.5%;
                        float: none;
                        width: auto;
                    }
                    @media (max-width: 768px) {
                        flex-wrap: wrap;
                        > div {
                            flex-basis: 100%;
                            &:first-child {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
                .hs-input {
                    width: 100% !important;
                    &.error {
                        border: 1px solid rgb(244, 86, 86);
                    }
                }
            }
            .input + .hs-error-msgs {
                margin-top: 6px;
            }
            .hs-error-msg {
                color: #f45656;
                font-weight: 500;
                font-size: 14px;
            }
            .hs-form-field {
                label {
                    span {
                        color: rgb(73, 75, 80);
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.2px;
                        &.hs-form-required {
                            display: none;
                        }
                    }
                }
                &.hs-fieldtype-textarea {
                    div.input {
                        line-height: 0;
                    }
                }
            }
            .hs_error_rollup {
                margin-bottom: 30px;
                .hs-error-msgs {
                    > li {
                        label {
                            position: relative;
                            background: rgba(244, 86, 86, 0.08);
                            border-radius: 0px;
                            padding: 11px 20px;
                            display: block;
                            padding-left: 44px;
                            color: #f45656;
                            font-size: 14px;
                            font-weight: 400; // regularmedium regular 400 medium 500
                            letter-spacing: 0px;
                            &:before {
                                content: url('/assets/alert-circle.svg');
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 15px;
                                height: 23px;
                            }
                        }
                    }
                }
            }
            .hs-submit {
                margin-top: 28px;
            }
        }
        input[type="submit"] {
            color: #fff;
            background: #3e8af6;
            padding: 1px 28px;
            border: 1px solid #3e8af6;
            transition: background .3s, border .3s, color .3s;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            text-align: center;
            cursor: pointer;
            &:hover {
                background: #4C80DC;
                border: 1px solid #4C80DC;
            }
        }
        .hs-fieldtype-booleancheckbox {
            padding-top: 5px;
            margin-bottom: 0px !important;
            .hs-form-booleancheckbox-display {
                display: inline-block;
                cursor: pointer;
                position: relative;
                line-height: 24px;
                input {
                    margin-right: 12px;
                    height: 20px !important;
                    width: 20px !important;
                    margin-top: 1px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -o-appearance: none;
                    appearance: none;
                    border: 1px solid rgb(210, 214, 220);
                    border-radius: 4px;
                    outline: none;
                    transition-duration: 0.3s;
                    background-color: #FFF;
                    display: inline-block;
                    cursor: pointer;
                    vertical-align: top;
                    &:checked {
                        border: 1px solid #2b3a5a;
                        background-color: #2b3a5a;
                    }
                }
                &:hover {
                    input {
                        border-color: #9fa6b2;
                    }
                }
                span {
                    max-width: calc(100% - 32px);
                    line-height: 24px;
                    display: inline-block;
                    color: rgb(73, 75, 80) !important;
                    font-size: 16px !important;
                    font-family: Inter !important;
                    font-weight: normal !important;
                    letter-spacing: initial !important;
                    vertical-align: top;
                }
                input:checked + span::before {
                    content: '';
                    background-image: url('/assets/tick.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 12px;
                    width: 12px;
                    display: block;
                    text-align: center;
                    position: absolute;
                    left: 4px;
                    top: 6px;
                }
                & > input:active {
                    border: 2px solid #34495E;
                }
            }
        }
    }
    .locations-container {
        background: #2b3a5a;
        padding: 130px 0px;
        @media (max-width: 768px) {
            padding: 60px 0px;
        }
        .locations {
            width: 1230px;
            margin: 0px auto;
            display: flex;
            justify-content: space-between;
            @media (max-width: 1230px) {
                width: calc(100% - 80px);
                margin-left: 40px;
                margin-right: 40px;
            }
            @media (max-width: 768px) {
                flex-wrap: wrap;
                width: calc(100% - 40px);
                margin-left: 20px;
                margin-right: 20px;
            }
            .location {
                flex: 0 0 48.5%;
                @media (max-width: 768px) {
                    flex-basis: 100%;
                    margin-bottom: 40px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
                img {
                    width: 100;
                    margin-bottom: 34px;
                    @media (max-width: 768px) {
                        margin-bottom: 20px;
                    }
                }
                .subheading,
                .link,
                p {
                    margin-bottom: 10px;
                }
                .subheading {
                    span {
                        display: inline-block;
                        margin-left: 10px;
                        opacity: 0.5;
                        font-weight: 500;
                    }
                }
                .link-phone {
                    a {
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                * {
                    color: #ffffff;
                }
            }
        }
    }
}

