.matrix-featureList {
    position: relative;
    .feature-list-content {
        width: 1230px;
        padding-bottom: 60px;
        margin: auto;
        display: flex;
        .mobile-text {
            display: none;
        }
        .feature-image {
            display: flex;
            align-items: center;
            .mobile-image {
                display: none;
            }
        }
        .feature-list {
            margin-left: 80px;
            width: 375px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .feature-icon {
                margin-bottom: 20px;
                svg {
                    width: 32px;
                }
            }
            .feature-item {
                margin-bottom: 57px;
                h5 {
                    @media (min-width: 769px) {
                        margin-bottom: 12px;
                    }
                    margin-bottom: 7px;
                }
                font-family: Helvetica;
                font-size: 16px;
                color: #494B50;
                letter-spacing: 0;
                line-height: 28px;
                width: 100%;
            }
        }
        &.image-right {
            // @media (min-width: 768px) and (max-width: 1024px) {
            //     padding: 40px;
            // }
            flex-direction: row-reverse;
            .feature-list {
                margin-left: 0px;
                margin-right: 80px;
            }
        }
        &.image-top {
            flex-direction: column;
            .feature-image {
                width: 100%;
                margin: auto;
                @media (min-width: 769px) {
                    margin-top: -60px !important;
                    margin-bottom: 64px !important;
                }
            }
            .feature-list {
                flex-direction: row;
                width: 100%;
                margin: 0;
                .feature-text {
                    width: 80%;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
        }
        &.image-size-default {
            .feature-image {
                margin-left: -35px;
                margin-top: -40px;
                margin-bottom: 30px;
            }
        }
        &.image-size-larger {
            .feature-image {
                img {
                    max-width: 175%;
                    // the container becomes smaller below 1230px, so we want the image back to 175% below that point
                    @media (max-width: 1600px) and (min-width: 1230px) {
                        max-width: 150%;
                    }
                }
            }
            &.image-left {
                .feature-image {
                    width: 50%;
                    position: relative;
                    @media (max-width: 992px) {
                        width: 100%;
                    }
                    img {
                        @media (min-width: 992px) {
                            position: absolute;
                            right: 0;
                            width: 175%;
                        }
                        @media (max-width: 992px) {
                            position: relative;
                            right: auto;
                            width: 130%;
                            margin-left: -30%;
                        }
                        @media (max-width: 768px) {
                            position: relative;
                            right: auto;
                            width: 175%;
                            margin-left: -75%;
                        }
                    }
                }
            }
        }
        &.text-width-larger {
            .feature-list {
                > .text {
                    margin-bottom: 90px;
                }
                width: 475px;
                flex-shrink: 0;
                margin-right: 120px;
            }
            &.image-left {
                .feature-list {
                    margin-right: 15px;
                }
            }
        }
        &.has-background-colour {
            padding-top: 70px;
            @media (max-width: 768px) {
                padding-top: 0px;
            }
        }
        &.has-text-colour {
            .mobile-text {
                &, * {
                    color: currentColor;
                }
            }
            .text {
                &, * {
                    color: currentColor;
                }
            }
            .feature-list {
                .feature-item {
                    &, * {
                        color: currentColor;
                    }
                }
            }
        }
        @media (max-width: 1230px) {
            width: 100%;
            &.image-left {
                .feature-image {
                    .desktop-image {
                        padding-left: 40px;
                    }
                }
            }
        }
        @media (min-width: 1023px) and (max-width: 1230px) {
            padding-left: 40px;
            padding-right: 40px;
        }
        // all the elements already have 20px padding on mobile, so we just want to pad it a bit extra for tablet (not amazing but saves a lot of time)
        @media (min-width: 992px) and (max-width: 1023px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @media (max-width: 1023px) {
            .feature-list {
                margin-left: 30px;
                .feature-item {
                    padding: 20px;
                    margin-bottom: 0px;
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 28px;
                }
                > .text {
                    padding: 20px;
                }
            }
            .basic-link {
                margin-left: 20px;
                margin-top: 20px;
            }
        }
        @media (max-width: 992px) {
            .feature-list {
                width: 100%;
                margin-left: 0px;
            }
            .feature-image {
                width: 100%;
            }
        }
        @media (max-width: 992px) {
            .card-link {
                position: relative;
                z-index: 1;
            }
            &, &.image-right, &.image-left, &.image-top {
                &.has-background-colour {
                    .mobile-text {
                        padding-top: 60px;
                    }
                }
                flex-direction: column;
                padding-bottom: 40px;
                .mobile-text {
                    display: block;
                    padding: 20px;
                    &:empty {
                        display: none;
                    }
                }
                .feature-list {
                    width: 100%;
                    .feature-item {
                        padding-right: 30px;
                    }
                    > .text {
                        width: 100%;
                        display: none;
                    }
                }
                .feature-image {
                    width: 100%;
                    padding: 0 20px;
                    margin: 0 auto 5px;
                    .desktop-image {
                        display: none;
                    }
                    .mobile-image {
                        display: block;
                        width: 70%;
                        margin: 0 auto;
                    }
                }
                &.image-top {
                    .feature-list {
                        flex-direction: column;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            &, &.image-right, &.image-left, &.image-top {
                .feature-image {
                    .mobile-image {
                        width: 100%;
                    }
                }
            }
        }
    }
}