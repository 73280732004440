// $app-primary: #00B691;
// $app-secondary: #00434B;

// $funnel-primary: #5C6CFF;
// $funnel-secondary: #2B2256;

// $website-primary: #00ACCF;
// $website-secondary: #00D0E7;

// $master-primary: #3E8AF6;
// $master-secondary: #2B3A5A;

@import 'banner';
@import 'imageHeader';
@import 'textAndLogos';
@import 'logos';
@import 'footerCta';
@import 'highlights';
@import 'offers';
@import 'customerQuote';
@import 'customerStory';
@import 'form';

.campaign-page {
    .wrapper {
        padding: 70px 20px;
        @screen lg {
            max-width: 1270px;
            margin: auto;
            padding: 80px 40px;
        }
        @screen xl {
            padding: 150px 40px;
        }
        .streamline-stroke-icon-campaign svg * {
            stroke: currentColor;
            fill: none;
        }
    }
}
