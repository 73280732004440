.page  {
    .page-bg {
        height: 510px;
        @apply pointer-events-none absolute inset-x-0 top-0 bg-blue-800;
        + .page-header {
            @apply bg-blue-800;
        }
    }
    .page-angled-bg {
        top: -600px;
        height: 1545px;
        transform: skewY(-22.5deg);
        @apply pointer-events-none absolute inset-x-0 bg-blue-800;
        + .page-header {
            padding-bottom: 268px;
            h1 {
                @apply font-barlow;
                font-size: 48px;
                line-height: 60px;
            }
            p {

            }
        }
    }
}

.page-header {
    position: relative;
    z-index: 10;
    padding-top: 85px;
    padding-bottom: 153px;
    h4 {
        letter-spacing: 2px;
        line-height: 15px;
    }
    h1 {
        font-size: 60px;
        letter-spacing: 0px;
        margin-top: 18px;
    }
    p {
        margin: 27px auto;
        letter-spacing: 0;
        line-height: 36px;
        font-size: 21px;
    }
    .visual {
        @apply bg-white mx-auto;
        box-shadow: 15px 15px 60px 0 rgba(0,0,0,0.05);
        border-radius: 10px;
        height: 513px;
        max-width: 915px;
        margin-top: 118px;
    }
}

.page-content {
    .page-sidebar {
        @apply relative;
        padding: 130px 80px;
        &:before {
            content: '';
            top: 120px;
            bottom: 130px;
            left: 0px;
            @apply absolute border-l border-neutral-100;
        }
        h5 {
            letter-spacing: 0px;
            margin-bottom: 40px;
        }
        ul {
            li {
                display: block;
                margin-bottom: 28px;
                a {
                    @apply text-grey-600 opacity-50;
                    letter-spacing: 0px;
                    line-height: 28px;
                    transition: opacity 0.3s;
                    &:hover,
                    &.is-active {
                        @apply opacity-100;
                    }
                }
            }
        }
    }
 }

 .policy-info {
    display: table;
    border: 1px solid #dee2eb;
    padding: 25px 60px 25px 32px;
    margin: 70px 0;
 }