.demo-page {
    display: flex;
    // justify-content: center;
    // align-items: flex-start;
    flex-direction: column;
    align-items: center;
    background-color: #f6f8fb;
    padding-top: 80px;
    @media (max-width: 1023px) {
        padding-top: 70px;
    }
    .matrix-imageHeader {
        display: none;
    }
    &.demo-watch-active {
        background: none;
        @media (max-width: 768px) {
            padding-top: 0px;
        }
        .matrix-imageHeader {
            display: block;
        }
    }
    .matrix-imageHeader.demo-image-header {
        .image-header.size-oversize.style-regular {
            min-height: 0px;
            padding: 0px;
            &:after {
                width: 245vw;
                @media (max-width: 1024px) {
                    width: 345vw;
                }
                @media (max-width: 768px) {
                    width: 569vw;
                    height: 162vw;
                    left: -95vw;
                }
            }
        }
    }
    .demo-about {
        position: relative;
        display: flex;
        align-items: flex-start;
        width: 1230px;
        padding-bottom: 100px;
        &.demo-hidden {
            display: none;
        }
        @media (min-width: 1024px) and (max-width: 1340px) {
            .demo-banner {
                padding-left: 40px;
            }
            .demo-content {
                padding-left: 40px;
                margin-top: 45px;
            }
        }
        @media (max-width: 1023px) {
            flex-direction: column;
            width: 100%;
            margin-right: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
        .demo-banner {
            position: absolute;
            width: 50%;
            color: white;
            @media (max-width: 1023px) {
                position: relative;
                width: 100%;
            }
            &.demo-banner-expanded {
                .demo-banner-content {
                    padding-top: 60px;
                    padding-bottom: 140px;
                    @media (max-width: 1024px) {
                        padding-top: 0px;
                        padding-bottom: 0px;
                        .demo-subheading {
                            margin-bottom: 22px;
                        }
                        .demo-lead {
                            padding-bottom: 96px;
                        }
                    }
                }
            }
            .demo-banner-background {
                position: absolute;
                bottom: 0;
                top: -280px;
                left: 100%;
                width: 200vw;
                margin-left: -50vw;
                @media (min-width: 1024px) and (max-width: 1340px) {
                    margin-left: -70vw;
                }
                @media (max-width: 1023px) {
                    left: 50%;
                }
            }
            .demo-banner-content {
                position: relative;
                padding-top: 80px;
                padding-right: 80px;
                @media (max-width: 1023px) {
                    padding: 0 20px;
                }
                .demo-subheading {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-bottom: 28px;
                    @media (max-width: 1023px) {
                        margin-bottom: 8px;
                    }
                    .demo-subheading-logo {
                        width: 30px;
                        height: 30px;
                        margin-right: 16px;
                    }
                }
                .demo-heading {
                    color: white;
                    @media (max-width: 1023px) {
                        font-size: 32px;
                        line-height: 44px;
                    }
                }
                .demo-lead {
                    display: block;
                    color: rgb(255, 255, 255);
                    font-family: Barlow;
                    font-size: 22px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    line-height: 36px;
                    padding-bottom: 80px;
                    @media (max-width: 1023px) {
                        font-size: 19px;
                        letter-spacing: 0px;
                        line-height: 31px;
                        padding-bottom: 87px;
                    }
                }
            }
        }
        .demo-content {
            width: 50%;
            order: 2;
            margin-top: 480px;
            padding-right: 80px;
            @media (max-width: 1023px) {
                width: 100%;
                padding: 0 20px;
                margin-top: 47px;
                order: 3;
            }
            > div {
                margin: 47px 0 60px;
                @media (max-width: 1023px) {
                    margin: 0 0 42px;
                }
            }
            .demo-list {
                h3 {
                    line-height: 42px;
                    @media (max-width: 1023px) {
                        font-size: 28px;
                        line-height: 39px;
                    }
                }
                @media (min-width: 1024px) and (max-width: 1050px) {
                    margin-top: 120px;
                }
                .demo-list-items {
                    .demo-list-item {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 12px;
                        .demo-list-item-svg {
                            margin-right: 12px;
                            display: flex;
                            align-items: center;
                            height: 28px;
                            g[stroke] {
                                stroke: currentColor;
                            }
                        }
                        .demo-list-item-text {
                            color: rgb(73, 75, 80);
                            font-size: 14px;
                            font-weight: normal;
                            letter-spacing: 0px;
                            line-height: 28px;
                        }
                    }
                }
            }
            .demo-logos {
                .demo-logos-pre-heading {
                    color: rgb(43, 58, 90);
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                    @media (max-width: 1023px) {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
                .demo-logos-items {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    .logo-item {
                        width: calc(33.33% - 10px);
                        margin-bottom: 28px;
                        @media (max-width: 1023px) {
                            width: 50%;
                            margin-bottom: 40px;
                        }
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 62px;
                        img {
                            width: auto;
                            height: 25px;
                        }
                    }
                    .placeholder {
                        width: 33.33%;
                        @media (max-width: 1023px) {
                            width: 50%;
                        }
                    }
                }
            }
            .demo-image {
                transform: translateY(-60px);
                img {
                    position: relative;
                    z-index: 10;
                }
                @media (max-width: 1024px) {
                    // transform: none;
                }
            }
        }
        .demo-form {
            position: relative;
            width: 50%;
            order: 3;
            min-height: 1000px;
            background: white;
            box-shadow: 0px 12px 36px 0px rgba(10, 63, 138, 0.14);
            margin-bottom: 30px;
            padding: 28px 60px 60px;
            @media (min-width: 1024px) and (max-width: 1340px) {
                margin-left: 0;
                margin-right: 40px;
            }
            @media (max-width: 1023px) {
                position: relative;
                top: auto;
                left: auto;
                width: calc(100% - 40px);
                margin: -50px 20px 0;
                min-height: 300px;
                padding: 10px 20px 32px;
                order: 2;
            }
            .demo-spinner {
                @keyframes spinner {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50px;
                height: 50px;
                margin-top: -25px;
                margin-left: -25px;
                border-radius: 50%;
                border: 6px dotted rgba(0,0,0,0.3);
                animation: spinner 2s infinite linear;
            }
            .form {
                position: relative;
                background: white;
                label:not(.hs-error-msg) {
                    display: block;
                    margin: 22px 0 6px;
                    @media (max-width: 1023px) {
                        margin: 18px 0 2px;
                    }
                }
                .hs-fieldtype-select {
                    .input {
                        position: relative;
                        &:after {
                            content: '';
                            position: absolute;
                            display: block;
                            top: 15px;
                            right: 18px;
                            width: 15px;
                            height: 20px;
                            background-image: url('data:image/svg+xml;utf8,<svg stroke="dimgray" fill="none" width="8" height="5" viewBox="0 0 8 5" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L4 4L7 1" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            transition: transform .3s;
                            pointer-events: none;
                        }
                        select {
                            appearance: none;
                            -webkit-appearance: none;
                            line-height: 20px;
                            background: #FFF;
                        }
                    }
                }
                input,
                select,
                textarea {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid rgb(210, 214, 220);
                    line-height: 46px;
                    &[type="text"],
                    &[type="email"],
                    &[type="tel"] {
                        padding: 2px 15px 0px;
                        // remove default input shadow on ios
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }
                    &:hover {
                        border-color: #9fa6b2;
                    }
                    &:focus,
                    &:active {
                        outline-style: none;
                        box-shadow: 0 0 0 3px rgba(62,138,246,.2);
                        border-color: #3e8af6;
                        &.error {
                            outline-color: rgba(244, 86, 86, 0.16);
                            box-shadow: 0 0 0 3px rgba(244, 86, 86, 0.16);
                        }
                    }
                    &::placeholder {
                        color: rgb(73, 75, 80);
                        opacity: 0.4;
                        font-weight: 300;
                    }
                }
                textarea {
                    min-height: 161px;
                    padding: 0 15px;
                    line-height: 26px;
                    padding-top: 10px !important;
                    // remove default input shadow on ios
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                select {
                    height: 50px;
                    padding: 11px 16px;
                }
                .hs-fieldtype-booleancheckbox {
                    padding-top: 5px;
                    .hs-form-booleancheckbox-display {
                        display: inline-block;
                        cursor: pointer;
                        position: relative;
                        line-height: 24px;
                        input {
                            margin-right: 12px;
                            height: 20px !important;
                            width: 20px !important;
                            margin-top: 1px;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            -o-appearance: none;
                            appearance: none;
                            border: 1px solid rgb(210, 214, 220);
                            border-radius: 4px;
                            outline: none;
                            transition-duration: 0.3s;
                            background-color: #FFF;
                            display: inline-block;
                            cursor: pointer;
                            vertical-align: top;
                            &:checked {
                                border: 1px solid #2b3a5a;
                                background-color: #2b3a5a;
                            }
                        }
                        &:hover {
                            input {
                                border-color: #9fa6b2;
                            }
                        }
                        span {
                            max-width: calc(100% - 32px);
                            line-height: 24px;
                            display: inline-block;
                            color: rgb(73, 75, 80) !important;
                            font-size: 16px !important;
                            font-family: Inter !important;
                            font-weight: normal !important;
                            letter-spacing: initial !important;
                            vertical-align: top;
                        }
                        input:checked + span::before {
                            content: '';
                            background-image: url('/assets/tick.svg');
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            height: 12px;
                            width: 12px;
                            display: block;
                            text-align: center;
                            position: absolute;
                            left: 4px;
                            top: 6px;
                        }
                        & > input:active {
                            border: 2px solid #34495E;
                        }
                    }
                }
                .input {
                    margin-right: 0px;
                    input {
                        width: 100%;
                    }
                }
                .submitted-message {
                    background: none;
                    border-radius: 0px;
                    padding: 30px 0;
                    @media (max-width: 1024px) {
                        padding: 30px 0;
                    }
                    p {
                        color: rgb(73, 75, 80);
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        line-height: 28px;
                        strong {
                            color: rgb(73, 75, 80);
                            font-family: Barlow;
                            font-size: 21px;
                            font-weight: 600;
                            letter-spacing: 0px;
                            line-height: 30px;
                        }
                        @media (max-width: 1024px) {
                            strong {
                                font-size: 18px;
                                line-height: 28px;
                            }
                        }
                    }
                    p + p {
                        margin-top: 10px;
                    }
                }
                .hs-form {
                    fieldset {
                        max-width: 100%;
                        margin-bottom: 20px;
                        label {
                            > span {
                                display: inline-block;
                                margin-bottom: 5px;
                            }
                        }
                        &.form-columns-2 {
                            display: flex;
                            justify-content: space-between;
                            > div {
                                flex: 0 0 48.5%;
                                float: none;
                                width: auto;
                            }
                            @media (max-width: 1024px) {
                                flex-wrap: wrap;
                                > div {
                                    flex-basis: 100%;
                                    &:first-child {
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                        }
                        .hs-input {
                            width: 100% !important;
                            &.error {
                                border: 1px solid rgb(244, 86, 86);
                            }
                        }
                    }
                    .input + .hs-error-msgs {
                        margin-top: 6px;
                    }
                    .hs-error-msg {
                        color: #f45656;
                        font-weight: 500;
                        font-size: 14px;
                    }
                    .hs-form-field {
                        label {
                            span {
                                color: rgb(73, 75, 80);
                                font-size: 14px;
                                font-weight: 600;
                                letter-spacing: 0.2px;
                                &.hs-form-required {
                                    display: none;
                                }
                            }
                        }
                        &.hs-fieldtype-textarea {
                            div.input {
                                line-height: 0;
                            }
                        }
                    }
                    .hs_error_rollup {
                        margin-bottom: 30px;
                        .hs-error-msgs {
                            > li {
                                label {
                                    position: relative;
                                    background: rgba(244, 86, 86, 0.08);
                                    border-radius: 0px;
                                    padding: 11px 20px;
                                    display: block;
                                    padding-left: 44px;
                                    color: #f45656;
                                    font-size: 14px;
                                    font-weight: 400; // regularmedium regular 400 medium 500
                                    letter-spacing: 0px;
                                    &:before {
                                        content: url('/assets/alert-circle.svg');
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        left: 15px;
                                        height: 23px;
                                    }
                                }
                            }
                        }
                    }
                    .hs-submit {
                        margin-top: 32px;
                    }
                }
                input[type="submit"] {
                    width: auto;
                    color: #fff;
                    background: #3e8af6;
                    padding: 1px 28px;
                    border: 1px solid #3e8af6;
                    transition: background .3s, border .3s, color .3s;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    text-align: center;
                    cursor: pointer;
                    &:hover {
                        background: #4C80DC;
                        border: 1px solid #4C80DC;
                    }
                }
            }
        }
        &.demo-about-watch {
            padding-bottom: 0px;
            .demo-form {
                min-height: auto;
                padding: 38px 60px 82px;
                margin-top: 20px;
                @media (max-width: 1024px) {
                    order: 3 !important;
                    padding: 20px 20px 40px 20px;
                    // offset the demo-image block that does the same on mobile
                    transform: translateY(-60px);
                }
            }
            .demo-content {
                margin-top: 470px !important;
                @media (max-width: 1024px) {
                    order: 2 !important;
                    margin-top: 0px !important;
                    .demo-matrix-image {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .demo-watch-container {
        width: 100%;
        &.demo-hidden {
            display: none;
        }
        .demo-watch {
            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 1230px;
            margin: 0 auto;
            @media (max-width: 1024px) {
                
            }
            .matrix-video {
                margin-bottom: 85px;
                .background-wrapper {
                    .background-element {
                        background: none !important;
                    }
                }
                @media (max-width: 1024px) {
                    margin-bottom: 48px;
                    .video-container {
                        border-radius: 2px;
                    }
                }
            }
            .matrix-featureList {
                @media (max-width: 1024px) {
                    margin-bottom: 20px;
                }
                .feature-item {
                    @media (max-width: 1024px) {
                        padding-right: 20px;
                        .feature-text {
                            h4 {
                                font-size: 18px;
                                line-height: 28px;
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
            .demo-logos {
                display: flex;
                justify-content: space-between;
                a {
                    display: inline-block;
                    img {
                        height: 42px;
                        max-width: 100%;
                    }
                }
            }
            .home-logos {
                margin-top: 0px;
                @media (max-width: 768px) {
                    margin-bottom: 30px;
                }
                .logos-logos {
                    @media (max-width: 768px) {
                        padding: 0px 20px;
                        justify-content: flex-start;
                    }
                }
                .logos-subheading {
                    @media (max-width: 768px) {
                        font-size: 12px;
                        line-height: 15px;
                        margin-bottom: 30px;
                    }
                }
            }
            > h1 {
                max-width: 810px;
                margin: 0 auto 60px;
                text-align: center;
                @media (max-width: 768px) {
                    max-width: 400px;
                    margin: 0 auto 32px;
                    padding: 0px 20px;
                }
            }
            .demo-subheading {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 34px;
                @media (max-width: 768px) {
                    margin-bottom: 22px;
                }
                .demo-subheading-logo {
                    margin-right: 16px;
                }
                .demo-subheading-text {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
            }
        }
        .demo-watch-cta-outer {
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 10;
            .demo-watch-cta-bg {
                position: absolute;
                top: 50%;
                bottom: 0;
                left: 0;
                right: 0;
                background: #f6f8fb;
                z-index: 5;
            }
            .demo-watch-cta {
                z-index: 10;
                width: 1690px;
                background: #2B2256;
                min-height: 387px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                z-index: 10;
                @media (max-width: 1730px) {
                    width: calc(100% - 40px);
                    margin: 0px 20px;
                    padding: 0px 20px;
                }
                @media (max-width: 1024px) {
                    min-height: 299px;
                }
                .demo-watch-cta-heading {
                    z-index: 10;
                    color: #FFF;
                    * {
                        color: #FFF;
                        text-align: center;
                        @media (max-width: 1024px) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }
                }
                .demo-watch-cta-text {
                    z-index: 10;
                    color: #FFF;
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 33px;
                    text-align: center;
                    @media (max-width: 1024px) {
                        font-size: 18px;
                        line-height: 29px;
                    }
                }
                .demo-watch-cta-logo {
                    position: absolute;
                    right: -30px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 5;
                    pointer-events: none;
                    @media (max-width: 1024px) {
                        top: 30px;
                        transform: none;
                        right: -90px;
                    }
                    img {
                        height: 436px;
                        @media (max-width: 1024px) {
                            height: 313px;
                        }
                    }
                }
            }
        }
    }
}