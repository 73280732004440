.matrix-imageHeader {
    position: relative;
    .perfect-slant-box {
        display: none;
        &.style-bottomSlant,
        &.style-greyBackground {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 150px;
            @media (max-width: 768px) {
                height: 50px;
            }
            z-index: -1;
            svg {
                width: 100%;
                height: 100%;
                polygon {
                    fill: currentColor;
                }
            }
        }
        &.style-greyBackground {
            transform: scaleX(-1);
            z-index: 1;
        }
    }
}
.image-header-wrapper {
    &.style-greyBackground {
        background-color: #f6f8fb;
        padding-bottom: 180px;
        @media (max-width: 768px) {
            padding-bottom: 70px;
        }
        @media (min-width: 769px) {
            margin-bottom: 52px;
            padding: 120px 0 132px;
            .image-header {
                .image-header-content {
                    p {
                        padding-right: 0px;
                    }
                }
                .image-header-image {
                    margin-left: 114px;
                    img {
                        width: 114%;
                        max-width: 114%;
                    }
                }
            }
        }
        .image-header-content {
            .subheading {
                margin-bottom: 28px;
            }
        }
    }
    &.style-bottomSlant {
        @media (max-width: 768px) {
            &:after {
                content: '';
                display: block;
                width: 100%;
                background: #f6f8fb;
                height: 17px;
                display: none;
            }
            .image-header {
                min-height: 800px;
                .image-header-content {
                    padding-bottom: 60px;
                }
            }
        }
    }
}
.image-header {
    width: 1230px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px 0;
    &.size-small {
        justify-content: space-between;
        .image-header-content {
            p {
                @media (min-width: 768px) {
                    padding-right: 0px;
                }
            }
        }
    }
    &.size-large, &.size-oversize {
        &:after {
            content: '';
            position: absolute;
            width: 200vw;
            height: 100vw;
            left: -67vw;;
            bottom: 100%;
            @media (max-width: 768px) {
                bottom: 78%;
            }
            transform: rotate(-20deg);
            background-color: #f6f8fb;
            z-index: -1;
        }
        &.style-bottomSlant,
        &.style-greyBackground {
            &:after {
                display: none;
            }
        }
    }
    &.size-oversize {
        position: relative;
        width: 1230px;
        min-height: 765px;
        margin: auto;
        .image-header-content {
            width: 570px;
            margin-right: auto;
        }
        .image-header-image {
            position: absolute;
            width: 1100px;
            left: 600px;
        }
    }
    &.size-large {
        padding: 0px 0 100px;
        .image-header-content {
            width: 595px;
        }
        .image-header-image {
            margin-left: 92px;
            width: 540px;
            left: 40%;
            width: 595px;
            margin-top: -15px;
        }
    }
    &.style-greyBackground {
        background-color: #f6f8fb;
    }
    .image-header-content {
        width: 705px;
        flex-shrink: 0;
        p {
            font-size: 22px;
            font-family: Barlow;
            line-height: 36px;
            @media (max-width: 1230px) {
                font-size: 19px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 31px;
            }
            @media (min-width: 768px) {
                padding-right: 20%;
            }
        }
    }
    .image-header-image {
        margin-top: 30px;
        width: 325px;
        img {
            width: 100%;
            height: auto;
        }
        &.has-mobile-image {
            img.mobile-image {
                display: none;
            }
            @media (max-width: 768px) {
                img.mobile-image {
                    display: block;
                }
                img.desktop-image {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 1230px) {
        width: 100%;
        padding: 40px;
        .image-header-image {
            margin-left: 0;
        }
        &.size-large {
            .image-header-content {
                padding-left: 40px;
            }
        }
        &:not(.size-large) {
            .image-header-content {
                padding-right: 40px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 100% !important;
        flex-direction: column-reverse;
        padding: 0 0 60px !important;
        min-height: auto;
        .image-header-content {
            width: 100% !important;
            padding: 20px !important;
        }
        .image-header-image {
            position: static !important;
            width: 90% !important;
            margin: 0 !important;
            padding-bottom: 20px;
            padding-top: 20px;
            img {
                min-width: 100%;
            }
        }
        &.size-oversize {
            .image-header-image {
                img {
                    min-width: 150%;
                }
            }
        }
        &.size-small {
            .image-header-image {
                margin-bottom: 40px !important;
                img {
                    min-width: auto;
                    max-width: 80%;
                    margin: auto;
                }
            }
        }
        &.style-bottomSlant,
        &.style-greyBackground {
            padding-bottom: 10px !important;
        }
    }
    .button {
        position: relative;
        z-index: 1;
        color: white;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 16px;
        text-align: center;
        border-radius: 4px;
        height: 50px;
        padding: 17px 28px;
        overflow: hidden;
        display: inline-flex;
        margin-top: 40px;
        .button-text {
            position: relative;
        }
        .button-bg {
            position: absolute;
            border-radius: 4px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #3e8af6;
            filter: brightness(100%);
            transition: filter .3s;
        }
        &:hover {
            .button-bg {
                filter: brightness(92%);
            }
        }
    }
}