.matrix-moduleFeatures {
    .module-bg-light {
        @apply bg-gray-100;
    }
    .module-bg-dark {
        @apply bg-grey-900;
        h1, h4, p {
            @apply text-white;
        }
    }
    .feature-item {
        padding: 100px 0;
        .flex-row-reverse {
            .feature-details {
                @apply justify-end;
            }
        }
        .feature-details {
            @apply flex;
            .feature-text {
                max-width: 495px;
                h2 {
                    @apply font-barlow font-semibold text-4xl text-blue-800;
                    letter-spacing: 0;
                    font-size: 30px;
                    line-height: 42px;
                }
                h4 {
                    @apply text-grey-400;
                    font-size: 21px;
                    line-height: 30px;
                    margin-top: 10px;
                    margin-bottom: 30px;
                }
                p {
                    @apply text-grey-600;
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: 0;
                }
            }
        }
    }
}