body {
  background: #ffffff;
  overflow-x: hidden;
  &.mobileMenuActive,
  &.modal-active,
  &.wistia_popover_mode {
    max-height: 100vh;
    overflow: hidden;
  }
}
#app {
  position: relative;
  overflow: hidden;
}
.design-overlay,
.nav-overlay,
.footer-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  opacity: 0.5;
  mix-blend-mode: multiply;
  z-index: 99999;
  pointer-events: none;

  &.hidden {
    display: none;
  }
  .mobile-overlay {
    display: none;
  }
  @media (max-width: 768px) {
    margin-left: -187.5px;
    width: 375px;
    .desktop-overlay {
      display: none;
    }
    .mobile-overlay {
      display: block;
    }
  }
}
.footer-overlay {
  top: auto;
  bottom: 0;
}
.pages-v2 {
  @import 'mixins.scss';
  @import 'new_components/all.scss';
  @import 'banner_components/all.scss';
  @import 'home_components/all.scss';
  font-family: Barlow;
  font-size: 16px;
  line-height: 28px;
  .lead {
    font-family: Barlow;
    color: rgb(73, 75, 80);
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 33px;
    strong {
      font-weight: 600;
      font-family: Barlow;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 29px;
    }
    &.lead-header {
      font-weight: 300;
      font-size: 22px;
      letter-spacing: 0px;
      line-height: 36px !important;
      @media (max-width: 768px) {
        font-size: 19px;
        line-height: 30px !important;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &.curved-underlines {
      u {
        position: relative;
        text-decoration: none;
        svg {
          content: '';
          display: block;
          position: absolute;
          top: 95%;
          left: 0;
          right: 0;
          width: 100%;
          height: 15px;
          stroke: currentColor;
          overflow: visible;
          @media (max-width: 768px) {
            top: 87%;
          }
        }
      }
      strong {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        vertical-align: bottom;
        svg {
          @media (max-width: 1023px) {
            display: none;
          }
        }
      }
      em {
        font-style: initial;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        vertical-align: bottom;
        font-family: Barlow;
        svg {
          margin-left: 3px;
          margin-top: -10px;
        }
      }
    }
    &.left-slanted-underlines,
    &.right-slanted-underlines {
      u {
        position: relative;
        text-decoration: none;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 2px;
          border-radius: 1px;
          background: currentColor;
          @media (max-width: 768px) {
            transform: none;
          }
        }
      }
    }
    &.left-slanted-underlines {
      u {
        &:after {
          transform: rotate(1deg);
        }
      }
    }
    &.right-slanted-underlines {
      u {
        &:after {
          transform: rotate(-1deg);
        }
      }
    }
  }
  h1 {
    &,
    & * {
      color: rgb(43, 58, 90);
      font-family: Barlow;
      font-size: 55px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 70px;
      margin-bottom: 26px;
      @media (max-width: 768px) {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 15px;
      }
      &.jumbo {
        font-size: 95px;
        line-height: 100px;
        color: #2b3a5a !important;
        margin-bottom: 35px;
        @media (max-width: 1023px) {
          font-size: 32px;
          line-height: 44px;
        }
      }
    }
  }
  h2 {
    color: #2b3a5a;
    font-family: Barlow;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 54px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 9px;
    }
  }
  h3 {
    color: #2b3a5a;
    font-family: Barlow;
    color: rgb(43, 58, 90);
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 21px;
    line-height: 36px;
    @media (max-width: 768px) {
      font-size: 28px;
      letter-spacing: 0px;
      margin-bottom: 12px;
    }
  }
  h4 {
    font-family: Barlow;
    font-weight: bold;
    font-size: 24px;
    color: #2b3a5a;
    letter-spacing: 0;
    margin-bottom: 16px;
  }
  h5 {
    font-family: Barlow;
    font-weight: 600;
    font-size: 21px;
    color: #494b50;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
  .logo,
  .subheading {
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 8px 0;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }

    display: inline-flex;
    align-items: center;
    img {
      height: 30px;
      margin-right: 16px;
      @media (max-width: 768px) {
        height: 30px;
      }
    }
  }
  .subheading {
    line-height: 16px;
    padding: 0px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
  p {
    font-weight: 300;
    font-family: Inter, sans-serif;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .streamline-stroke-icon {
    svg {
      overflow: visible;
      &.d-custom-icon {
        * {
          stroke-width: 1.5px;
        }
      }
      * {
        fill: none;
        stroke: currentColor;
        stroke-width: 1.1px;
      }
    }
  }
  .background-element {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .card-link {
    .link-content {
      display: inline-flex;
      align-items: center;
    }
  }
  .internal-link {
    display: inline-block;
    transition: transform 0.3s;
    svg {
      overflow: visible;
      width: 16px;
      [stroke]:not([stroke='none']) {
        stroke: currentColor;
        stroke-width: 1.8;
      }
    }
  }
  .external-link {
    vertical-align: text-bottom;
    margin-top: -2px;
    svg {
      overflow: visible;
    }
    .arrow {
      transform: translate(0, 0);
      transition: transform 0.3s;
    }
  }
  .basic-link {
    display: inline-block;
    transition: opacity 0.3s;
    &:hover {
      @include triggerLinkAnimation;
    }
    .link-content {
      color: #2b3a5a;
      font-weight: 600;
      font-size: 16px;
      .link-type-icon {
        margin-left: 8px;
        color: #2b3a5a;
      }
    }
    &.basic-link-white {
      .link-content {
        color: #ffffff;
        .link-type-icon {
          color: #ffffff;
        }
      }
    }
  }
  .video-sub {
    .video-modal {
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
      .modal-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
      }
      .video {
        position: relative;
        width: 80%;
        @media (max-width: 768px) {
          width: 100%;
        }
        max-width: 90vh * (16/9);
        .video-sizer {
          width: 100%;
          padding-bottom: 100% * (9/16);
          pointer-events: none;
        }
        .video-wrapper,
        iframe,
        .plyr {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &.active {
      .video-modal {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

p + p {
  margin-top: 20px;
}

p {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  small {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
  }
}

// page specific overrides
[slug='about'] {
  .matrix-text {
    .text-content {
      h1.jumbo {
        margin-top: 12px;
        @media (max-width: 768px) {
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .matrix-textAndImage {
    .text-and-image-content {
      .text {
        h3 {
          @media (max-width: 768px) {
            font-size: 24px;
            line-height: 29px;
          }
        }
      }
    }
  }
}
.wistia-video-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  &:not(.active) {
    &,
    * {
      pointer-events: none !important;
    }
  }
  .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .modal-content {
    position: relative;
    width: 80%;
    @media (max-width: 768px) {
      width: 100%;
    }
    max-width: 90vh * (16/9);
    .video-sizer {
      width: 100%;
      padding-bottom: 100% * (9/16);
      pointer-events: none;
    }
    .video-wrapper,
    iframe,
    .plyr {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.careers-page-svg {
  display: none;
  position: relative;
  @media (max-width: 768px) {
    display: inline-block;
  }
  svg {
    position: absolute;
    bottom: -60px;
  }
}
