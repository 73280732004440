.wrapper[type="modules"] {
    @apply bg-white;
}

.module .module-bg {
    top: -600px;
    height: 1100px;
    transform: skewY(-22.5deg);
    @apply pointer-events-none absolute inset-x-0 bg-grey-100;
}

.module-header {
    position: relative;
    z-index: 10;
    h4 {
        line-height: 15px;
        letter-spacing: 2px;
    }
    h1 {
        font-size: 42px;
        line-height: 54px;
        margin-top: 25px;
        margin-bottom: 57px;
    }
    p {
        font-size: 24px;
        line-height: 36px;
    }
    .visual {
        position: relative;
        @screen md {
            left: 130px;
        }
    }
}

.module-content {
    &.header-angled {
        margin-top: 300px;
    }
}