.matrix-ctaBanner {
    @apply bg-grey-900 text-center;
    padding: 60px 20px;
    br {
        display: none;
    }
    @screen md {
        margin-top: 180px;
        padding: 177px 20px 170px;
        br {
            display: initial;
        }
    }
    h3 {
        @apply font-barlow font-bold text-3xl text-white text-center;
        letter-spacing: 0px;
        margin-bottom: 20px;
        @screen md {
            @apply text-5xl;
            line-height: 58px;
            margin: 0 auto 48px;
        }
    }
    .btn {
        margin: 23px auto 0;
        padding: 18px 55px;
        @apply text-base inline-block;
    }
}