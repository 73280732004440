







































.d-notification {
    right: 48px;
    bottom: 48px;
    max-width: 534px;
    padding: 34px 100px 34px 40px;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.11);
    transform: translateY(150%);
    transition: transform 0.5s;
    @media (max-width: 992px) {
        right: 0;
        bottom: 0;
        border-radius: 0px;
        padding: 24px 65px 24px 24px;
    }
    &.show-notification {
        transform: translateY(0px);
    }
    .notification-text {
        line-height: 24px;
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .close-notification {
        &:hover {
            svg g {
                stroke-width: 2px;
            }
        }
    }
}
