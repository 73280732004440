.cs-columns {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 0 auto;
    justify-content: center;
    .is-hidden {
        display: none;
    }
    .is-3,
    .is-4,
    .is-6,
    .is-8,
    .is-12 {
        flex-grow: 0;
        flex-shrink: 0;
    }
    .is-3 {
        flex-basis: 25%;
    }
    .is-4 {
        flex-basis: 33.33333%;
    }
    .is-6 {
        flex-basis: 50%;
    }
    .is-8 {
        flex-basis: 66.66666%;
    }
    .is-12 {
        flex-basis: 100%;
    }
    .is-auto {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
    }
    // medium screen size classes (< 1600px)
    @media (max-width: 1600px) {
        .m-is-4 {
            flex-basis: 33.33333%;
        }
        .m-is-hidden {
            display: none;
        }
    }
    @media (max-width: 1100px) {
        > div {
            flex-basis: 100% !important;
        }
        .s-is-hidden {
            display: none;
        }
        .s-is-visible {
            display: block;
        }
    }
    .red {
        background: red;
        min-height: 300px;
    }
    .blue {
        background: blue;
        min-height: 300px;
    }
}
.customer-stories-page.pages-v2 {
    // slanted header bg
    .image-header-wrapper.style-regular {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none;
        .image-header.size-oversize.style-regular {
            &:after {
                width: 241vw;
                height: 311vw;
                right: -64vw;
                left: auto;
                bottom: 100%;
                transform: rotate(17deg);
                @media (max-width: 1200px) {
                    width: 321vw;
                }
                @media (max-width: 768px) {
                    width: 420vw;
                }
            }
        }
    }
    &.customer-stories-listing {
        .cs-hero {
            padding-top: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: 1200px) {
                padding-top: 60px;
            }
            .subheading {
                text-align: center;
                @media (max-width: 1024px) {
                    margin-bottom: 16px;
                }
            }
            .page-headline {
                max-width: 900px;
                text-align: center;
                padding-bottom: 45px;
                @media (max-width: 1024px) {
                    max-width: 560px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 30px;
                    h1, h1 u {
                        font-size: 32px;
                        line-height: 44px;
                        margin-bottom: 0px;
                    }
                }
            }
            .cs-logos {
                display: flex;
                justify-content: space-between;
                min-width: 650px;
                max-width: 850px;
                align-items: center;
                margin-bottom: 115px;
                @media (max-width: 1024px) {
                    min-width: 100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    max-width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-bottom: 40px;
                }
                a {
                    display: flex;
                    align-items: center;
                    max-width: 140px;
                    img {
                        max-height: 70px;
                    }
                    @media (max-width: 1024px) {
                        max-width: none;
                        flex-basis: 50%;
                        padding: 20px;
                        justify-content: center;
                    }
                }
            }
        }
        .cs-feature {
            display: flex;
            justify-content: center;
            max-width: 1200px;
            margin: 0 auto 160px;
            @media (max-width: 1024px) {
                margin: 0 auto 65px;
            }
            @media (max-width: 768px) {
                flex-wrap: wrap;
            }
            a.csf-image-wrapper {
                display: block;
                flex-basis: 53%;
                @media (max-width: 768px) {
                    flex-basis: 100%;
                }
                // only enable hover on desktop
                @media (min-width: 1024px) {
                    &:hover {
                        .csf-image {
                            .csf-image-bg-wrapper { 
                                transform: scale(0.97);
                                .csf-image-bg {
                                    transform: scale(1.12);
                                    &:after {
                                        background: rgba(#000000, 0.2);
                                    }
                                }
                            }
                        }
                    }
                }
                .csf-image {
                    padding-bottom: 90.5%;
                    width: calc(100% + 117px);
                    transform: translateX(calc(-117px - 66px));
                    position: relative;
                    z-index: 10;
                    @media (max-width: 1660px) {
                        padding-bottom: 68.5%;
                        width: calc(100% - 40px);
                        transform: translateX(0px);
                    }
                    @media (max-width: 1024px) {

                    }
                    .csf-image-bg-wrapper {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        z-index: 5;
                        border-radius: 4px;
                        overflow: hidden;
                        transition: all 0.6s;
                        @media (max-width: 1200px) {
                            border-radius: 2px;
                        }
                        .csf-image-bg {
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center;
                            z-index: 5;
                            border-radius: 4px;
                            transition: all 0.6s;
                            @media (max-width: 1200px) {
                                border-radius: 2px;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;
                                background: rgba(#000000, 0.0);
                                transition: all 0.6s;
                            }
                        }
                    }
                    .csf-image-outer {
                        background: #F6F8FB;
                        position: absolute;
                        top: 119px;
                        right: -66px;
                        left: -9999px;
                        bottom: -61px;
                        @media (max-width: 1200px) {
                            top: 93px; // TODO test on all screen sizes
                            right: -20px;
                            bottom: -20px;
                        }
                    }
                }
            }
            .csf-content {
                flex-basis: 47%;
                padding-top: 70px;
                padding-left: 90px;
                @media (max-width: 1240px) {
                    padding-right: 20px;
                }
                @media (max-width: 1200px) {
                    padding-top: 52px;
                    padding-left: 20px;
                }
                @media (max-width: 768px) {
                    flex-basis: 100%;
                }
                .csf-logo {
                    &.csf-logo-wide {
                        width: auto;
                        height: 31px;
                        margin-bottom: 70px;
                        @media (max-width: 1200px) {
                            margin-bottom: 40px;
                        }
                    }
                    &.csf-logo-tall {
                        width: auto;
                        height: 71px;
                        margin-bottom: 55px;
                        @media (max-width: 1200px) {
                            margin-bottom: 35px;
                        }
                    }
                }
                .csf-products {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #000000;
                    opacity: 0.7;
                    margin-bottom: 12px;
                }
                a {
                    &:hover {
                        h2.csf-title {
                            span {
                                border-bottom: 2px solid rgba(#2B3A5A, 1);
                            }
                        }    
                    }
                    h2.csf-title {
                        font-family: 'Barlow';
                        @media (max-width: 1200px) {
                            font-size: 24px;
                            line-height: 29px;
                            margin-bottom: 12px;
                        }
                        span {
                            font-family: 'Barlow';
                            transition: border-color 0.3s;
                            border-bottom: 2px solid rgba(#2B3A5A, 0);
                        }
                    }
                }
                .csf-summary {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 33px;
                    color: #494B50;
                    margin-bottom: 60px;
                    @media (max-width: 1200px) {
                        margin-bottom: 20px;
                        font-size: 18px;
                        line-height: 29px;
                    }
                }
            }
        }
        .customer-stories-list {
            max-width: 1200px;
            margin: 0 auto;
            &.is-loading {
                .customer-stories-filters {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
            &.no-click {
                .customer-stories-filters {
                    pointer-events: none;
                }
            }
            h2.explore {
                margin-bottom: 60px;
                @media (max-width: 1240px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @media (max-width: 1024px) {
                    margin-bottom: 36px;
                    font-size: 24px;
                    line-height: 29px;
                }
            }
            .customer-stories-filters-outer {
                overflow-y: auto;
                margin-bottom: 48px;
                padding-bottom: 12px;
                @media (max-width: 1240px) {
                    padding-right: 20px;
                    margin-bottom: 28px;
                    padding-bottom: 12px;
                }
                .customer-stories-filters {
                    display: flex;
                    border-bottom: 1px solid #E3E8EF;
                    position: relative;
                    transition: opacity 0.3s;
                    @media (max-width: 1240px) {
                        margin-left: 20px;
                    }
                    @media (max-width: 1024px) {
                        min-width: 450px;
                    }
                    .cs-filter {
                        display: flex;
                        align-items: center;
                        padding: 10px 10px 20px 10px;
                        margin-right: 28px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        color: #494B50;
                        border-bottom: 2px solid rgba(#3E8AF6, 0);
                        transition: all 0.2s;
                        @media (max-width: 992px) {
                            margin-right: 12px;
                            font-size: 14px;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                        &.is-active {
                            pointer-events: none;
                            font-weight: 600;
                            // border-bottom: 2px solid rgba(#3E8AF6, 1);
                        }
                        img {
                            width: 24px;
                            margin-right: 8px;
                            pointer-events: none;
                        }
                    }
                    .cs-filter-indicator {
                        position: absolute;
                        left: 0px;
                        bottom: 0;
                        width: 97px;
                        height: 2px;
                        background: #3E8AF6;
                        transition: all 0.5s;
                    }
                }
            }
            .customer-stories-container {
                display: flex;
                flex-wrap: wrap;
                margin-left: -30px;
                margin-right: -30px;
                @media (max-width: 1240px) {
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-left: -10px;
                    margin-right: -10px;
                }
                @media (max-width: 768px) {
                    margin-left: 0px;
                    margin-right: 0px;
                }
                &.fade-out {
                    opacity: 0;
                    transition: opacity 0.3s;
                }
                a.customer-story-item {
                    opacity: 0;
                    &.fade-in {
                        opacity: 1;
                    }
                }
            }
            .load-more-wrapper {
                display: flex;
                justify-content: center;
                margin-bottom: 130px;
                padding-top: 16px;
                @media (max-width: 768px) {
                    margin-bottom: 90px;
                }
                button.load-more-stories {
                    display: inline-block;
                    padding: 10px 28px 10px 28px;
                    height: 50px;
                    border: 1px solid #EAEBEF;
                    box-sizing: border-box;
                    border-radius: 4px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 30px;
                    text-align: center;
                    color: #3E8AF6;
                    transition: all 0.3s;
                    &:hover {
                        background: rgba(#3E8AF6, 0.08);
                        border-color: rgba(#3E8AF6, 0.08);
                    }
                    &.hidden {
                        display: none;
                    }
                }
            }
        }
        .cs-cta {
            background: #283A5D;
            padding: 180px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (max-width: 768px) {
                padding: 130px 20px;
            }
            .cta-headline {
                max-width: 844px;
                font-family: 'Barlow';
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 54px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 48px;
                @media (max-width: 768px) {
                    font-size: 28px;
                    line-height: 40px;
                }
                h2 {
                    color: #FFF;
                }
            }
            a.button {
                font-family: 'Inter';
                display: inline-block;
                background-color: #3E8AF6;
                border-radius: 4px;
                padding: 15px 28px;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
                transition: background-color 0.3s;
                font-weight: 600;
                &:hover {
                    background-color: #3275E1;
                }
            }
        }
    }
    &.customer-stories-entry {
        .cs-entry-container {
            max-width: 1200px;
            margin: 0 auto;
        }
        .cs-entry-header {
            background: #F6F8FB;
            padding-top: 110px;
            padding-bottom: 72px;
            @media (max-width: 1100px) {
                padding-top: 50px;
                padding-bottom: 22px;
            }
            .subheading {
                @media (max-width: 1600px) {
                    max-width: 66.666%;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    text-align: left;
                }
                @media (max-width: 1100px) {
                    max-width: 100%;
                    margin-left: 20px;
                    margin-right: 20px;
                    max-width: 100%;
                    margin-bottom: 16px;
                }
            }
            h1 {
                max-width: 947px;
                font-family: 'Barlow';
                font-style: normal;
                font-weight: bold;
                font-size: 55px;
                line-height: 70px;
                color: #2B3A5A;
                @media (max-width: 1600px) {
                    // max-width: 100%;
                    max-width: 66.666%;
                    margin: 0 auto;
                }
                @media (max-width: 1100px) {
                    margin-left: 20px;
                    margin-right: 20px;
                    max-width: 100%;
                    font-size: 32px;
                    line-height: 44px;
                }
            }
            .cs-entry-container {
                @media (max-width: 1600px) {
                    width: 66.6666%;
                    margin: 0;
                }
                @media (max-width: 1100px) {
                    width: 100%;
                }
            }
        }
        .cs-entry-content {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: -9999px;
                right: -9999px;
                height: 100px;
                background: #F6F8FB;
                z-index: -5;
                @media (max-width: 1600px) {
                    height: 40px;
                }
            }
            .cs-entry-video {
                &:not(.image-only) {
                    .csv-image-bg-wrapper {
                        cursor: pointer;
                        &:hover {
                            .csv-play-icon {
                                .csv-icon-wrap {
                                    &:before {
                                        transform: scale(1.1);
                                    }
                                }
                                span {
                                    &:after {
                                        width: 100%;
                                    }
                                }
                            }
                            .csv-image-bg {
                                transform: scale(1.05);
                                &:after {
                                    background: rgba(0,0,0,0.3);
                                }
                            }
                        }
                    }
                }
                .csv-image {
                    padding-right: 138px;
                    // this can be used to extend the image off to the left
                    // margin-left: -100px;
                    @media (max-width: 1600px) {
                        padding-right: 0px;
                    }
                    @media (max-width: 1100px) {
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .csv-image-bg-wrapper {
                        position: relative;
                        overflow: hidden;
                        .csv-image-bg {
                            position: relative;
                            padding-bottom: 62.5%;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center;
                            transition: transform 0.4s;
                            @media (max-width: 1600px) {
                                padding-bottom: 72.5%;
                            }
                            @media (max-width: 1100px) {
                                padding-bottom: 62.5%;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background: rgba(0,0,0,0);
                                transition: background-color 0.4s;
                                pointer-events: none;
                            }
                        }
                        .csv-play-icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            pointer-events: none;
                            .csv-icon-wrap {
                                position: relative;
                                margin-bottom: 35px;
                                @media (max-width: 1100px) {
                                    width: 60px;
                                    margin: 0 auto 10px;
                                }
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    border: 8px solid white;
                                    border-radius: 50%;
                                    transition: transform 0.3s 0.05s;
                                }
                                svg {
                                    @media (max-width: 1100px) {
                                        width: 60px;
                                        height: 60px;
                                        margin: 0 auto;
                                    }
                                }
                            }
                            span {
                                position: relative;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                text-align: center;
                                letter-spacing: 0.1em;
                                text-transform: uppercase;
                                color: #FFFFFF;
                                @media (max-width: 1100px) {
                                    font-size: 11px;
                                    line-height: 13px;
                                }
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 120%;
                                    left: 0;
                                    width: 0%;
                                    height: 2px;
                                    background: white;
                                    transition: width 0.4s;
                                }
                            }
                        }
                    }
                }
            }
            .cs-entry-sidebar {
                padding-top: 67px;
                @media (max-width: 1600px) {
                    padding-top: 80px;
                }
                @media (max-width: 1100px) {
                    padding-top: 60px;
                }
                .cs-entry-stats {
                    opacity: 1;
                    transition: opacity 0.2s;
                    padding-top: 120px;
                    padding-bottom: 120px;
                    @media (max-width: 1600px) {
                        padding-left: 70px;
                    }
                    &.cs-entry-stats-mobile {
                        padding-left: 0px;
                        margin-left: 20px;
                        margin-right: 20px;
                        padding-top: 0px;
                        padding-bottom: 48px;
                    }
                    &.stats-hidden {
                        opacity: 0;
                        pointer-events: none;
                    }
                    &.js-is-sticky {
                        transform: translateY(67px);
                        @media (max-width: 1600px) {
                            transform: translateY(80px);
                        }
                        @media (max-height: 860px) {
                            top: 50% !important;
                            transform: translateY(-50%) !important;
                            padding-top: 0px;
                            padding-bottom: 0px;
                        }
                    }
                    &.js-is-stuck {                    
                        position: fixed !important;
                        opacity: 0;
                        top: 0 !important;
                        // bottom: auto !important;
                        transform: translateY(67px);
                    }
                    .cse-stat {
                        width: 328px;
                        border-bottom: 1px solid rgba(#000000, 0.1);
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        @media (max-width: 1100px) {
                            max-width: 703px;
                            margin: 0 auto;
                            // margin-right: 0px;
                            width: 100%;
                            // max-width: 500px;
                            font-size: 14px;
                            line-height: 17px;
                        }
                        a.school-logo {
                            display: block;
                            height: 55px;
                            background-position: left;
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin-bottom: 50px;
                            @media (max-width: 1100px) {
                                height: 47px;
                                margin-bottom: 32px;
                            }
                        }
                        &:not(:first-child) {
                            padding: 28px 0px;
                            @media (max-width: 1100px) {
                                padding: 24px 0px;
                            }
                            @media (max-height: 660px) {
                                padding: 18px 0px !important;
                            }
                        }
                        .cse-stat-subtitle {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 11px;
                            line-height: 13px;
                            letter-spacing: 0.05em;
                            text-transform: uppercase;
                            color: #494B50;
                            opacity: 0.7;
                            margin-bottom: 12px;
                        }
                        .cse-stat-products {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            align-items: center;
                            .cse-stat-product {
                                display: flex;
                                align-items: center;
                                margin-right: 20px;
                                &:last-child {
                                    margin-right: 0px;
                                }
                                img {
                                    width: 24px;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .customer-stories-matrix {
            margin: 100px 0px 60px 0px;
            @media (max-width: 1100px) {
                margin: 0px 20px 45px;
            }
            .cs-block-body {
                display: flex;
                justify-content: center;
                > div {
                    max-width: 703px;
                    h2 {
                        margin-top: 60px;
                        @media (max-width: 1100px) {
                            margin-top: 48px;
                        }
                    }
                }
                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 32px;
                    color: #494B50;
                    @media (max-width: 1100px) {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
                p.lead {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 36px;
                    @media (max-width: 1100px) {
                        font-size: 19px;
                        line-height: 31px;
                    }
                }
                h2 {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 54px;
                    color: #2B3A5A;
                    @media (max-width: 1100px) {
                        font-size: 24px;
                        line-height: 29px;
                        margin-bottom: 12px;
                    }
                }
                figure {
                    width: 100%;
                    padding: 20px 0px;
                    a {
                        display: inline-block;
                    }
                    img {
                        width: 100%;
                    }
                }
                ol {
                    list-style: auto;
                }
                ul {
                    list-style: initial;
                }
                ol, ul {
                    padding-left: 22px;
                    // margin-bottom: 12px;
                    li {
                        font-size: 18px;
                        line-height: 32px;
                        @media (max-width: 1100px) {
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                }
                p + ol,
                p + ul,
                ul + p,
                ol + p,
                ol + ul,
                ul + ol {
                    margin-top: 20px;
                }
            }
            .cs-block-blockquote {
                position: relative;
                z-index: 10;
                .cs-blockquote-outer {
                    background: #F6F8FB;
                    display: flex;
                    justify-content: center;
                    &.cs-blockquote-with-image {
                        margin: 180px -9999px 130px;
                        @media (max-width: 992px) {
                            margin-top: calc(48px + 60px);
                            margin-bottom: 48px;
                        }
                    }
                    &.cs-blockquote-no-image {
                        margin: 110px -9999px 96px;
                        @media (max-width: 992px) {
                            margin-top: 48px;
                            margin-bottom: 48px;
                        }
                    }
                    .cs-blockquote {
                        .quote-text {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 28px;
                            line-height: 42px;
                            color: #2B3A5A;
                            margin-bottom: 20px;
                            @media (max-width: 1100px) {
                                font-size: 18px;
                                line-height: 27px;
                                margin-bottom: 16px;
                            }
                        }
                        .quote-byline {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 24px;
                            color: #494B50;
                            @media (max-width: 1100px) {
                                font-size: 12px;
                            }
                            strong {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: bold;
                                font-size: 16px;
                                line-height: 27px;
                                color: #494B50;
                                @media (max-width: 1100px) {
                                    font-size: 14px;
                                }
                            }
                        }
                        &.cs-blockquote-with-image {
                            max-width: 1200px;
                            display: flex;
                            @media (max-width: 1600px) {
                                max-width: 1075px;
                                padding-left: 120px;
                                .quote-text {
                                    font-size: 26px;
                                    line-height: 38px;
                                }
                            }
                            @media (max-width: 992px) {
                                flex-direction: column;
                                max-width: 100vw;
                                padding-left: 0px;
                            }
                            .csb-image {
                                width: 525px;
                                height: 600px;
                                flex-shrink: 0;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                transform: translateY(-60px);
                                @media (max-width: 1600px) {
                                    width: 400px;
                                    height: 545px;
                                }
                                @media (max-width: 1100px) {
                                    width: 45.5%;
                                    height: auto;
                                    padding-bottom: 52%;
                                }
                                @media (max-width: 992px) {
                                    width: 65.5%;
                                    height: auto;
                                    padding-bottom: 75%;
                                }
                                .scribbles {
                                    position: absolute;
                                    top: 32px;
                                    right: -292px;
                                    width: 92px;
                                    height: 50px;
                                    @media (max-width: 992px) {
                                        right: -35%;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                    .scribble-line {
                                        position: absolute;
                                        display: block;
                                        top: 0;
                                        left: 0;
                                        height: 56px;
                                        width: 47px;
                                        // <svg width="58" height="49" viewBox="0 0 58 49" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.28095 48.1091C3.19145 43.0683 0.703224 37.3851 0.90365 31.4715C1.10408 25.5578 4.61399 19.4677 10.2601 17.7417C15.1486 16.2706 20.6188 18.232 25.5072 16.6825C33.2799 14.2307 36.5454 4.105 44.2496 1.45221C48.9788 -0.0699472 54.1506 1.57211 57.1453 5.54663" stroke="#2B3A5A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNDkiIHZpZXdCb3g9IjAgMCA1OCA0OSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi4yODA5NSA0OC4xMDkxQzMuMTkxNDUgNDMuMDY4MyAwLjcwMzIyNCAzNy4zODUxIDAuOTAzNjUgMzEuNDcxNUMxLjEwNDA4IDI1LjU1NzggNC42MTM5OSAxOS40Njc3IDEwLjI2MDEgMTcuNzQxN0MxNS4xNDg2IDE2LjI3MDYgMjAuNjE4OCAxOC4yMzIgMjUuNTA3MiAxNi42ODI1QzMzLjI3OTkgMTQuMjMwNyAzNi41NDU0IDQuMTA1IDQ0LjI0OTYgMS40NTIyMUM0OC45Nzg4IC0wLjA2OTk0NzIgNTQuMTUwNiAxLjU3MjExIDU3LjE0NTMgNS41NDY2MyIgc3Ryb2tlPSIjMkIzQTVBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
                                        background-size: contain;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        transition: transform .3s;
                                        pointer-events: none;
                                        @media (max-width: 992px) {
                                            left: 40px;
                                            height: 45px;
                                            width: 40px;
                                        }
                                    }
                                    .scribble-circle {
                                        position: absolute;
                                        display: block;
                                        bottom: 0;
                                        right: 0;
                                        width: 18px;
                                        height: 18px;
                                        // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.62293 1C2.96098 2.97867 1.81594 5.34108 1.29101 7.87434C0.751625 10.0724 0.966209 12.3886 1.90013 14.4493C3.2795 16.9149 5.75248 18.5688 8.5518 18.8977C11.92 19.394 15.2979 18.0613 17.4268 15.3964C19.4247 12.72 19.5831 8.67482 17.5364 6.05951C15.4898 3.44421 11.3233 2.66817 8.64317 4.66631" stroke="#2B3A5A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC42MjI5MyAxQzIuOTYwOTggMi45Nzg2NyAxLjgxNTk0IDUuMzQxMDggMS4yOTEwMSA3Ljg3NDM0QzAuNzUxNjI1IDEwLjA3MjQgMC45NjYyMDkgMTIuMzg4NiAxLjkwMDEzIDE0LjQ0OTNDMy4yNzk1IDE2LjkxNDkgNS43NTI0OCAxOC41Njg4IDguNTUxOCAxOC44OTc3QzExLjkyIDE5LjM5NCAxNS4yOTc5IDE4LjA2MTMgMTcuNDI2OCAxNS4zOTY0QzE5LjQyNDcgMTIuNzIgMTkuNTgzMSA4LjY3NDgyIDE3LjUzNjQgNi4wNTk1MUMxNS40ODk4IDMuNDQ0MjEgMTEuMzIzMyAyLjY2ODE3IDguNjQzMTcgNC42NjYzMSIgc3Ryb2tlPSIjMkIzQTVBIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
                                        background-size: contain;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        transition: transform .3s;
                                        pointer-events: none;
                                        @media (max-width: 992px) {
                                            right: -3px;
                                            bottom: -15px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }
                            }
                            .csb-content {
                                padding: 110px 75px 110px 106px;
                                @media (max-width: 1600px) {
                                    padding: 110px 75px 110px 60px;
                                }
                                @media (max-width: 992px) {
                                    transform: translateY(-30px);
                                    padding: 0px 40px calc(50px - 30px);
                                }
                                .quote-text {
                                    @media (max-width: 1100px) {
                                        font-size: 18px;
                                        line-height: 27px;
                                        margin-bottom: 16px;
                                    }
                                }
                                .quote-byline {
                                    strong {
                                        display: block;
                                    }
                                }
                            }
                        }
                        &.cs-blockquote-no-image {
                            max-width: 705px;
                            padding: 120px 0px;
                            position: relative;
                            @media (max-width: 1100px) {
                                max-width: 100vw;
                                padding: 48px 40px;
                            }
                            .quote-byline {
                                display: flex;
                                align-items: center;
                                @media (max-width: 1100px) {
                                    flex-direction: column;
                                    align-items: flex-start;
                                }
                                strong {
                                    display: inline-block;
                                    padding-right: 8px;
                                    @media (max-width: 1100px) {

                                    }
                                }
                            }
                            .quote-text {
                                position: relative;
                                @media (max-width: 1100px) {
                                    max-width: 703px;
                                }
                                .scribbles {
                                    position: absolute;
                                    top: -60px;
                                    left: -160px;
                                    width: 60px;
                                    height: 60px;
                                    @media (max-width: 1600px) {
                                        top: -86px;
                                        left: -94px;
                                    }
                                    @media (max-width: 1100px) {
                                        top: -70px;
                                        left: auto;
                                        right: 15px;
                                    }
                                    .scribble-line {
                                        position: absolute;
                                        display: block;
                                        top: 0;
                                        left: 0;
                                        height: 35px;
                                        width: 28px;
                                        // <svg width="29" height="38" viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.03245 36.9783C0.618561 33.8556 0.693236 30.0442 1.9312 28.9789C3.16917 27.9136 5.01182 27.8719 6.67235 28.1241C8.33288 28.3765 10.0167 28.7244 11.6102 28.285C12.4641 28.0396 13.2663 27.4778 13.4645 26.6245C13.5454 26.2274 13.4703 25.8152 13.3654 25.3846C12.9094 23.7212 11.8215 22.2777 11.2029 20.6767C10.5844 19.0755 10.5391 17.0153 11.8135 15.8912C13.1725 14.6974 15.2577 15.1305 17.0559 15.4271C18.8541 15.7237 21.1595 15.6016 21.9275 13.9618C22.275 13.2008 22.1796 12.2883 21.9515 11.4566C21.5304 9.9367 20.6881 8.56341 20.2189 7.05448C19.7497 5.54556 19.6666 3.7465 20.6357 2.51534C21.956 0.850531 24.7688 0.883104 28.0497 1.9357" stroke="#2B3A5A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="bevel"/></svg>
                                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAyOSAzOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC4wMzI0NSAzNi45NzgzQzAuNjE4NTYxIDMzLjg1NTYgMC42OTMyMzYgMzAuMDQ0MiAxLjkzMTIgMjguOTc4OUMzLjE2OTE3IDI3LjkxMzYgNS4wMTE4MiAyNy44NzE5IDYuNjcyMzUgMjguMTI0MUM4LjMzMjg4IDI4LjM3NjUgMTAuMDE2NyAyOC43MjQ0IDExLjYxMDIgMjguMjg1QzEyLjQ2NDEgMjguMDM5NiAxMy4yNjYzIDI3LjQ3NzggMTMuNDY0NSAyNi42MjQ1QzEzLjU0NTQgMjYuMjI3NCAxMy40NzAzIDI1LjgxNTIgMTMuMzY1NCAyNS4zODQ2QzEyLjkwOTQgMjMuNzIxMiAxMS44MjE1IDIyLjI3NzcgMTEuMjAyOSAyMC42NzY3QzEwLjU4NDQgMTkuMDc1NSAxMC41MzkxIDE3LjAxNTMgMTEuODEzNSAxNS44OTEyQzEzLjE3MjUgMTQuNjk3NCAxNS4yNTc3IDE1LjEzMDUgMTcuMDU1OSAxNS40MjcxQzE4Ljg1NDEgMTUuNzIzNyAyMS4xNTk1IDE1LjYwMTYgMjEuOTI3NSAxMy45NjE4QzIyLjI3NSAxMy4yMDA4IDIyLjE3OTYgMTIuMjg4MyAyMS45NTE1IDExLjQ1NjZDMjEuNTMwNCA5LjkzNjcgMjAuNjg4MSA4LjU2MzQxIDIwLjIxODkgNy4wNTQ0OEMxOS43NDk3IDUuNTQ1NTYgMTkuNjY2NiAzLjc0NjUgMjAuNjM1NyAyLjUxNTM0QzIxLjk1NiAwLjg1MDUzMSAyNC43Njg4IDAuODgzMTA0IDI4LjA0OTcgMS45MzU3IiBzdHJva2U9IiMyQjNBNUEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPjwvc3ZnPg==');
                                        background-size: contain;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        transition: transform .3s;
                                        pointer-events: none;
                                    }
                                    .scribble-circle {
                                        position: absolute;
                                        display: block;
                                        bottom: 0;
                                        right: 0;
                                        width: 18px;
                                        height: 18px;
                                        // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.3771 1C17.039 2.97867 18.1841 5.34108 18.709 7.87434C19.2484 10.0724 19.0338 12.3886 18.0999 14.4493C16.7205 16.9149 14.2475 18.5688 11.4482 18.8977C8.08001 19.394 4.70208 18.0613 2.57321 15.3964C0.575274 12.72 0.416901 8.67482 2.46357 6.05951C4.51024 3.44421 8.67667 2.66817 11.3568 4.66631" stroke="#2B3A5A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUuMzc3MSAxQzE3LjAzOSAyLjk3ODY3IDE4LjE4NDEgNS4zNDEwOCAxOC43MDkgNy44NzQzNEMxOS4yNDg0IDEwLjA3MjQgMTkuMDMzOCAxMi4zODg2IDE4LjA5OTkgMTQuNDQ5M0MxNi43MjA1IDE2LjkxNDkgMTQuMjQ3NSAxOC41Njg4IDExLjQ0ODIgMTguODk3N0M4LjA4MDAxIDE5LjM5NCA0LjcwMjA4IDE4LjA2MTMgMi41NzMyMSAxNS4zOTY0QzAuNTc1Mjc0IDEyLjcyIDAuNDE2OTAxIDguNjc0ODIgMi40NjM1NyA2LjA1OTUxQzQuNTEwMjQgMy40NDQyMSA4LjY3NjY3IDIuNjY4MTcgMTEuMzU2OCA0LjY2NjMxIiBzdHJva2U9IiMyQjNBNUEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=');
                                        background-size: contain;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        transition: transform .3s;
                                        pointer-events: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .cs-block-blockquote + .cs-block-body {
                > div {
                    h2 {
                        &:first-child {
                            margin-top: 0px;
                        }
                    }
                }   
            }
        }
        .cse-cta-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 130px;
            @media (max-width: 992px) {
                margin-left: 20px;
                margin-right: 20px;
            }
            @media (max-width: 768px) {
                margin-bottom: 80px;
            }
            .cse-cta {
                background: #F6F8FB;
                width: 100%;
                max-width: 704px;
                padding: 60px;
                @media (max-width: 1100px) {
                    padding: 36px 24px;
                }
                .cta-headline {
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 54px;
                    color: #2B3A5A;
                    margin-bottom: 14px;
                    @media (max-width: 1100px) {
                        font-size: 28px;
                        line-height: 36px;
                        margin-bottom: 10px;
                    }
                }
                .cta-desc {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    color: #494B50;
                    opacity: 0.8;
                    margin-bottom: 28px;
                    @media (max-width: 1100px) {
                        margin-bottom: 24px;
                    }
                }
                .cta-button {
                    position: relative;
                    display: inline-block;
                    a {
                        display: inline-block;
                        background: #3E8AF6;
                        border-radius: 4px;
                        padding: 17px 30px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        color: #FFFFFF;
                        transition: background-color 0.3s;
                        &:hover {
                            background-color: #3275E1;
                        }
                    }
                    .cta-arrow {
                        position: absolute;
                        bottom: 5px;
                        right: -80px;
                        width: 50px;
                        height: 30px;
                        // <svg width="50" height="31" viewBox="0 0 50 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M48.8283 1C48.6484 7.16132 46.2219 13.3951 41.5743 17.4393C36.927 21.4836 29.9619 22.982 24.3509 20.4525C22.4172 19.5806 20.5235 17.992 20.3987 15.8716C20.2531 13.3978 22.9107 11.3314 25.3606 11.68C27.8104 12.0289 29.6991 14.3131 30.0754 16.7621C30.4516 19.2113 29.5334 21.7221 28.0788 23.728C25.0767 27.8667 19.8164 30.0939 14.7141 29.8914C9.61165 29.6887 4.73771 27.2251 1.13103 23.6071M6.018 22.686C4.3652 22.7751 1.01812 23.5575 1.00021 23.611C0.985441 23.656 1.76115 27.2733 2.5969 28.8513" stroke="#2B2256" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCA1MCAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDguODI4MyAxQzQ4LjY0ODQgNy4xNjEzMiA0Ni4yMjE5IDEzLjM5NTEgNDEuNTc0MyAxNy40MzkzQzM2LjkyNyAyMS40ODM2IDI5Ljk2MTkgMjIuOTgyIDI0LjM1MDkgMjAuNDUyNUMyMi40MTcyIDE5LjU4MDYgMjAuNTIzNSAxNy45OTIgMjAuMzk4NyAxNS44NzE2QzIwLjI1MzEgMTMuMzk3OCAyMi45MTA3IDExLjMzMTQgMjUuMzYwNiAxMS42OEMyNy44MTA0IDEyLjAyODkgMjkuNjk5MSAxNC4zMTMxIDMwLjA3NTQgMTYuNzYyMUMzMC40NTE2IDE5LjIxMTMgMjkuNTMzNCAyMS43MjIxIDI4LjA3ODggMjMuNzI4QzI1LjA3NjcgMjcuODY2NyAxOS44MTY0IDMwLjA5MzkgMTQuNzE0MSAyOS44OTE0QzkuNjExNjUgMjkuNjg4NyA0LjczNzcxIDI3LjIyNTEgMS4xMzEwMyAyMy42MDcxTTYuMDE4IDIyLjY4NkM0LjM2NTIgMjIuNzc1MSAxLjAxODEyIDIzLjU1NzUgMS4wMDAyMSAyMy42MTFDMC45ODU0NDEgMjMuNjU2IDEuNzYxMTUgMjcuMjczMyAyLjU5NjkgMjguODUxMyIgc3Ryb2tlPSIjMkIyMjU2IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        @media (max-width: 1100px) {
                            bottom: 10px;
                            right: -70px;
                        }
                    }
                }
            }
        }
        .cse-related-wrapper {
            max-width: 1200px;
            margin: 0 auto;
            .cse-related {
                .cse-related-heading {
                    margin-bottom: 80px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: 1100px) {
                        margin-bottom: 40px;
                    }
                    h2 {
                        margin-bottom: 20px;
                        @media (max-width: 1100px) {
                            font-size: 24px;
                            line-height: 29px;
                            margin-bottom: 16px;
                        }
                    }
                }
                .cse-related-stories {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -30px;
                    margin-right: -30px;
                    margin-bottom: 60px;
                    @media (max-width: 1240px) {
                        padding-left: 20px;
                        padding-right: 20px;
                        margin-left: -10px;
                        margin-right: -10px;
                    }
                    @media (max-width: 1100px) {
                        margin-bottom: 30px;
                    }
                    @media (max-width: 768px) {
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    &.fade-out {
                        opacity: 0;
                        transition: opacity 0.3s;
                    }
                }
            }
        }
    }
    // override curved underline and replace with scribble
    // this was really only configured to work with the word "best"...
    // TODO... maybe... ???
    .page-headline {
        .curved-underlines {
            u {
                position: relative;
                display: inline-block;
                margin-bottom: 0px;
                svg {
                    display: none;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -3px;
                    left: -10px;
                    bottom: -5px;
                    right: -10px;
                    background-image: url('/img/scribble.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    @media (max-width: 1024px) {
                        left: -4px;
                        right: -4px;
                    }
                }
            }
        }
    }
    .subheading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #858E99;
        opacity: 0.75;
        margin-bottom: 25px;
    }
    .wistia_click_to_play {
        position: absolute !important;
    }
}
.customer-stories-container,
.cse-related-stories {
    a.customer-story-item {
        width: 50%;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 56px;
        // opacity: 0;
        transition: opacity 0.8s;
        @media (max-width: 1240px) {
            padding-left: 10px;
            padding-right: 10px;
            // margin-bottom: 24px;
            margin-bottom: 40px;
        }
        @media (max-width: 768px) {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }
        &:hover {
            .csi-image {
                .csi-image-bg {
                    transform: scale(1.05);
                    &:after {
                        background: rgba(#000000, 0.2);
                    }
                }
            }
            .csi-title {
                span {
                    border-bottom: 2px solid rgba(#2B3A5A, 1);
                }
            }
        }
        &.fade-in {
            opacity: 1;
        }
        @for $i from 1 through 6 {
            &.fade-in-#{$i} {
                transition-delay: #{$i * 0.2}s;
            }
        }
        .csi-image {
            padding-bottom: 60%;
            position: relative;
            z-index: 10;
            margin-bottom: 28px;
            overflow: hidden;
            border-radius: 2px;
            .csi-image-bg {
                background-color: rgba(#E3E8EF, 0.4);
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 5;
                transition: transform 0.4s;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    transition: background-color 0.4s;
                    background: rgba(#000000, 0);
                }
            }
            .csi-image-logo {
                position: absolute;
                z-index: 10;
                top: 8px;
                right: 8px;
                background: #FFF;
                padding: 10px;
                height: 48px;
                border-radius: 1px;
                img {
                    height: 100%;
                    width: auto;
                }
            }
        }
        .csi-categories {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #000000;
            opacity: 0.7;
            margin-bottom: 12px;
        }
        .csi-title {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #2B3A5A;
            margin-bottom: 8px;
            span {
                font-family: 'Barlow';
                transition: border-color 0.4s;
                border-bottom: 2px solid rgba(#2B3A5A, 0);
            }
            @media (max-width: 1100px) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .csi-summary {
            font-family: 'Inter';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #494B50;
        }
        &.csi-skeleton {
            opacity: 0.4 !important;
            pointer-events: none;
            .csi-image {
                margin-bottom: 12px;
                .csi-image-bg {
                    background: #E3E8EF;
                }
            }
            .csi-categories {
                width: 150px;
                background: #E3E8EF;
                height: 18px;
                opacity: 1;
            }
            .csi-title {
                width: 420px;
                background: #E3E8EF;
                height: 30px;
            }
            .csi-summary {
                width: 100%;
                background: #E3E8EF;
                height: 50px;
            }
        }
    }
}