.home-matrix-products {
    position: relative;
    padding-top: 130px;
    background-color: rgb(246, 248, 251);
    @media (max-width: 1400px) {
        padding-top: 80px;
    }
    .home-products {
        width: 100%;
        max-width: 1230px;
        margin: auto;
        margin-bottom: 230px;
        @media (max-width: 1400px) {
            width: calc(100% - 80px);
            margin: 0px 40px 100px;
        }
        @media (max-width: 768px) {
            width: calc(100% - 40px);
            margin: 0px 20px 50px;
        }
        h1, h2 {
            margin: auto;
            text-align: center;
            margin-bottom: 20px;
            color: #2b3a5a;
            font-family: Barlow;
            font-size: 55px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 70px;
            margin-bottom: 26px;
            @media (max-width: 768px) {
                font-size: 28px;
                line-height: 40px;
            }
        }
        .products-text.lead {
            max-width: 865px;
            width: 100%;
            margin: auto;
            text-align: center;
            margin-bottom: 50px;
            font-size: 22px;
            line-height: 36px;
            font-weight: 300;
            @media (max-width: 768px) {
                margin-bottom: 55px;
                color: rgb(73, 75, 80);
                font-size: 18px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 29px;
            }
        }
        .products {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            margin-top: 85px;
            @media (max-width: 1023px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 45px;
            }
            @media (max-width: 768px) {
                margin-top: 0px;
            }
            .product {
                position: relative;
                width: 330px;
                padding-bottom: 50px;
                margin-bottom: 50px;
                @media (max-width: 1160px) {
                    width: 280px;
                }
                @media (max-width: 1023px) {
                    width: 100%;
                    margin: 40px auto;
                    .product-line {
                        display: none;
                    }
                    > img {
                        margin: 0 auto 46px;
                    }
                }
                @media (max-width: 768px) {
                    margin: 20px auto;
                    padding-top: 16px;
                    &:first-child {
                        margin-top: 0px;
                    }
                }
                .product-line {
                    position: absolute;
                    width: 130px;
                    left: 100%;
                    top: 130px;
                    margin-left: -20px;
                    line {
                        stroke: black;
                        stroke-width: 1px;
                        stroke-dasharray: 4px 4px;
                    }
                }
                &:last-child {
                    .product-line {
                        display: none;
                    }
                }
                .product-illustration {
                    margin: 0px auto 34px;
                    height: 280px;
                    max-width: 80%;
                    @media (max-width: 768px) {
                        height: auto;
                    }
                }
                .subheading {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    > img {
                        margin-right: 12px;
                    }
                }
                .heading {

                }
                .text {

                }
                .basic-link {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
    .perfect-slant {
        position: absolute;
        top: 100%;
        width: 100%;
        height: 660px;
        transform: scale(-1);
        fill: rgb(246, 248, 251);
        @media (max-width: 768px) {
            height: 240px;
        }
    }
}