@mixin triggerLinkAnimation {
  .internal-link {
    transform: translateX(10px);
  }
  .external-link {
    .arrow {
      transform: translate(2px, -2px);
    }
  }
}
@mixin aspect-ratio($height: $default-height, $width: $default-width) {
  padding-top: calc($height / $width) * 100%;
}
