.job-vacancies {
    max-width: 835px;
    margin: 0 auto;
    margin-bottom: 235px;
    @media (max-width: 992px) {
        margin-bottom: 117px;
    }
    .jobs-inner {
        padding-left: 20px;
        padding-right: 20px;
        a.job {
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #ECF1F7;
            padding: 37px 0px;
            &:hover {
                h3 {
                    span {
                        background-size: 100% 1px !important;
                    }
                }
            }
            @media (max-width: 992px) {
                flex-direction: column;
                align-items: flex-start;
                padding: 32px 0px;
            }
            &:last-child {
                border-bottom: 0px;
            }
            .job-details {
                h3 {
                    margin-bottom: 7px;
                    @media (max-width: 992px) {
                        font-size: 24px;
                        line-height: 34px;
                    }
                    span {
                        // underline animation stuff
                        background-repeat: no-repeat;
                        background-image: linear-gradient(to right, rgb(43, 58, 90) 0%, rgb(43, 58, 90) 100%);
                        background-position: 0px 100%;
                        background-size: 0px 1px;
                        transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                        padding: 0.6% 0px;
                    }
                }
                .job-location {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #858E99;
                    @media (max-width: 992px) {
                        margin-bottom: 9px;
                    }
                }
            }
            .job-link {
                display: flex;
                align-items: center;
                span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    color: #2B3A5A;
                    svg {
                        max-width: 14px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}