.matrix-related {
    .background-wrapper {
        &.background-padding-padded {
            padding-top: 150px;
            @media (max-width: 768px) {
                padding-top: 0;
            }
        }
    }
    .related-title {
        text-align: center;
        margin-bottom: 54px;
        padding-top: 64px;
        @media (max-width: 1230px) {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 50px;
        }
    }
    .video-card {
        width: 1230px;
        height: 600px;
        padding: 80px;
        position: relative;
        z-index: 5;
        margin: 0 auto -70px;
        display: flex;
        flex-direction: column;
        @media (max-width: 1230px) {
            width: calc(100% - 80px);
        }
        @media (max-width: 768px) {
            width: calc(100% - 40px);
        }
        .placeholder {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(15deg, rgba(0,0,0,0.8575805322128851) 10%, rgba(0,0,0,0) 54%);
            }
        }
        .video-info {
            margin-top: auto;
            max-width: 60%;
            z-index: 2;
            .video-sub {
                display: inline-flex;
                .video-link {
                    display: inline-flex;
                    align-items: center;
                    cursor: pointer;
                    margin-bottom: 20px;
                    .play-icon {
                        position: relative;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: 6px solid white;
                            border-radius: 50%;
                            transition: transform .3s .05s;
                        }
                        margin-right: 20px;
                    }
                    .subtitle {
                        color: rgb(255, 255, 255);
                        font-weight: bold;
                        font-size: 12px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        position: relative;
                        &:before {
                            content: '';
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            width: 0%;
                            height: 1px;
                            background: white;
                            transition: width .4s;
                        }
                    }
                    &:hover {
                        .play-icon {
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                        .subtitle {
                            &:before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .video-title {
                font-family: Barlow;
                color: rgb(255, 255, 255);
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0px;
                line-height: 50px;
            }
        }

        @media (max-width: 768px) {
            height: 448px;
            @media (max-width: 512px) {
                height: 232px;
            }
            padding: 20px;
            .video-info {
                max-width: 100%;
                .video-sub {
                    .video-link {
                        margin-bottom: 12px;
                        .play-icon {
                            width: 30px;
                            margin-right: 13px;
                            svg {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .subtitle {
                            color: rgb(255, 255, 255);
                            font-family: Inter;
                            font-size: 10px;
                            font-weight: bold;
                            letter-spacing: 1.67px;
                            text-transform: uppercase;
                        }
                    }
                }
                .video-title {
                    font-size: 18px;
                    font-family: Barlow;
                    letter-spacing: 0px;
                    line-height: 24px;
                }
            }
        }
    }
    .cards {
        margin-bottom: -150px;
        &.card-count-0 {
            display: none;
        }
        .card {
            padding: 60px;
            @media (max-width: 768px) {
                min-height: 302px;
                padding: 40px !important;
            }
            position: relative;
            .logo {
                padding: 8px 0;
                padding-top: 0px;
                @media (max-width: 768px) {
                    margin-bottom: 12px;
                }
            }
            .card-text {
                max-width: 380px;
                @media (max-width: 768px) {
                    padding-bottom: 30px;
                    h3 {
                        font-size: 24px;
                    }
                }
            }
            .card-link {
                position: absolute;
                right: 60px;
                bottom: 60px;
                @media (max-width: 768px) {
                    right: 39px;
                    bottom: 35px;
                }
                svg {
                    width: 24px !important;
                    [stroke]:not([stroke="none"]) {
                        stroke-width: 1;
                    }
                }
            }
        }
    }
    .related-text {
        position: relative;
        overflow: hidden;
        padding-top: 120px;
        padding-bottom: 200px;
        text-align: center;
        z-index: 1;
        &.has-video {
            padding-top: 100px;
        }
        .text-content {
            color: white;
            * {
                color: white;
            }
            width: 800px;
            margin: 200px auto 65px;
            position: relative;
            z-index: 1;
            @media (max-width: 1230px) {
                width: 100%;
                padding: 40px;
            }
            @media (max-width: 768px) {
                padding: 20px;
            }
            h2 {
                u {
                    text-decoration: none;
                    padding-bottom: 3px;
                    border-bottom: 1px solid #fff;
                }
            }
        }
        .button {
            position: relative;
            z-index: 1;
            color: rgb(0, 67, 75);
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            line-height: 16px;
            text-align: center;
            width: 100px;
            border-radius: 4px;
            height: 50px;
            padding: 17px 28px;
            overflow: hidden;
            box-shadow: 0 0 0 rgba(0,0,0,0.5);
            transition: box-shadow .3s;
            .button-text {
                position: relative;
            }
            .button-bg {
                position: absolute;
                border-radius: 4px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgb(205, 255, 244);
                transition: background-color .3s;
                // transition: filter .3s;
                // filter: brightness(100%);
            }
            &:hover {
                box-shadow: 0 0 50px rgba(0,0,0,0.4);
                .button-bg {
                    background-color: #ffffff !important;
                    // filter: brightness(92%);
                }
            }
        }
        .background-image {
            position: absolute;
            bottom: -10%;
            right: -10%;
        }
    }
    @media (max-width: 768px) {
        .related-title {
            margin: auto;
            margin-bottom: 0px;
            padding-bottom: 7px;
        }
        .related-text {
            .text-content {
                margin-top: 180px;
                margin-bottom: 12px;
            }
            .background-image {
                position: absolute;
                left: 10%;
                bottom: -10%;
                max-width: none;
            }
            &.has-video {
                .text-content {
                    margin-top: 110px;
                }
            }
        }
    }
}