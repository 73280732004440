.product .product-bg {
    top: -600px;
    height: 1545px;
    transform: skewY(-22.5deg);
    @apply pointer-events-none absolute inset-x-0 bg-blue-800;
}

.product {
    .product-header {
        padding-top: 90px;
        .header-content {
            h4 {
                letter-spacing: 2px;
            }

            h1 {
                letter-spacing: 0;
                @screen md {
                    line-height: 105px;
                }
            }

            p {
                max-width: 650px;
                margin: 27px auto;
                letter-spacing: 0;
                line-height: 36px;
                font-size: 21px;
            }

            .visual {
                margin: 72px -30px 110px;
            }
        }
    }
}