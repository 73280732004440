.campaign-page {
  .matrix-related {
    .background-wrapper {
      .related-text {
        padding: 180px 0;
        @screen md {
          padding: 215px 0 185px;
        }
        @screen xl {
          padding: 200px 0;
        }
        .text-content {
          margin: 0 auto 32px;
          padding: 0 20px;
          @screen md {
            margin: 0 auto 60px;
          }
          @screen xl {
            margin: 0 auto 65px;
          }
        }
        .button {
          @apply font-sans;
          padding: 10px 28px;
          line-height: 30px;
          width: auto;
          display: inline-block;
        }
        .background-image {
          bottom: -10%;
          right: -10%;
          @screen md {
            display: none;
          }
          @screen xl {
            display: block;
          }
        }
      }
    }
  }
}
