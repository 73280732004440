.matrix-text {
    text-align: center;
    position: relative;
    .text-content {
        max-width: 560px;
        margin: auto;
        font-size: 19px;
        padding-bottom: 125px;
        &.has-background-colour {
            padding-top: 120px;
        }
        &.width-wide {
            max-width: 880px;
            p {
                max-width: 700px;
            }
        }
        &.width-full {
            max-width: 100%;
        }
        &.padding-minimal {
            padding-top: 10px;
            padding-bottom: 10px;
            &.has-background-colour {
                padding-top: 100px;
                @media (max-width: 768px) {
                    padding-top: 40px;
                }
            }
        }
        &.padding-medium {
            padding-top: 10px;
            padding-bottom: 40px;
            &.has-background-colour {
                padding-top: 100px;
                @media (max-width: 768px) {
                    padding-top: 50px;
                }
            }
        }
        &.has-text-colour {
            * {
                color: currentColor;
            }
        }
        &.mobile-align-left {
            @media (max-width: 768px) {
                text-align: left;
            }
        }
        &.style-aboutBlue {
            h1 {
                @media (max-width: 768px) {
                    font-size: 29px;
                    line-height: 35px;
                    margin-bottom: 33px;
                    u {
                        font-size: 29px;
                        line-height: 35px;
                        margin-bottom: 33px;
                    }
                }
            }
        }
        p {
            max-width: 600px;
            margin: auto;
        }
        @for $i from 1 through 10 {
            &.h-size-#{$i} {
                h1,h2,h3,h4,h5,h6 {
                    max-width: 200px + ($i * 100);
                    margin: auto;
                }
            }
            &.p-size-#{$i} {
                p {
                    max-width: 200px + ($i * 100);
                }
            }
        }
        @media (max-width: 1230px) {
            padding: 40px;
            
            padding-bottom: 150px;
            width: 100%;
        }
        @media (max-width: 768px) {
            padding: 20px;
        }

    }
    @media (max-width: 768px) {
        // text-align: left;
        padding-bottom: 10px;
        .text-content {
            &.has-background-colour {
                padding-top: 50px;
            }
        }
    }
}