.matrix-pageFeature {
    .module-bg-light {
        @apply bg-gray-100;
    }
    .module-bg-dark {
        @apply bg-blue-800;
        h1, h4, p, h2, a {
            @apply text-white;
        }
    }

    .feature-details {
        width: 100%;
        max-width: 870px;
        padding: 50px 30px;
        @screen md {
            padding: 0 135px;
        }
        h4 {
            letter-spacing: 2px;
        }
        h2 {
            font-size: 42px;
            letter-spacing: 0px;
            line-height: 56px;
            margin-top: 20px;
            margin-bottom: 39px;
        }
        p {
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 30px;
            margin-bottom: 63px;
        }
        a {
            font-size: 18px;
            letter-spacing: 0px;
        }
    }
    .feature-image {
        height: 300px;
        @screen md {
            height: 800px;
        }
    }
}