.web-catalogue-banner {
  background-color: #F6F8FB;
  > .container {
    padding: 115px 40px 15px;
    display: grid;
    place-items: center;
    gap: 30px;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      img {
        width: 30px;
      }

      small {
        color: $website-primary;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 2px;
      }
    }

    h1 {
      font-family: $font-heading;
      font-size: 55px;
      font-weight: 700;
      text-align: center;
      color: $brand-midnight-blue;
    }
  }
}
