.matrix-pageGrid {
    .module-grid {
        padding: 150px 0 50px;
        @screen md {
            padding: 190px 0 79px;
        }
    }
    .module-bg-white {
        @apply bg-white;
    }
    .module-bg-light {
        @apply bg-gray-100;
    }
    .module-bg-dark {
        @apply bg-grey-900;
        h2, p, h4 {
            @apply text-white;
        }
    }
    .grid-header {
        h4 {
            font-size: 12px;
            letter-spacing: 2px;
        }
        h2 {
            margin-top: 20px;
            margin-bottom: 109px;
            max-width: 810px;
            font-size: 42px;
            letter-spacing: 0px;
            line-height: 56px;
        }
    }
    .grid-item-wrapper {
        @screen md {
            &:nth-child(3n - 1) {
                .grid-item {
                    @apply mx-auto;
                }
            }
            &:nth-child(3n) {
                .grid-item {
                    @apply ml-auto;
                }
            }
        }
        .grid-item {
            max-width: 285px;
            margin-bottom: 60px;
            @screen md {
                margin-bottom: 0px;
                padding-top: 80px;
                padding-bottom: 80px;
            }
            h2 {
                margin-top: 30px;
                margin-bottom: 13px;
                font-size: 20px;
                letter-spacing: 0px;
            }
            p {
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 24px;
            }
        }
    }
}