.screenshot-header-outer {
    overflow: hidden;
    .screenshot-header {
        background-color: #2b2256;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: 100px;
        @media (max-width: 768px) {
            padding-top: 77px;
        }
        margin-bottom: 150px;
        .image {
            position: relative;
            z-index: 2;
        }
        .text {
            width: 100%;
            text-align: center;
            max-width: 660px;
            color: white;
            margin-bottom: 50px;
            @media (max-width: 768px) {
                margin-bottom: 32px;
            }
            * {
                color: white;
            }
        }
        .screenshot {
            margin-bottom: -80px;
        }
        .shape-fill {
            position: absolute;
            z-index: 1;
            top: 30%;
            left: -3%;
        }
        .shape-outline {
            position: absolute;
            z-index: 1;
            top: 0;
            right: -10%;
            [stroke] {
                stroke: #7d8eff;
            }
        }
        .shape-fill, .shape-outline {
            display: none;
        }
    }
    @media (max-width: 768px) {
        .screenshot-header {
            .shape-fill,
            .shape-outline {
                display: none
            }
            align-items: start;
            .text {
                text-align: left;
                padding: 0px 20px;
            }
            .screenshot {
                max-width: 145%;
                margin-left: 7px;
            }
            .lead {
                text-align: left;
            }
            .subheading {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}