.matrix-teamMembers {
  margin: 0px auto;
  .team-members-header {
    text-align: center;
    margin-bottom: 70px;
    .text,
    h3 {
      text-align: center;
      max-width: 600px;
      margin: 0 auto;
    }
    .text {
      margin-bottom: 90px;
      position: relative;
      p {
        color: rgb(73, 75, 80);
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 33px;
        text-align: center;
        &.lead {
          @media (max-width: 768px) {
            font-size: 18px;
            line-height: 29px;
          }
        }
      }
      .floating-arrow {
        position: absolute;
        left: -222px;
        top: 75px;
        svg {
          width: 150px;
        }
        @media (max-width: 1230px) {
          display: none;
        }
      }
    }
    @media (max-width: 768px) {
      margin: 20px;
      margin-top: 50px;
      .text {
        margin-bottom: 50px;
      }
      h3 {
        font-size: 24px;
      }
    }
  }

  .team-members-grid {
    max-width: 1230px;
    margin: 40px auto;
    padding: 20px;
    display: grid;
    gap: 20px;
    row-gap: 65px;
    column-gap: 20px;
    @media (min-width: 768px) and (max-width: 896px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 897px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .team-member {
      img {
        width: 100%;
        margin-bottom: 25px;
        @media (max-width: 768px) {
          margin-bottom: 19px;
        }
      }
      h4 {
        margin-bottom: 10px;
        .person-flag {
          margin-left: 6px;
          font-size: 20px;
        }
        @media (max-width: 768px) {
          font-size: 20px;
          .person-flag {
            margin-left: 11px;
          }
        }
      }
      .member-title {
        margin-bottom: 10px;
        color: #aab0bd;
        font-size: 14px;
        font-weight: 600;
      }
      .member-fact {
        color: rgb(73, 75, 80);
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 28px;
      }
    }
  }
}
