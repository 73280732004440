.matrix-explore {
    margin: 140px 0;
    @media (max-width: 768px) {
        margin: 24px 0;
    }
}
.explore {
    &.has-general-image {
        width: 1230px;
        margin: auto;
        position: relative;
        overflow: visible;
        background: none !important;
        .empty-window, .floating-background {
            display: none;
        }
        .text {
            width: 600px;
            margin-bottom: 80px;
        }
        .destinations {
            width: 390px;
            &:before {
                content: 'EXPLORE';
                display: flex;
                align-items: center;
                min-height: 60px;
                font-family: Inter;
                font-weight: bold;
                font-size: 12px;
                color: #858e99;
                letter-spacing: 2px;
                border-bottom: 1px solid rgba(133, 142, 153, 0.2);
            }
            .destination {
                min-height: 120px;
                display: flex;
                align-items: stretch;
                border-bottom: 1px solid rgba(133, 142, 153, 0.2);
                .dest-button {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    .text {
                        display: none;
                    }
                    .arrow {
                        margin-left: auto;
                        margin-top: 10px;
                        margin-right: 16px;
                        opacity: 1;
                        transform: translateX(-10px);
                        transition: opacity .3s, transform .3s;
                        .internal-link {
                            svg {
                                width: 21px;
                                [stroke] {
                                    stroke: #838E9A;
                                }
                            }
                        }
                    }
                    &:hover {
                        .arrow {
                            opacity: 1;
                            transform: translateX(0px);
                        }
                    }
                }
            }
            .basic-link {
                margin-top: 35px;
            }
        }
        .explore-general-image {
            position: absolute;
            width: 1130px;
            top: 0;
            left: 525px;
            img {
                width: 100%;
            }
        }
        @media (max-width: 1230px) {
            padding: 40px;
        }
        @media (max-width: 768px) {
            padding: 20px;
        }
        @media (max-width: 768px) {
            width: 100%;
            .text {
                width: 100%;
                margin-bottom: 30px;
            }
            .explore-general-image {
                position: static;
                width: 100%;
                margin-bottom: 20px;;
                img {
                    min-width: 125%;
                }
            }
            .destinations {
                width: 100%;
                padding-top: 0px;
                padding-bottom: 20px;
                .destination {
                    min-height: 100px;
                    .dest-button {
                        .button-logo {
                            img {
                                max-height: 27px;
                            }
                        }
                        .arrow {
                            opacity: 1;
                            transform: translateX(0);
                            margin-right: 5px;
                            svg {
                                width: 20px;
                                [stroke]:not([stroke=none]) {
                                    stroke-width: 1.5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.no-general-image {
        &.has-text-colour {
            * {
                color: currentColor;
            }
            .dest-button {
                .text {
                    .initial-text, .additional-text {
                        * {
                            color: currentColor;
                        }
                    }
                }
            }
        }
        .explore-content {
            .subheading {

            }
            > .text {
                padding-top: 140px;
                padding-bottom: 68px;
                width: 1230px;
                margin: auto;
                .text-content {
                    width: 600px;
                }
                @media (max-width: 1230px) {
                    padding-left: 40px;
                    padding-right: 40px;
                }
                @media (max-width: 768px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
            .destinations {
                position: relative;
                width: 1230px;
                padding-bottom: 170px;
                padding-top: 1px;
                margin: auto;
                &.hovering-other {
                    .destination:not(.active) {
                        .dest-button {
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
                .destination {
                    margin-top: -30px;
                    @media (max-width: 768px) {
                        margin-top: 0;
                    }
                    transition: margin .3s, padding .3s;
                    &:not(.active) {
                        .dest-button {
                            cursor: pointer;
                        }
                    }
                    &.active {
                        margin-top: 0;
                        margin-bottom: 30px;
                        &.last-dest {
                            margin-bottom: 60px;
                        }
                        .dest-button {
                            opacity: 1;
                            .button-background {
                                @media (max-width: 768px) {
                                    opacity: 0.1;
                                }
                            }
                            .text {
                                height: 130px;
                                .additional-text {
                                    opacity: 1;
                                    // display: block;
                                }
                            }
                        }
                        .images {
                            opacity: 1;
                        }
                        + .destination {
                            margin-top: 0;
                        }
                    }
                    .dest-button {
                        opacity: 0.5;
                        position: relative;
                        border-radius: 10px;
                        width: 533px;
                        padding: 35px;
                        @media (min-width: 800px) and (max-width: 1200px) {
                            padding-left: 50px;
                        }
                        display: flex;
                        margin-left: -30px;
                        transition: opacity .3s;
                        .streamline-stroke-icon {
                            flex-shrink: 0;
                            width: 30px;
                            height: 30px;
                            svg {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .button-background {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: #5C6CFF;
                            border-radius: 10px;
                            opacity: 0;
                            transition: opacity 0.3s;
                        }
                        .text {
                            margin-left: 30px;
                            transition: height .3s;
                            height: 40px;
                            .additional-text {
                                opacity: 0;
                                transition: opacity .3s;
                                width: 360px;
                                @media (max-width: 768px) {
                                    width: auto;
                                }
                                .basic-link {
                                    margin-top: 20px;
                                }
                                // display: none;
                                position: absolute;
                            }
                        }
                    }
                    .images {
                        opacity: 0;
                        position: absolute;
                        z-index: 2;
                        top: -30px;
                        left: 700px;
                        width: 1000px;
                        transition: opacity 0.3s;
                        pointer-events: none;
                        @media (max-width: 768px) {
                            img {
                                max-height: 100%;
                            }
                        }
                    }
                }
                .floating-background {
                    position: absolute;
                    top: 0;
                    left: -35px;
                    width: 533px;
                    height: 215px;
                    background-color: #5C6CFF;
                    border-radius: 10px;
                    opacity: 0.1;
                    transition: top .3s;
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
                .empty-window {
                    opacity: 1;
                    position: absolute;
                    z-index: 1;
                    top: -30px;
                    left: 700px;
                    width: 1000px;
                    transition: opacity 0.3s;
                    pointer-events: none;
                }
                @media (max-width: 1230px) {
                    width: 100%;
                    padding-left: 20px;
                    padding-bottom: 55px;
                    .destination {
                        .images {
                            left: 550px;
                            width: 700px;
                            top: 0px;
                        }
                    }
                    .empty-window {
                        display: none;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .explore-content {

                > .text {
                    width: 100%;
                    padding-top: 70px;
                    padding-bottom: 20px;
                    .text-content {
                        width: 100%;
                    }
                }
                .destinations {
                    .destination {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-direction: column-reverse;
                        .dest-button {
                            position: static;
                            width: 100%;
                            padding-bottom: 10px !important;
                            padding-top: 0px !important;

                            transition: padding .3s;
                            .button-background {
                                border-radius: 0;
                                right: 0;
                                left: -20px;
                            }
                            .streamline-stroke-icon {
                                margin-left: 20px;
                            }
                            .text {
                                .additional-text {
                                    padding-right: 40px;
                                }
                            }
                        }
                        .images {
                            height: 0px;
                            opacity: 0;
                            pointer-events: none;
                            transition: height .3s, opacity .1s;
                            position: static;
                            // width: 100%;
                            padding-top: 25px;
                            margin-left: -15px;
                            z-index: 1;
                            img {
                                width: auto;
                                height: auto;
                                margin: auto;
                            }
                        }
                        &.active {
                            padding-bottom: 25px;
                            margin-top: 20px;
                            margin-bottom: 50px;
                            .dest-button {
                                padding-bottom: 60px !important;
                                padding-top: 30px !important;
                            }
                            .images {
                                transition: height .3s, opacity .3s .2s;
                                height: 370px;
                                opacity: 1;
                                pointer-events: initial;
                            }
                        }
                    }
                }
            }
        }
    }
    .basic-link {
        margin-top: 50px;
    }
}