.matrix-video {
    .background-wrapper {
        position: relative;
        .background-element {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 175px;
            @media (max-width: 768px) {
                height: 100px;
            }
        }
        &.background-padding-padded {
            padding-top: 150px;
            @media (max-width: 768px) {
                padding-top: 0;
            }
        }
    }
    .video-container {
        overflow: hidden;
        position: relative;
        max-width: 1230px;
        margin: 0 auto;
        border-radius: 8px;
        &.image-only {
            &:after {
                padding-top: 56.25%;
                display: block;
                content: '';
            }
        }
        .wistia-element {
            opacity: 0;
            transition: opacity .3s;
            &:not(.active) {
                &, * {
                    pointer-events: none;
                }
            }
            &.active {
                opacity: 1;
            }
        }
        &.has-video {
            cursor: pointer;
        }
        @media (max-width: 1235px) {
            width: calc(100% - 80px);
        }
        @media (max-width: 768px) {
            width: calc(100% - 40px);
        }
        .video-placeholder-image,
        .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .video-placeholder-image {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            transition: all 0.35s;
        }
        .video-overlay {
            background: #000;
            opacity: 0;
            transition: opacity 0.35s;
        }
        .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 10px solid white;
                border-radius: 50%;
                transition: transform 0.35s;
            }
            @media (max-width: 768px) {
                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        &.has-video {
            &:hover {
                .video-placeholder-image {
                    transform: scale(1.03);
                }
                .video-overlay {
                    opacity: 0.3;
                }
                .play-icon {
                    &:before {
                        transform: scale(1.12);
                    }
                }
            }
        }
    }
}