.page-text {
    span {
        letter-spacing: 2px;
        margin-bottom: 28px;
    }
    h3 {
        font-size: 36px;
        letter-spacing: 0;
        line-height: 48px;
        padding-right: 65px;
    }
    p {
        padding-left: 65px;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 40px;
    }
}