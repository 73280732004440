.triple-image {
    position: relative;
    margin-top: 17px;
    padding-bottom: 100px;
    background-color: #f6f8fb;
    .slant {
        width: 100%;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        svg {
            width: 100%;
            height: 350px;
            @media (max-width: 768px) {
                height: 110px;
            }
            transform: scaleX(-1);
            .triangle {
                fill: #f6f8fb;
            }
        }
    }
    &.page-websites {
        @media (min-width: 769px) {
            background: none;
            margin-top: 220px;
            .slant {
                transform: translateY(120px);
                z-index: -1;
                svg {
                    height: 480px;
                }
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                background-color: #f6f8fb;
                top: 120px;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
    }
    &.page-apps {
        @media (max-width: 768px) {
            margin-top: 0px;
            .slant {
                display: none;
            }
        }
    }
    .initial-text {
        width: 1230px;
        margin: auto;
        padding-right: 600px;
        margin-bottom: 120px;
        @media (max-width: 768px) {
            margin-bottom: 0px;
        }
    }
    .image-columns {
        display: flex;
        width: 1230px;
        margin: auto;
        align-items: flex-start;
        .image-column {
            &:not(:last-child) {
                margin-right: 85px;
            }
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .image {
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 40px;
            }
        }
    }
    @media (max-width: 1230px) {
        width: 100%;
        padding: 40px;
        .initial-text{
            width: 100%;
            padding: 0;
        }
        .image-columns {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        margin-top: 100px;
        padding: 20px;
        padding-bottom: 49px;
        .image-columns {
            flex-direction: column;
            .image-column {
                .image {
                    padding-top: 40px;
                    height: auto;
                    min-height: 275px;
                    max-width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}