.web-catalogue-modal {
  position: fixed;
  inset: 0;
  visibility: hidden;

  &.is-active{
    visibility: visible;
    z-index: 99;
  }

  .modal-wrapper{
    position: relative;
    isolation: isolate;
    height: 100%;
    display: grid;
    place-items: center;
    align-content: center;
    padding: 0 10px;
    @screen md{
      align-content: unset;
    }

    .modal-background{
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: -1;
    }

    .modal-content {
      max-width: 1150px;
      height: 95vh;
      background: #fff;
      border-radius: 8px 8px 0 0;
      z-index: 1;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 44px;
      padding: 44px 0 44px 44px;
      position: relative;
      @screen md {
        height: auto;
        max-height: clamp(300px, 900px, 95vh);
        aspect-ratio: 1;
        padding: 44px 0px 44px 100px;
        grid-template-columns: 1fr 100px;
        border-radius: 8px;
      }
      .modal-close{
        position: absolute;
        display: flex;
        align-items: flex-start;
        top: 20px;
        right: 20px;
        margin: 0;
      }

      .counter-container {
        @screen md {
          display: flex;
          justify-content: space-between;
          gap: 15px;
        }
        &.heading {
          align-items: center;
        }

        &.description {
          margin-top: 16px;
        }

        .slide-counter {
          color: $strom-gray;
          font-size: 13px;
          margin-top: 10px;
          @screen md {
            font-size: 16px;
            margin-top: 0;
          }
        }
      }

      h4 {
        color: $brand-midnight-blue;
        font-font: $font-heading;
        font-size: 30px;
        font-weight: 700;
      }

      p {
        margin-top: 0;
        color: $strom-gray;
        font-size: 16px;
        line-height: 28px;
      }

      a {
        margin-top: 16px;
        display: flex;
        gap: 10px;
        align-items: center;

        &:hover {
          svg {
            animation: bounce-right 1.5s ease-in-out infinite;
          }
        }

         span {
           color: $brand-midnight-blue;
           font-size: 16px;
           font-weight: 600;
           line-height: 30px;
         }
      }

      .images-container{
        grid-column: 1 / span 2;
        overflow-y: auto;

        > div {
          padding-right: 44px;
          @screen md {
            padding-right: 100px;
          }
        }

        .images {
          margin-top: 14px;
          @screen md {
            margin-top: 24px;
          }
        }
      }

      .main-img-container {
        margin-top: 12px;

        .slick-arrows{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
          @screen md {
            justify-content:flex-end;
          }
          .slick-arrow {
            background-color: #fff;
            border: 1px solid #E5E7EB;
            border-radius: 8px;
            padding: 5px;

            @screen md {
              position: fixed;
              top: 50%;
            }

            &.slick-disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }

            &.slick-prev {
              @screen md {
                left: 20px;
              }
              @media (min-width: 1150px) {
                left: calc((100vw - 1150px) / 2 + 30px)
              }
              svg {
                transform: rotate(180deg);
              }
            }
            &.slick-next{
              @screen md {
                right: 20px;
              }
              @media (min-width: 1150px) {
                right: calc((100vw - 1150px) / 2 + 45px)
              }
            }
          }
        }

        .slick-slide:not(.slick-current) {
          height: 0px;
        }

        .slick-image {
          border-radius: 8px;
          .img-wrapper{
            @include aspect-ratio(400px, 660px);
          }
        }
      }

      .thumbnails {
        margin-top: 12px;
        display: none;

        @screen md {
          display: block;
        }

        .slick-track{
          margin-left: -5px;
          .slick-slide {
            width: 100px;
            height: 100px;

            &.slick-current {
              .slick-image{
                border: 2px solid $website-primary;
              }
            }
            > div {
              margin: 0px 5px;
            }
          }
        }

        .slick-image {
          border-radius: 8px;
          overflow: hidden;
          height: 100px;

          .img-wrapper {
            height: 100%;

            img {
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}

// extra div wrapper on slick slides Fix
.slick-slide > div {
  height: 100%;
}
