$screen-sm: 375px;
$screen-xl: 1440px;
$screen-2xl: 1920px;

.digi-uni-imageVideo {
  background-color: red;
  @apply bg-blue-900;
  display: block;
  .video-banner-hero {
    @screen lg {
      padding-bottom: min(calc(155px / $screen-2xl * 100vw), 155px);
    }
    .video-banner-logo {
      margin-top: 30px;
      @screen lg {
        margin-top: 100px;
      }
      img {
        width: clamp(200px, calc(333px / $screen-sm * 100vw), $screen-sm);
        @screen lg {
          width: clamp($screen-sm, calc(434px / $screen-2xl * 100vw), 434px);
          max-width: 434px;
        }
        @screen xl {
          width: 434px;
        }
      }
    }
    .video-banner-banner {
      @apply relative;
      margin-top: 30px;
      @screen lg {
        @apply items-start justify-center;
        width: 100%;
        margin-top: 90px;
        max-width: 1230px;
      }
      * {
        @apply text-white;
      }
      .graphic {
        &-grid,
        &-dots {
          @screen lg {
            display: block;
          }
        }
        &-grid {
          right: -92px;
          top: -142px;
        }
        &-dots {
          left: -277px;
          bottom: -57px;
          @screen xl {
            left: max(calc(-227px / $screen-xl * 100vw), -227px);
          }
          @media (min-width: $screen-2xl) {
            left: max(calc(-277px / $screen-2xl * 100vw), -277px);
          }
        }
        &-arrow {
          bottom: 20px;
          @media (min-width: 1411px) {
            display: block;
            left: max(calc(-104px / $screen-xl * 100vw), -109px);
          }
        }
      }
      .text {
        margin-top: 20px;
        @screen lg {
          margin-top: 0;
          padding-right: clamp(50px, calc(90px / $screen-2xl * 100vw), 90px);
          width: 100%;
          max-width: 52%;
        }
        @screen xl {
          max-width: 50%;
        }
        .heading {
          margin-bottom: 24px;
          * {
            font-size: 26px;
            line-height: calc(45px / 26px);
            max-width: 350px;
            @media (min-width: 450px) {
              font-size: 35px;
            }
            @screen md {
              font-size: 45px;
              line-height: calc(75px / 55px);
              max-width: 510px;
            }
            @screen lg {
              font-size: clamp(45px, calc(55px / $screen-xl * 100vw), 55px);
              line-height: calc(75px / 55px);
              max-width: 450px;
            }
            @screen xl {
              max-width: 500px;
            }
          }
          h1 {
            margin-bottom: 0;
            u {
              @apply relative inline-block no-underline mb-0;
              margin-right: 4px;
              @screen lg {
                margin-right: 12px;
              }
              &:before {
                content: '';
                @apply block absolute top-0 bottom-0 bg-no-repeat bg-contain;
                width: calc(100% + 10px);
                height: 48px;
                margin-left: -6px;
                padding-left: 6px;
                padding-right: 4px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 151 48' fill='none'%3E%3Cpath d='M43.5678 1.96748C72.1856 -1.24938 137.215 3.22085 147.924 20.4396C155.246 32.2117 144.451 45.7005 95.3723 46.8325C56.7629 47.723 3.9109 45.6405 1.09279 26.2597C-1.38863 9.23429 46.471 2.86058 75.9762 5.14149' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                @media (min-width: 450px) {
                  width: calc(100% + 16px);
                  height: 64px;
                  margin-left: -10px;
                }
                @screen md {
                  width: calc(100% + 32px);
                  height: 75px;
                  margin-left: -20px;
                  padding-left: 20px;
                  padding-right: 12px;
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 301 77' fill='none'%3E%3Cpath d='M86.4214 2.57741C143.849 -2.66747 274.345 4.62095 295.834 32.695C310.526 51.8886 288.864 73.8812 190.378 75.7269C112.9 77.1788 6.84133 73.7835 1.18621 42.1842C-3.79329 14.4255 92.2473 4.03356 151.456 7.75244' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }
              }
            }
          }
        }
        p {
          @apply font-normal;
          font-size: 18px;
          line-height: 1.6;
          @screen lg {
            font-size: clamp(16px, calc(20px / $screen-xl * 100vw), 20px);
            max-width: 490px;
          }
          @screen xl {
            // max-width: 480px;
          }
        }
        ul {
          margin: 17px 0 20px;
          @screen lg {
            font-size: clamp(16px, calc(20px / $screen-xl * 100vw), 20px);
            max-width: 490px;
          }
          @screen xl {
            max-width: 480px;
          }
          li {
            @apply font-semibold relative bg-contain bg-no-repeat bg-left;
            line-height: 1.77;
            padding-left: 37px;
            background-size: 20px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' viewBox='0 0 24 18' fill='none'%3E%3Cpath d='M2 10.4L7.71429 16L22 2' stroke='%23ED7445' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            @screen xl {
              line-height: 1.6;
            }
          }
        }
        .button {
          margin-top: 25px;
          padding: 16px 40px;
          @screen lg {
            margin-top: 33px;
          }
        }
      }
      .video-preview {
        @apply relative;
        @screen lg {
          width: 42%;
        }

        @screen xl {
          @apply w-full;
        }
        @media (min-width: 1440px) {
          margin-right: max(calc(-65px / $screen-xl * 100vw), -65px);
        }
        @media (min-width: $screen-2xl) {
          margin-right: max(calc(-150px / $screen-2xl * 100vw), -150px);
        }
        .preview-video {
          @apply p-0 w-full;
          .video-placeholder {
            @screen lg {
              @apply w-full pb-0;
              @include aspect-ratio($height: 1172, $width: 1506);
            }
            .placeholder-background {
              &:before {
                @apply hidden;
              }
            }
          }
        }
        .person-avatar {
          @apply absolute block;
          right: -13.5px;
          bottom: -17px;
          transform: rotate(4.115deg);
          @screen lg {
            right: clamp(-35px, calc(-55px / $screen-2xl * 100vw), -55px);
            bottom: -45px;
          }
          img {
            width: clamp(100px, 107 / 375 * 100vw, 200px);
          }
        }
      }
    }
  }
}
