.mobile-spacing {
    display: none;
}
@media (max-width: 768px) {
    .desktop-spacing {
        display: none;
    }
    .mobile-spacing {
        display: block;
    }
}