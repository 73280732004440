.web-catalogue-section {
  margin-top: 120px;

  &:not(:first-child) {
    padding-top: 120px;
    border-top: 1px solid rgba(#858E99, 0.5);
  }

  &:last-child {
    margin-bottom: 120px;
  }

  h2 {
    font-family: $font-heading;
    color: $brand-midnight-blue;
    font-size: 40px;
    font-weight: 700;
    line-height: 54px;
  }

  p {
    font-size: 16px;
    color: #494B50;
    margin-top: 16px;
    max-width: 870px;
  }

  .cards-wrapper {
    margin-top: 60px;
    display: grid;
    gap: 60px;

    @screen md {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-top: 80px;
      gap: 80px 40px;
    }

    &.is-row-layout {
      grid-template-columns: 1fr;

      .card.standard-card {
        display: grid;
        gap: 14px;
        @screen md {
          gap: 48px;
        }

        .img-outer{
          height: fit-content;
          align-self: center;

          .img-wrapper {
            @include aspect-ratio(210px, 330px);
            @screen lg {
              @include aspect-ratio(360px, 580px);
            }
          }
        }


        @screen md {
          grid-template-columns: repeat(2, minmax(0, 1fr));

          .card-content {
            margin-top: 0;
            padding: 10px 0;
          }
        }

        > * {
          grid-column: span 1;
        }
      }
    }

    .card{
      grid-column: span 1;
      //padding: 15px 0;
      cursor: pointer;

      .img-outer {
        border-radius: 4px;
        box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.14);
        .img-wrapper {
          @include aspect-ratio(350px, 550px)
        }
      }

      // standard cards
      &.standard-card .card-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 30px;

        h3 {
          font-family: $font-heading;
          color: $brand-midnight-blue;
          font-size: 30px;
          font-weight: 700;
        }

        p {
          color: $strom-gray;
          font-size: 16px;
          line-height: 24px;
          max-width: 480px;
          margin-top: 0;
        }

        .icons-wrapper{
          display: grid;
          gap: 8px;

          .icon{
            display: flex;
            gap: 16px;

            svg{
              width: 24px;
              height: 24px;
              path {
                fill: $website-primary;
              }

            }

            p {
              color: $strom-gray;
            }
          }
        }

        .view-website{
          margin-top: 10px;
          display: flex;
          align-items: center;
          gap: 10px;

          &:hover{
            svg {
              animation: bounce-right 1.5s ease-in-out infinite;
            }
          }

          span {
            color: #2B3A5A;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      // modal cards
      &.modal-card {
        .card-content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 30px;

          small {
            color: $website-primary;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
          }

          h3 {
            font-family: $font-heading;
            color: $brand-midnight-blue;
            font-size: 30px;
            font-weight: 700;
          }

          p {
            margin-top: 0;
            color: $strom-gray;
            font-size: 16px;
            line-height: 28px;
          }

          .item-btn {
            display: flex;
            align-items: center;
            gap: 10px;
          }


        }
      }
    }
  }
}
