.campaign-page {
  .matrix-textAndLogos {
    position: relative;
    .wrapper {
      @screen xl {
        display: grid;
        grid-template-columns: .85fr 1fr;
        grid-column-gap: 4.688rem;

        align-items: center;
      }
      @screen xl {
        padding: 165px 40px;
      }
      .text {
        text-align: left;
        margin: auto;
        @screen md {
          text-align: center;
          max-width: 60vw;
        }
        @screen xl {
          text-align: left;
          max-width: none;
        }
        .subheading {
          margin-bottom: 0;
        }
        h2 {
          @apply text-white;
          @screen md {
            font-size: 55px;
            line-height: 70px;
            max-width: 420px;
            margin: 18px auto
          }
          @screen xl {
            width: auto;
            margin: 18px 0;

          }
          @screen xl {
            margin: 24px 0 32px;
          }
        }
        p {
          @apply font-barlow text-white;
          @screen md {
            font-size: 22px;
            line-height: 36px;
          }
        }
      }
      .logos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 0.01rem;
        justify-content: space-around;
        margin-top: 54px;
        @screen md {
          margin-top: 33px;
          grid-column-gap: 3.25rem;
          margin-left: auto;
          margin-right: auto;
          max-width: 660px;
        }
        @screen xl {
          margin-top: 0;

        }

        img {
          margin: auto;
        }
      }
    }
  }
}
