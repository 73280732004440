.icon-header {
    position: relative;
    min-height: 200px;
    .text {
        width: 510px;
        @media (max-width: 768px) {
            width: auto;
            padding: 20px 20px 50px;
        }
        margin: auto;
        text-align: center;
        padding: 136px 0 202px;
        p {
            color: rgb(73, 75, 80);
            font-family: Barlow;
            font-size: 22px;
            font-weight: 300 !important;
            letter-spacing: 0px;
            line-height: 36px;
            @media (max-width: 768px) {
                font-size: 18px;
                font-weight: 400 !important;
                line-height: 30px;
            }
        }
        img {
            margin: 48px auto;
        }
    }
    .header-icons {
        .icon-section {
            position: absolute;
            top: 130px;
            bottom: 130px;
            width: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &:nth-child(1) {
                right: 50%;
                margin-right: 500px;
                @media (max-width: 1500px) {
                    margin-right: 420px;
                }
                @media (max-width: 1200px) {
                    margin-right: 300px;
                }
                .header-icon {
                    &:nth-child(2n) {
                        align-self: flex-end;
                    }
                    &:nth-child(2n + 1) {
                        align-self: flex-start;
                    }
                }
            }
            &:nth-child(2) {
                left: 50%;
                transform: translateX(-30px);
                margin-left: 500px;
                @media (max-width: 1500px) {
                    margin-left: 420px;
                }
                @media (max-width: 1200px) {
                    margin-left: 300px;
                }
                .header-icon {
                    &:nth-child(2n) {
                        align-self: flex-start;
                    }
                    &:nth-child(2n + 1) {
                        align-self: flex-end;
                    }
                }
            }
            .header-icon {
                &:nth-child(6) {
                    display: none;
                }
                
                // position: absolute;
                width: 69px;
                height: 69px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 6s;
                img {
                    width: 100%;
                    max-width: 40px;
                    max-height: 22px;
                }
                // @for $i from 1 through 30 {
                //     &:nth-child(#{$i}) {
                //     top: random(100) + 0%;
                //     left: random(100) + 0%;
                //     margin-top: -30px;
                //     margin-left: -30px;
                //     transform: scale((random(10) / 20) + 0.5);
                //     }
                // }
            }
        }
    }
    .shape-fill {
        display: none;
        position: absolute;
        z-index: -1;
        top: 30%;
        left: -100px;
    }
    .shape-outline {
        display: none;
        position: absolute;
        z-index: -1;
        top: 0;
        right: -300px;
    }
    @media (max-width: 1700px) {
        .shape-fill {
            left: calc(100vw - 1700px + -100px);
        }
        .shape-outline {
            right: calc(100vw - 1700px + -300px);
        }
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        .shape-fill, .shape-outline {
            display: none;
        }
        .header-icons {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: -30px;
            .icon-section {
                position: static;
                margin-left: -30px !important;
                margin-right: 0 !important;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                min-height: 100px;
                &:nth-child(1) {
                    .header-icon {
                        &:nth-child(2n) {
                            align-self: flex-end;
                        }
                        &:nth-child(2n + 1) {
                            align-self: flex-start;
                        }
                    }
                }
                &:nth-child(2) {
                    display: none;
                }
                .header-icon {
                    width: 35px;
                    height: 35px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: transform 6s;
                    img {
                        width: 100%;
                        max-width: 20px;
                        max-height: 11px;
                    }
                }
            }
        }
    }
}