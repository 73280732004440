$strom-gray: #494B50;
$website-primary: #00ACCF;
$brand-midnight-blue: #2B3A5A;

$font-heading: 'Barlow', sans-serif;

@mixin aspect-ratio($height, $width) {
  padding-top: calc($height / $width) * 100%;
}

@import 'nav';
@import 'banner';
@import 'filters';
@import 'section';
@import 'modal';
@import 'footer';

.web-catalogue {
  .img-outer {
    overflow: hidden;
  }

  .responsive-img {
    position: relative;
    width: 100%;
    @include aspect-ratio($height: 9, $width: 16);
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &.has-zoom {
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.03) translate(-50%, -50%);
        }
      }
      img {
        transform: scale(1) translate(-50%, -50%);
        transition: transform 0.35s ease-out;
        transform-origin: left center;
        a:hover & {
          transform: scale(1.03) translate(-50%, -50%);
        }
      }
    }
  }
}



@keyframes bounce-right {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(30%);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(0);
  }
}


@keyframes bounce-up {
  0% {
    transform: translateY(0);
  }
  19% {
    transform: translateY(20%);
  }
  38% {
    transform: translateY(-5%);
  }
  57% {
    transform: translateY(10%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
