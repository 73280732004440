.matrix-modules {
    > div {
        padding: 190px 0;
    }
    .module-bg-light {
        @apply bg-gray-100;
    }
    .module-bg-dark {
        @apply bg-grey-900;
        h1, h4, p {
            @apply text-white;
        }
    }
    h1 {
        font-size: 42px;
        line-height: 54px;
    }
    h4 {
        font-size: 24px;
        line-height: 36px;
        margin-top: 25px;
        margin-bottom: 31px;
    }
    p {
        font-size: 18px;
        line-height: 34px;
    }
    a {
        @apply inline-block;
        margin-top: 54px;
    }
}