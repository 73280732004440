.matrix-textAndImage {
    .text-and-image {
        position: relative;
        .squiggle {
            position: absolute;
        }
        .mobile-squiggle {
            @media (min-width: 769px) {
                display: none;
            }
        }
        .desktop-squiggle {
            @media (max-width: 768px) {
                display: none;
            }
        }
        .background-element-partial {
            display: none;
        }
        &.background-slant {
            padding-top: 100px;
            .background-element {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                @media (max-width: 768px) {
                    top: 45%;
                    bottom: 35%;
                }
                .perfect-slant {
                    width: 100%;
                    height: 80%;
                    margin-top: -150px;
                    .triangle {
                        fill: #f6f8fb;
                    }
                }
                &:after {
                    content: '';
                    display: block;
                    background-color: #f6f8fb;
                    width: 100%;
                    height: 50%;
                    @media (max-width: 768px) {
                        height: 350%;
                    }
                }
            }
        }
        &.background-straight,
        &.background-mobileSlant {
            &.has-background {
                background-color: #f6f8fb;
            }
            .background-element {
                display: none;
            }
        }
        &.background-mobileSlant {
            @media (max-width: 768px) {
                .background-element {
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 40%;
                    bottom: -100px;
                    z-index: -1;
                    background: #f6f8fb;
                    .perfect-slant {
                        position: absolute;
                        bottom: 100%;
                        width: 100%;
                        height: 200px;
                        transform: scaleX(-1);
                        .triangle {
                            fill: #f6f8fb;
                        }
                    }
                }
            }
        }
        &.background-partial {
            .background-element {
                display: none;
            }
            .background-element-partial {
                display: initial;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 90px;
                z-index: -1;
                @media (max-width: 768px) {
                    bottom: 0px;
                }
            }
        }
        &.image-left {
            .text-and-image-content {
                flex-direction: row-reverse;
                .image {
                    > img {
                        right: 30px;
                    }
                }
            }
        }
        &.image-right {
            .text-and-image-content {
                flex-direction: row;
                .image {
                    > img {
                        left: 30px;
                    }
                }
            }
        }
        &.scroll-animation {
            .text-and-image-content {
                min-height: 500px;
            }
            @media (max-width: 1230px) {
                transition: opacity 0.3s;
                &:not(.active) {
                    .text-and-image-content {
                        opacity: 0.3;
                    }
                }
            }
            @media (min-width: 1231px) {
                .image {
                    img {
                        transition: opacity 0.3s, margin 0.3s;
                    }
                }
                .text {
                    opacity: 0.3;
                    transition: opacity 0.3s;
                }
                &.active {
                    .image {
                        img {
                            transition: opacity 0.3s 0.3s, margin 0.3s 0.3s;
                        }
                    }
                    .text {
                        opacity: 1;
                    }
                }
                &.start {
                    .image .img {
                        transition: none;
                    }
                }
                &.float {
                    .image {
                        img {
                            position: fixed;
                            left: calc(50% + 45px);
                            z-index: 1;
                            max-width: 600px;
                            pointer-events: none;
                        }
                    }
                }
                &:not(.active) {
                    .image {
                        img {
                            opacity: 0;
                        }
                    }
                }
                &.above {
                    .image {
                        img {
                            margin-top: 20px;
                        }
                    }
                }
                &.below {
                    .image {
                        img {
                            margin-top: -20px;
                        }
                    }
                }
            }
        }
        .text-and-image-content {
            max-width: 1230px;
            min-height: 980px;
            margin: auto;
            padding: 250px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.height-partially {
                min-height: 880px;
            }
            &.height-reduced {
                min-height: 600px;
            }
            &.height-reducedPlus {
                min-height: 400px;
                padding: 50px 0;
                @media (max-width: 768px) {
                    padding: 20px 0;
                }
            }
            .text {
                width: 480px;
                font-size: 18px;
                p {
                    line-height: 32px;
                    @media (max-width: 768px) {
                        line-height: 28px;
                        &.lead {
                            line-height: 29px;
                        }
                    }
                }
                h2 {
                    margin-bottom: 20px;
                    @media (max-width: 768px) {
                        margin-bottom: 9px;
                    }
                }
            }
            .image {
                width: 100%;
                max-width: 600px;
                position: relative;
                z-index: 1;
                .mobile-image {
                    display: none;
                }
                @media (max-width: 768px) {
                    .desktop-image {
                        display: none;
                    }
                    .mobile-image {
                        display: block;
                    }
                }
                > img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.image-with-arrow {
                    @media (max-width: 768px) {
                        padding-bottom: 65px;
                    }
                }
            }
            .basic-link {
                position: relative;
                z-index: 1;
                margin-top: 48px;
                @media (max-width: 768px) {
                    margin-top: 30px;
                }
                font-weight: 600;
            }
            @media (max-width: 1230px) {
                flex-direction: column;
                justify-content: space-evenly;
                padding: 175px 0px;
                .text {
                    padding: 20px;
                    width: 45%;
                    font-size: 16px;
                }
                .image {
                    width: 45%;
                    > img {
                        max-width: 85%;
                    }
                }
            }
            @media (max-width: 1024px) {
                padding: 75px 0px;
                .image {
                    > img {
                        max-width: 70%;
                    }
                }
            }
            @media (max-width: 768px) {
                flex-direction: column !important;
                justify-content: center;
                min-height: 0;
                padding: 30px 0px;
                .image {
                    order: 1;
                    width: 50%;
                    > img {
                        max-width: 100%;
                        display: inline-block;
                        position: initial;
                        transform: none;
                    }
                }
                .text {
                    width: 70%;
                    order: 2;
                    [type='pagesV2'][slug='contact'] &,
                    [type='pagesV2'][slug='about'] & {
                        padding: 26px 0px;
                    }
                }
            }
            @media (max-width: 500px) {
                .image {
                    padding: 0px 20px;
                    width: 100%;
                }
                .text {
                    width: 100%;
                }
            }
        }
        &.image-size-fullWidth {
            .text-and-image-content {
                padding: 50px 0px; // less padding because the images are not absolute
                .text {
                    width: 46.5%;
                    padding-right: 45px;
                    font-size: 16px; // nothing really matters
                }
                .image {
                    max-width: 46.5%;
                    > img {
                        position: initial;
                        transform: none;
                        width: 100%;
                    }
                }
                @media (max-width: 1230px) {
                    .text,
                    .image {
                        max-width: none;
                        width: calc(50% - 40px);
                        margin-left: 40px;
                        margin-right: 40px;
                        padding-left: 0px;
                        padding-right: 0px;
                        img {
                            max-width: 100%;
                        }
                    }
                }
                @media (max-width: 768px) {
                    padding: 20px 0px;
                    .text,
                    .image {
                        margin-left: 20px;
                        margin-right: 20px;
                        width: calc(75% - 40px);
                    }
                }
                @media (max-width: 500px) {
                    .text,
                    .image {
                        width: calc(100% - 40px);
                    }
                }
            }
        }
        &.image-size-larger {
            // small extra amount of padding in case images are very tall
            padding: 60px 0px;
            @media (max-width: 768px) {
                padding: 22px 0px;
                .text-and-image-content {
                    .image {
                        > img {
                            // center oversized images on mobile
                            margin-left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
            .text-and-image-content {
                .image {
                    > img {
                        max-width: 125%;
                        @media (max-width: 768px) {
                            max-width: 100%;
                        }
                    }
                }
            }
            &.image-left {
                .text-and-image-content {
                    flex-direction: row-reverse;
                    .image {
                        > img {
                            right: 60px;
                        }
                    }
                }
            }
            &.image-right {
                .text-and-image-content {
                    .image {
                        > img {
                            left: 60px;
                        }
                    }
                }
            }
        }
        &.text-width-wide {
            .text-and-image-content {
                .text {
                    width: 518px;
                    @media (max-width: 768px) {
                        width: initial;
                    }
                }
            }
        }
        &.has-arrow {
            @media (min-width: 769px) {
                padding-top: 95px;
                padding-bottom: 0px;
            }
            &.image-size-larger {
                &.image-left {
                    .text-and-image-content {
                        .image {
                            img.mobile-image {
                                transform: none;
                                margin-left: -25%;
                            }
                        }
                    }
                }
                &.image-right {
                    .text-and-image-content {
                        .image {
                            img.mobile-image {
                                transform: none;
                                margin-left: -20px;
                            }
                        }
                    }
                }
            }
        }
        .sub-text {
            display: none;
        }
        &.is-careers {
            .text-and-image-content {
                max-width: 1380px;
                @media (max-width: 1420px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @media (max-width: 992px) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
                .text {
                    width: auto;
                    max-width: 520px;
                    @media (max-width: 1024px) {
                        padding-right: 40px;
                    }
                    @media (max-width: 992px) {
                        max-width: 380px;
                    }
                    @media (max-width: 768px) {
                        max-width: 100%;
                        width: 100%;
                        padding-left: 40px;
                    }
                    h1,
                    h2,
                    h3,
                    h4 {
                        color: #3e89f6;
                        @media (max-width: 992px) {
                            font-size: 26px;
                            line-height: 35px;
                        }
                    }
                    .sub-text {
                        display: block;
                        margin-top: 44px;
                        @media (max-width: 768px) {
                            margin-top: 30px;
                        }
                        p {
                            font-family: 'Inter';
                            font-style: normal;
                            font-size: 16px;
                            line-height: 28px;
                            color: #494b50;
                            strong {
                                font-weight: 700;
                            }
                        }
                    }
                }
                .image {
                    max-width: 800px;
                    @media (max-width: 1230px) {
                        width: 100% !important;
                        img {
                            left: auto;
                            max-width: 100%;
                        }
                    }
                    @media (max-width: 768px) {
                        margin-right: 0px;
                        padding-left: 20px;
                        padding-right: 6px; // leave room for squiggle
                    }
                }
            }
        }
    }
}
