.footer {
    .container {
        padding-left: 40px;
        padding-right: 40px;
        @media (max-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .footer-link {
        position: relative;
        display: inline-block;
        color: #494b50;
        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: currentColor;
            opacity: 0;
            transform: translateY(-3px);
            transition: opacity .3s, transform .3s;
        }
        &:hover {
            &:after {
                transform: translateY(-5px);
                opacity: 1;
            }
        }
        &.footer-bottom-link {
            &:after {
                transform: translateY(7px);
            }
            &.active-lang {
                pointer-events: none;
            }
            &:hover {
                &:after {
                    transform: translateY(5px);
                }
            }
        }
    }
    .footer-socials {
        .footer-social-link {
            svg {
                g[fill] {
                    transition: fill .3s;
                    fill: #3e8af6;
                }
            }
            &:hover {
                svg {
                    g[fill] {
                        fill: #4C80DC;
                    }
                }
            }
        }
        .footer-social-link {
            transition: opacity .3s;
            svg {
                height: 19px;
                width: auto;
            }
        }
    }
    .footer-acknowledgement {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        color: #858E99;
    }
    .legal{
      a {
        @media (max-width: 1140px) {
          margin-bottom: 1rem;
        }
      }
    }
}
