.matrix-cards {
    .module-card {
        padding: 167px 0 79px;
    }
    .module-bg-light {
        @apply bg-gray-100;
    }
    .module-bg-dark {
        @apply bg-grey-900;
        h2, p {
            @apply text-white;
        }
    }
    .cards-title {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 70px;
    }
    .card-item {
        padding: 72px 64px;
        max-width: 390px;
        margin-bottom: 40px;
        h2 {
            font-size: 24px;
            margin-top: 40px;
            margin-bottom: 30px;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
        .card-image {
            height: 100px;
        }
    }
}