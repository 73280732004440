.tippy-tooltip {
    &.light-theme {
        @apply text-black bg-white p-0 shadow-none rounded-md;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.02);
        & [data-animatefill] {
            background-color: initial;
        }
        & [x-placement^="top"] .tippy-arrow {
            border-top: 8px solid #fff;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
        & [x-placement^="bottom"] .tippy-arrow {
            border-bottom: 8px solid #fff;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }

        & [x-placement^="left"] .tippy-arrow {
            border-left: 8px solid #fff;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
        & [x-placement^="right"] .tippy-arrow {
            border-right: 8px solid #fff;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
        .tippy-backdrop {
            background-color: #fff;
        }
        .tippy-roundarrow {
            fill: #fff;
        }
    }
}