.matrix-grid {
    .module-grid {
        padding: 115px 0;
    }
    .module-bg-light {
        @apply bg-gray-100;
    }
    .module-bg-dark {
        @apply bg-grey-900;
        h2, p {
            @apply text-white;
        }
    }
    .grid-item {
        padding-top: 65px;
        padding-bottom: 65px;
        h2 {
            font-size: 30px;
            line-height: 42px;
            margin-top: 24px;
            margin-bottom: 26px;
        }
        p {
            font-size: 18px;
            line-height: 34px;
        }
    }
}