

















































































.member-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 90px;
    grid-gap: 15px;
    width: 100%;
    @screen sm {
        grid-gap: 30px;
    }
    .member {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        transition: opacity 0.4s;
        opacity: 1;
        @screen sm {
            width: 180px;
            height: 180px;
        }
        span {
            bottom: 20px;
        }
        &.is-empty {
            display: none;
        }
        &.is-loading {
            opacity: 0;
        }
    }

    $offset: 0;
    @for $i from 1 through 100 {
        .member:nth-child(#{$i}) {
            grid-column: 6 - (($i - 1) % 6);
            grid-row: #{(($i - 1) % 6) + $offset} / #{(($i - 1) % 6) + $offset + 2};
            @if $i % 6 == 0 {
                $offset: $offset + 2;
            }
        }
    }
    &.five-col {
        grid-template-columns: repeat(5, 1fr);
        $offset: 0;
        @for $i from 1 through 100 {
            .member:nth-child(#{$i}) {
                grid-column: 5 - (($i - 1) % 5);
                grid-row: #{(($i - 1) % 5) + $offset} / #{(($i - 1) % 5) + $offset + 2};
                @if $i % 5 == 0 {
                    $offset: $offset + 2;
                }
            }
        }
    }

    &.four-col {
        grid-template-columns: repeat(4, 1fr);
        $offset: 0;
        @for $i from 1 through 100 {
            .member:nth-child(#{$i}) {
                grid-column: 4 - (($i - 1) % 4);
                grid-row: #{(($i - 1) % 4) + $offset} / #{(($i - 1) % 4) + $offset + 2};
                @if $i % 4 == 0 {
                    $offset: $offset + 2;
                }
            }
        }
    }
    &.three-col {
        grid-template-columns: repeat(3, 1fr);
        $offset: 0;
        @for $i from 1 through 100 {
            .member:nth-child(#{$i}) {
                grid-column: 3 - (($i - 1) % 3);
                grid-row: #{(($i - 1) % 3) + $offset} / #{(($i - 1) % 3) + $offset + 2};
                @if $i % 3 == 0 {
                    $offset: $offset + 2;
                }
            }
        }
    }
    &.two-col {
        grid-template-columns: repeat(2, 1fr);
        $offset: 0;
        @for $i from 1 through 100 {
            .member:nth-child(#{$i}) {
                grid-column: 2 - (($i - 1) % 2);
                grid-row: #{(($i - 1) % 2) + $offset} / #{(($i - 1) % 2) + $offset + 2};
                @if $i % 2 == 0 {
                    $offset: $offset + 2;
                }
            }
        }
    }
}
