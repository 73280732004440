.matrix-magicList {
    .magic-list {
        position: relative;
        margin: auto;
        display: flex;
        @media (max-width: 768px) {
            padding-bottom: 36px;
        }
        .video-sub {
            .video-link {
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin: 20px 0;
                &:hover {
                    .video-icon {
                        transform: rotate(360deg);
                    }
                }
                .video-icon {
                    transition: transform .3s;
                    svg {
                        width: 20px;
                    }
                    margin-right: 10px;
                }
                .magic-video-text {
                    color: rgb(43, 58, 90);
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0px;
                }
            }
        }
        &.type-horizontal {
            width: 1230px;
            @media (max-width: 1230px) {
                width: calc(100vw - 80px);
            }
            @media (max-width: 768px) {
                width: calc(100vw - 40px);
            }
            padding-top: 765px;
            align-items: flex-start;
            .magic-item {
                width: 100%;
                cursor: pointer;
                .line-element {
                    transform: translateY(0px);
                    transition: transform .4s;
                    position: relative;
                    height: 2px;
                    background-color: #E7E8EB;
                    &:before {
                        content: '';
                        position: absolute;
                        background-color: #5c6cff;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        // width: 50%;
                        width: 0%;
                        transition: width 0.1s 0.4s;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        background-color: #E7E8EB;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        transition: width 0.4s linear;
                    }
                }
                transition: opacity 0.3s;
                &:not(:last-child) {
                    margin-right: 30px;
                }
                .magic-text {
                    padding-top: 40px;
                    padding-right: 40px;
                    transition: transform .4s;
                    transform: translateY(0px);
                }
                &.active {
                    .line-element {
                        transform: translateY(-20px);
                        &:before {
                            transition: width 5s linear;
                            width: 100%;
                        }
                        &:after {
                            transition: width 0.1s;
                            width: 0%;
                        }
                    }
                    .magic-text {
                        transform: translateY(-20px);
                    }
                    .magic-image-container {
                        opacity: 1;
                    }
                }
                &:hover {
                    .line-element {
                        transform: translateY(-20px);
                    }
                    .magic-text {
                        transform: translateY(-20px);
                    }
                }
                .magic-image-container {
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 665px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: opacity 0.3s;
                    .magic-image {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .magic-icon {
                    margin-bottom: 20px;
                    svg {
                        width: 38px;
                    }
                }
                padding-bottom: 0px;
                h5 {
                    margin-bottom: 12px;
                }
                @media (max-width: 992px) {
                    h5 {
                        margin-bottom: 7px;
                    }
                }
                font-family: Helvetica;
                font-size: 16px;
                color: #494B50;
                letter-spacing: 0;
                line-height: 28px;
            }
            @media (max-width: 768px) {
                flex-direction: column;
                padding-top: 60vw;
                .magic-item {
                    padding-bottom: 0px;
                    .magic-image-container {
                        height: 59vw;
                        .magic-image {
                            position: absolute;
                            // left: -10px;
                            max-width: 150%;
                        }
                    }
                    &.active {
                        .magic-image-container {
                            opacity: 1 !important;
                        }
                    }
                    &:not(.active) {
                        .magic-image-container {
                            opacity: 0 !important;
                        }
                    }
                    // .line-element {
                    //     display: none !important;
                    // }
                    .magic-text {
                        padding-right: 10px;
                        transform: none !important;
                    }
                }
            }
        }
        &.type-scroll {
            padding: 300px 0;
            width: 990px;
            flex-direction: column;
            justify-content: center;
            @media (max-width: 1230px) {
                width: 100%;
                padding: 0;
                .magic-item {
                    padding: 0 !important;
                    opacity: 1 !important;
                    .magic-image {
                        display: none !important;
                    }
                    .mobile-magic-image {
                        height: 100vh;
                        background-attachment: fixed;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        body.tippy-iOS & {
                            background-attachment: initial;
                        }
                    }
                    .magic-text {
                        padding: 40px;
                        @media (max-width: 768px) {
                            padding: 20px;
                        }
                        body:not(.tippy-iOS) & {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            height: 100vh;
                        }
                    }
                }
            }
            .magic-item {
                padding-left: 600px;
                position: relative;
                transition: opacity 0.3s;
                .magic-image {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    margin-top: -50%;
                    transition: opacity 0.4s;
                }
                .magic-icon {
                    margin-bottom: 20px;
                    svg {
                        width: 32px;
                    }
                }
                padding-bottom: 110px;
                h4 {
                    color: #494b50;
                }
                h5 {
                    margin-bottom: 7px;
                }
                font-family: Helvetica;
                font-size: 16px;
                color: #494B50;
                letter-spacing: 0;
                line-height: 28px;
                &:not(.active) {
                    opacity: 0.4;
                    .magic-image {
                        opacity: 0;
                    }
                }
                &.float {
                    .magic-image {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        margin-top: -490px;
                        margin-left: -495px;
                    }
                }
            }
        }
        &.show-lines-mobile {
            // use digi blue instead of funnel purp for the lines
            .magic-item {
                .line-element {
                    &:before {
                        background-color: #3E8AF6;
                    }
                }
            }
            @media (max-width: 768px) {
                .magic-item {
                    margin-top: 24px;
                    transition: all 0.3s;
                    padding-right: 12px;
                    &.active {
                        padding-left: 12px;
                        padding-right: 0px;
                    }
                    .magic-icon {
                        display: none;
                    }
                    .magic-text {
                        padding-top: 12px !important;
                        padding-bottom: 20px !important;
                    }
                    .line-element {
                        transform: translateY(0px) !important;
                    }
                }
            }
        }
        &:not(.show-lines-mobile) {
            @media (max-width: 768px) {
                .magic-item {
                    .line-element {
                        display: none !important;
                    }
                }
            }
        }
    }
}