.campaign-page {
  .matrix-customerStory {
    background-color: #f6f8fb;
    margin-top: 98px;

    @screen md {
      margin-top: 109px;
    }
    @screen xl {
      margin-top: 185px;
    }

    .wrapper {
      padding: 0 20px 55px;
      @screen md {
        padding: 0 20px 100px;
      }
      @screen md {
        padding: 0 40px 100px;
      }
      @screen xl {
        padding: 0 40px 150px;
      }
    }

    span.quotes {
      width: 116px;
      height: 45px;
      margin: 0;
      top: 0;
      left: 50%;
      transform: translate(-50%, -63%);

      @screen md {
        width: 162px;
        height: 63px;
      }
      svg {
        > g {
          path {
            fill: var(--theme-primary);
            stroke: var(--theme-primary);
          }
          > g {
            fill: var(--theme-dark);
            path {
              stroke: var(--theme-dark);
              fill: var(--theme-dark);
            }
          }
        }
      }
    }

    .intro {
      padding: 55px 20px 0;
      @screen md {
        padding: 66px 0 0;
      }

      small {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: var(--theme-primary);
      }

      h2 {
        margin-top: 16px;
        margin-bottom: 28px;
        @screen md {
          margin: 20px 0;
        }
        @screen lg {
          margin: 24px 0;
        }
      }
      .squiggle {
        display: none;
        right: 0;
        bottom: 0;
        svg {
          transform: scale(0.9);
          path {
            stroke: var(--theme-primary);
          }
        }
        @screen md {
          display: block;
          right: 50px;
        }
        @screen lg {
          bottom: -150px;
          right: 100px;
        }
      }
    }

    .arrow-link {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      position: relative;
      color: #2b3a5a;

      span.arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(150%, -50%);
        transition: transform 0.4s;
        svg path {
          color: #2b3a5a;
        }
      }

      &:hover {
        span.arrow {
          transform: translate(180%, -50%);
        }
      }
    }

    .inner-wrapper {
      display: grid;
      margin-top: 50px;

      @screen md {
        margin-top: 38px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(12, 1fr);

        .content {
          grid-template-rows: repeat(10, minmax(1, 1fr));
          align-items: flex-end;

          .inner {
            padding: 31px 0 76px;
            max-width: 420px;
          }
        }
      }
      @screen lg {
        margin-top: 68px;
      }
      @screen xl {
        margin-top: 103px;
        .content {
          .inner {
            padding: 70px 0 83px;
          }
        }
      }

      .content-wrapper {
        background-color: #fff;
        margin-top: -40px;
        padding-top: 60px;

        @screen md {
          background-color: unset;
          margin-top: 0;
          padding-top: 0;
          grid-row: 3 / 12;
          grid-column: 1 / 13;
        }

        .content {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 44px;
          @screen md {
            background-color: #fff;
            padding-bottom: 0;
            width: 100%;
          }
        }
      }

      .image-wrapper {
        width: 90%;
        margin: auto;

        @screen md {
          width: 110%;
          grid-row: 1 / 9;
          grid-column: 1 / 6;
        }
        @screen xl {
          width: 100%;
          grid-row: 1 / 11;
          grid-column: 1 / 7;
        }
      }
      .logo {
        padding: 0;
        margin: 0;
        img {
          width: 100px;
          height: auto;
          margin-bottom: 25px;
          margin-right: 0;
          @screen md {
            margin-bott: 43px;
            max-width: 126.36px;
          }
          @screen lg {
          }
          @screen xl {
            margin-bottom: 43px;
          }
        }
      }

      .text {
        h3 {
          font-size: 24px;
          line-height: 34px;
          @screen md {
            font-size: 20px;
            line-height: 25px;
          }
          @screen lg {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 16px;
          }
        }
        p {
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 30px;
          @screen md {
            line-height: 23px;
          }
          @screen lg {
            margin-bottom: 32px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
