.matrix-cards {
    position: relative;
    padding-bottom: 160px;
    @media (max-width: 1230px) {
        padding-bottom: 80px;
    }
    @media (max-width: 768px) {
        margin-top: 80px;
        padding-bottom: 50px;
    }
    .straight-background {
        content: '';
        position: absolute;
        top: 56px;
        left: -9999px;
        right: -9999px;
        bottom: 0;
        background-color: rgb(246, 248, 251);
    }
    .the-slant-tm {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        svg {
            width: 100%;
            height: auto;
        }
        &:after {
            content: '';
            position: static;
            width: 100%;
            background: #f6f8fb;
            flex-grow: 1;
        }
    }
}
.cards {
    position: relative;
    font-size: 0;
    width: 1260px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    &.columns-2 {
        .card {
            width: calc(50% - 30px);
        }
    }
    &.columns-3 {
        .card {
            width: calc(33.33% - 30px);
            padding: 40px;
        }
    }
    @media (max-width: 1230px) {
        width: 100%;
        @media (min-width: 768px) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    &.height-tall {
        .card {
            .card-text {
                padding-bottom: 80px;
                @media (max-width: 1230px) {
                    padding-bottom: 50px;
                }
            }
            .card-link {
                .link-type-icon {
                    margin-left: 8px;
                }
            }
        }
    }
    .card {
        display: flex;
        flex-direction: column;
        background-color: white;
        margin: 15px;
        font-size: initial;
        padding: 40px 4%;
        border-radius: 8px;
        box-shadow: 0px 12px 36px 0px rgba(10, 63, 138, 0.14);
        transition: transform 0.3s, box-shadow 0.3s;
        z-index: 20;

        @media (max-width: 768px) {
            width: calc(100% - 30px) !important;
            padding: 40px !important;
            margin: 10px 20px !important;
        }
        .logo {
            padding: 0 0 9px;
            @media (max-width: 768px) {
                margin-bottom: 16px;
            }
            img {
                transform-origin: 0 0;
            }
        }
        p {
            font-family: Inter;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0px;
            line-height: 28px;
        }
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 22px 50px 0px  rgba(10, 63, 138, 0.3);
            @include triggerLinkAnimation;
        }

        .card-text {
            display: block;
        }
        &.has-text-colour {
            .card-text {
                * {
                    color: currentColor;
                }
            }
        }

        // height: 485px;
        // width: 390px;

        .card-link {
            margin-top: auto;
            font-weight: 600;
            font-size: 16px;
            padding-top: 22px;
            color: #2B3A5A;
            .link-type-icon {
                margin-left: 8px;
            }
            svg {
                display: inline-block;
                [fill]:not([fill="none"]) {
                    fill: currentColor;
                }
                [stroke]:not([stroke="none"]) {
                    stroke: currentColor;
                }
            }
        }
    }
}
.matrix-iconsHeader + .matrix-cards {
    @media (max-width: 768px) {
        margin-top: 0;
    }
}
.matrix-imageHeader + .matrix-cards {
    @media (max-width: 768px) {
        margin-top: 0;
    }
}