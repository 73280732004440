.campaign-page {
  .matrix-form {
    background-color: #f6f8fb;
    .wrapper {
      @screen lg {
        margin: auto;
        padding: 115px 40px;
      }
    }
    .content {
      @screen lg {
        max-width: 502px;
      }
      h2 {
        @screen lg {
          font-size: 34px;
          line-height: 40px;
        }
      }
      p {
        @screen lg {
          max-width: 496px;
          font-size: 18px;
          line-height: 32px;
        }
      }
      .campaign-progressive-form {
        @screen lg {
          max-width: 495px;
        }
      }
      .hbspt-form {
        .hs-form-field {
          margin-bottom: 26px;
          label {
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: calc(16.94 / 14);
            letter-spacing: 0.2px;
            margin-bottom: 12px;
          }
          &.hs-fieldtype-select {
            .input {
              position: relative;
              .hs-input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
              }
              &:after {
                right: 20px;
                border: 2px solid #494b50;
                border-right: 0;
                border-top: 0;
                border-radius: 2px;
                content: ' ';
                display: block;
                height: 0.625em;
                margin-top: -0.375em;
                pointer-events: none;
                position: absolute;
                top: 50%;
                transform: rotate(-45deg);
                transform-origin: center;
                width: 0.625em;
              }
            }
          }
          .hs-input {
            border: 1px solid #d2d6dc;
            border-radius: 4px;
            padding: 11px 16px;
            width: 100%;
            gap: 10px;
            font-size: 16px;
            color: #3e3e3e;
            font-weight: 400;
            line-height: 1.5;
            background-color: #fff;
            background-clip: padding-box;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &[type='checkbox'],
            &[type='radio'] {
              width: auto;
              margin-right: 10px;
            }
            &:focus {
              border-color: #3e8af6;
              outline: 0;
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
            &.is-placeholder {
              color: #b6b7b9;
            }
            &.error {
              border: 1px solid rgb(244, 86, 86);
            }
          }
          .hs-form-checkbox-display,
          .hs-form-booleancheckbox-display,
          .hs-form-radio-display {
            padding-top: 4px;
            display: flex;
            align-items: center;
          }
          .hs-error-msgs {
            display: inline-block;
            li {
              .hs-error-msg {
                color: rgb(244, 86, 86);
              }
            }
          }
        }
        .hs-form-required {
          display: none;
          // display: inline-block;
          // color: rgb(244, 86, 86);
        }
        .hs-button {
          background-color: #3e8af6;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          padding: 10px 20px;
          border-radius: 4px;
          line-height: calc(30 / 16);
          padding: 10px 33.5px;
        }
        .hs_error_rollup {
          margin-bottom: 30px;
          .hs-error-msgs {
            > li {
              label {
                position: relative;
                background: rgba(244, 86, 86, 0.08);
                border-radius: 0px;
                padding: 11px 20px;
                display: block;
                padding-left: 44px;
                color: #f45656;
                font-size: 14px;
                font-weight: 400; // regularmedium regular 400 medium 500
                letter-spacing: 0px;
                &:before {
                  content: url('/assets/alert-circle.svg');
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 15px;
                  height: 23px;
                }
              }
            }
          }
        }
        .input + .hs-error-msgs {
          margin-top: 6px;
        }
        .hs-error-msg {
          color: rgb(244, 86, 86);
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .image-content {
      margin-top: 7rem;
      margin-bottom: 3.5rem;
      @screen md {
        margin-top: 0;
      }
      .image-wrapper {
        position: relative;
        margin: auto;
        width: 100%;
        @screen lg {
          width: 100%;
          max-width: 600px;
        }

        img {
          position: relative;
          z-index: 1;
          border-radius: 8px;
        }

        span.art {
          position: absolute;
          z-index: 0;
          top: -10%;
          right: -15%;
          bottom: 0;
          height: 100%;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &.theme {
    &-university {
      .matrix-form {
        .image-content {
          @screen md {
            grid-column-start: 7;
          }
          .image-wrapper {
            @screen md {
              padding-left: 2rem;
            }
            @screen xl {
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
}
