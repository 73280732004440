.web-catalogue-nav {
  background-color: #F6F8FB;
  > .container{
    display: grid;
    place-items: center;
    gap: 20px;
    padding: 24px 48px;
    @screen md {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    nav {
      display: flex;
      gap: 47px;

      .nav-item {
        font-size: 14px;
        font-weight: 500;
        color: $strom-gray;
        position: relative;

        &.is-active{
          &:before {
            content: '';
            position: absolute;
            width: 25%;
            height: 2px;
            background-color: $strom-gray;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
