footer.web-catalogue-footer{
  background-color: #F6F8FB;
  > .container {
    padding: 48px 24px;
    display: grid;
    place-items: center;
    gap: 20px;
    grid-template-columns: 1fr;
    @screen md {
      padding: 48px;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      place-items: unset;
      gap: 0;
    }

    img {
      width: 25px;

      @screen md {
        grid-column: span 1;
      }
    }

    nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 45px;

      @screen md{
        grid-column: span 3;
      }

      .footer-nav-item {
        color: $strom-gray;
        font-size: 14px;
        font-weight: 500;
        position: relative;

        &.is-active{
          &:before {
            content: '';
            position: absolute;
            width: 25%;
            height: 2px;
            background-color: $strom-gray;
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .back-to-top {
      position: relative;
      width: 100%;
      order: -1;

      @screen md {
        order: unset;
        grid-column: span 1;
      }

      .back-to-top-btn {
        background-color: transparent;
        border: none;
        display: grid;
        place-items: center;
        position: absolute;
        top: -40px;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;

        .svg-wrapper {
          background-color: $brand-midnight-blue;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          animation: bounce-up 2s ease-in-out infinite;
          position: relative;

          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        small {
          margin-top: 5px;
          font-family: $font-heading;
          font-size: 14px;
          font-weight: 600;
          color: $brand-midnight-blue;
        }
      }
    }
  }
}
