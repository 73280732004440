.campaign-page {
  &.theme-funnel,
  &.theme-websites {
    .matrix-highlights {
      background-color: var(--theme-dark);
      .wrapper {
        .content {
          .image-wrapper {
            img {
              @screen xl {
                position: absolute;
                top: 0;
                left: 0;
                // width: 944px;
                max-width: none;
                height: 102%;
                object-fit: contain;
                object-position: left;
              }
            }
            .image-graphic {
              display: none;
            }
          }
        }
      }
    }
  }
  &.theme-master {
    .matrix-highlights .wrapper .image-wrapper .image-graphic {
      @screen xl {
        top: 5px;
        right: -20px;
      }
    }
  }
  .matrix-highlights {
    background-color: var(--theme-dark);
    .wrapper {
      padding: 70px 20px;
      @screen md {
        padding: 80px 20px;
      }
      @screen lg {
        padding: 80px 40px;
      }
      @screen xl {
        padding: 140px 40px;
      }
      .intro {
        .sub-heading {
          @apply font-sans;
          color: var(--theme-light);
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        h2 {
          @apply text-white;
          font-weight: 700;
          font-size: 28px;
          line-height: 40px;
          margin: 16px 0;
          @screen md {
            font-size: 40px;
            line-height: 54px;
            margin: 20px 0;
          }
          @screen xl {
            margin: 24px 0 19px;
          }
        }
        .lead p {
          @apply text-white font-barlow;
          font-weight: 400;
          font-size: 18px;
          line-height: 29px;
          @screen md {
            font-size: 20px;
            line-height: 33px;
          }
        }
      }
      .content {
        @screen xl {
          margin-top: 95px;
        }
      }
      .image-wrapper {
        @screen md {
          max-width: calc(6 / 12 * 100%);
        }
        @screen xl {
          // margin-top: -45px;
        }
        .image-graphic {
          position: absolute;
          width: 25px;
          height: 37px;
          display: none;
          @screen md {
            top: -3%;
            right: 11%;
            display: block;
          }
          @screen xl {
            top: -10px;
            right: 60px;
          }
        }
      }
      .features {
        @screen md {
          max-width: calc(5 / 12 * 100%);
          @apply ml-0 mr-auto;
        }

        .feature {
          &:not(:first-child) {
            margin-top: 25px;
          }

          @screen md {
            max-width: 383px;
          }
          @screen lg {
            max-width: 496px;
          }

          svg {
            width: 35px;
            height: 35px;
            color: var(--theme-light);
            margin-bottom: 20px;
          }

          .feature-text {
            h3 {
              @apply font-barlow text-white;
              font-weight: 600;
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 8px;
              @screen md {
                font-size: 21px;
                line-height: 30px;
                margin-bottom: 12px;
              }
            }

            p {
              @apply font-sans text-white;
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}
