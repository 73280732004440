.matrix-feature {
    padding: 100px 0;
    .md\:flex-row-reverse {
        .feature-details {
            @apply justify-end;
        }
    }
    .feature-details {
        @apply flex;
        .feature-text {
            max-width: 495px;
            h2 {
                @apply font-barlow font-semibold text-4xl text-blue-800;
                letter-spacing: 0;
                line-height: 42px;
                margin-bottom: 37px;
            }
            p {
                @apply text-lg text-grey-600;
                letter-spacing: 0;
                line-height: 36px;
            }
        }
    }
}