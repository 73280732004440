.campaign-page {
  --logo-margin-bottom-sm: 38px;
  --logo-margin-bottom-md: 53px;
  --logo-margin-bottom-xl: 0;
  .campaign-logos {
    .wrapper {
      padding: 70px 20px calc(70px - var(--logo-margin-bottom-sm));
      @screen md {
        padding: 80px 20px calc(80px - var(--logo-margin-bottom-sm));
      }

      @screen xl {
        padding: 150px 40px;
      }
    }
    .spacer {
      display: block;
      @screen md {
        display: none;
      }
    }

    .logos-subheading {
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: center;
      color: rgb(43, 58, 90);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2.33px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 70px;
      @screen md {
        margin-bottom: 0;
      }
    }
    .logos-logos {
      @screen md {
        max-width: 660px;
        margin: 58px auto 0;
        justify-content: center;

        align-items: center;
      }
      @screen xl {
        justify-content: space-between;
        column-gap: 60px;
        max-width: 100%;
        margin: 64px auto 0;
      }
      .logos-graphic {
        position: absolute;
        width: 22.97px;
        height: 34.29px;
        right: -30px;
        top: 0;
        svg {
          path {
            stroke: var(--theme-dark);
          }
        }
      }
      a,
      .spacer {
        flex: 1 1 50%;
        max-height: 60px;
        margin-bottom: var(--logo-margin-bottom-sm);
        @screen md {
          flex: 1 0 33%;
          max-height: 70px;
          margin-bottom: var(--logo-margin-bottom-md);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          &:first-of-type {
            justify-content: left;
          }
          &:nth-of-type(3) {
            justify-content: right;
          }
        }
        @screen xl {
          flex: 1 1 auto;
          max-height: 70px;
          margin-bottom: var(--logo-margin-bottom-xl);
          &:first-of-type {
            justify-content: left;
          }
          &:last-of-type {
            justify-content: right;
          }
        }

        img {
          display: inline-block;
          object-fit: contain;
          width: auto;
          min-height: 20px;
          max-height: 60px;
          vertical-align: middle;
          @screen md {
            max-height: 58px;
          }
          @screen xl {
            max-height: 70px;
            text-align: center;
          }
        }
      }
    }
  }
}
