@keyframes scrollImages {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}
@keyframes scrollImagesMobile {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-140%);
    }
}
.scrolling-gallery {
    margin-bottom: 187px;
    position: relative;
    @media (max-width: 992px) {
        margin-bottom: 117px;
    }
    .scroll-button {
        display: none;
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 99;
        @media (max-width: 768px) {
            display: initial;
        }
        svg {
            width: 40px;
            height: 40px;
        }
    }
    .scrolling-gallery-row {
        margin-bottom: 20px;
        max-width: 100vw;
        overflow: hidden;
        @media (max-width: 768px) {
            margin-bottom: 15px;
        }
        &.row-scroll-right {
            .row-inner {
                animation-direction: reverse;
            }
        }
        .row-inner {
            display: flex;
            // try and keep the scrolling ~roughly~ the same speed on different screen sizes (bit faster on mobile)
            animation: scrollImages 90s infinite linear;
            @media (max-width: 1250px) {
                animation: scrollImages 60s infinite linear;
            }
            @media (max-width: 992px) {
                animation: scrollImagesMobile 45s infinite linear;
            }
            @media (max-width: 768px) {
                // animation: scrollImagesMobile 30s infinite linear;
                animation: none;
                overflow-y: hidden;
                overflow-x: scroll;
                padding-left: 15px;
            }
            // on desktop pause on hover
            @media (min-width: 1025px) {
                &:hover {
                    animation-play-state: paused;
                }
            }
            .row-item {
                margin-right: 20px;
                flex-shrink: 0;
                position: relative;
                @media (max-width: 768px) {
                    margin-right: 15px;
                }
                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(0,0,0,0);
                    transition: all 0.3s;
                }
                &:hover {
                    &:after {
                        background: rgba(0,0,0,0.2);
                    }
                }
                cursor: pointer;
                img {
                    height: 320px;
                    width: auto;
                    pointer-events: none;
                    @media (max-width: 768px) {
                        height: 220px;
                    }
                }
                &.is-duplicate {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
                &.row-item-video {
                    position: relative;
                    .wistia-popover-trigger {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 20;
                        .wistia_click_to_play {
                            width: 100% !important;
                            z-index: 25;
                        }
                    }
                    .video-elements {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: 35px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        background: linear-gradient(193.21deg, rgba(0, 0, 0, 0.0001) 19.63%, rgba(#000000, 0.5) 79.56%);
                        mix-blend-mode: normal;
                        @media (max-width: 768px) {
                            padding: 20px;
                        }
                        .video-subheading {
                            color: #FFF;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 15px;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            margin-bottom: 18px;
                            svg {
                                margin-right: 12px;
                                @media (max-width: 768px) {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                            @media (max-width: 768px) {
                                font-size: 12px;
                                line-height: 15px;
                                letter-spacing: 2px;
                                margin-bottom: 10px;
                            }
                        }
                        .video-title {
                            color: #FFF;
                            font-family: 'Barlow';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 21px;
                            line-height: 30px;
                            max-width: 324px;
                            @media (max-width: 768px) {
                                font-size: 18px;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.scrolling-gallery-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    // inactive
    pointer-events: none;
    opacity: 0;
    // active
    &.active {
        opacity: 1;
        pointer-events: initial;
    }
    .lightbox-backdrop {
        position: absolute;
        background: rgba(0,0,0,0.75);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        .close {
            position: absolute;
            top: 20px;
            right: 20px;
            color: #FFF;
            font-size: 30px;
            cursor: pointer;
            transition: opacity 0.3s;
            &:hover {
                opacity: 0.5;
            }
            svg {
                width: 24px;
            }
        }
    }
    .item {
        z-index: 99999;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        pointer-events: none;
        &.active {
            opacity: 1;
            pointer-events: initial;
        }
        .prev,
        .next {
            font-size: 30px;
            color: #FFF;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 40px;
            cursor: pointer;
            width: 50vw;
            height: 100%;
            display: flex;
            align-items: center;
            transition: opacity 0.3s;
            @media (max-width: 1250px) {
                top: calc(50% + 80px);
                width: 50%;
                align-items: flex-end;
            }
            &:hover {
                opacity: 0.5;
            }
            svg {
                width: 24px;
                fill: #FFF;
                transition: all 0.3s;
                @media (max-width: 1250px) {
                    width: 40px;
                }
                path {
                    fill: #FFF;
                }
            }
        }
        .prev {
            left: -50vw;
            justify-content: flex-end;
            @media (max-width: 1250px) {
                left: 0px;
                justify-content: flex-start;
            }
            svg {
                > g {
                    transform-origin: center;
                    transform: rotate(180deg)
                }
            }
            &:hover {
                svg {
                    transform: translateX(-6px);
                }
            }
        }
        .next {
            right: -50vw;
            justify-content: flex-start;
            @media (max-width: 1250px) {
                right: 0px;
                justify-content: flex-end;
            }
            &:hover {
                svg {
                    transform: translateX(6px);
                }
            }
        }
        img {
            max-height: 70vh;
            max-width: 70vw;
            @media (max-width: 1600px) {
                max-height: 85vh;
                max-width: 85vw;
            }
        }
    }
}