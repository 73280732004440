.error-page {
    padding: 180px 0 290px 0;
    @media (max-width: 992px) {
        padding: 108px 40px 166px 40px;
    }
    h1 {
        color: rgb(43, 58, 90);
        font-size: 160px;
        font-family: Barlow;
        font-weight: 600;
        text-align: center;
        margin-bottom: 32px;
        line-height: 192px;
        @media (max-width: 992px) {
            font-size: 90px;
            line-height: 108px;
            margin-bottom: 12px;
        }
    }
    p {
        color: rgb(73, 75, 80);
        font-size: 22px;
        font-family: Barlow;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0px;
        line-height: 36px;
        margin-bottom: 28px;
        @media (max-width: 992px) {
            font-size: 19px;
            line-height: 31px;
            margin-bottom: 20px;
        }
    }
    .link-wrapper {
        text-align: center;
    }
}