.web-catalogue-filters {
  background-color: #F6F8FB;
  > .container {
    padding: 15px 40px 110px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .filter-btn {
      padding: 8px 10px;
      background-color: #fff;
      border: 1px solid #C0C4C9;
      border-radius: 6px;

      color: #2B3A5A;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;

      &.is-active {
        background-color: #003C57;
        color: #fff;
      }
    }
  }
}
