.intro-blocks {
    .image-1-wrapper {
        height: 525px;
        .image-1 {
            @apply absolute bottom-0 right-0 overflow-hidden rounded-10;
            width: 525px;
            height: 525px;
            img {
                width: 525px;
                height: 525px;
            }
        }
    }

    .image-2-wrapper {
        .image-2 {
            @apply absolute bottom-0 right-0 overflow-hidden rounded-10;
            width: 390px;
            height: 390px;
            img {
                width: 390px;
                height: 390px;
            }
        }
    }

    .image-3-wrapper {
        height: 390px;
        .image-3 {
            @apply absolute top-0 right-0 overflow-hidden rounded-10;
            width: 390px;
            height: 390px;
            img {
                width: 390px;
                height: 390px;
            }
        }
    }


    .image-4-wrapper {
        @apply ml-5;
        height: 600px;
        .image-4 {
            @apply overflow-hidden rounded-10;
            img {
                width: 100%;
            }
        }
    }

    .image-5-wrapper {
        height: 390px;
        @apply ml-5;
        .image-5 {
            @apply overflow-hidden rounded-10;
            img {
                width: 100%;
            }
        }
    }

    h3 {
        font-size: 48px;
        letter-spacing: 0;
        margin-top:20px;
        margin-bottom: 46px;
    }
    a {
        display: block;
        font-size: 15px;
        letter-spacing: 0;
        margin-bottom: 120px;
    }
}
