.matrix-itemsWithIcons {
    .outer-container {
        padding: 40px 0px;
        position: relative;
        .bottom-half-background-element {
            position: absolute;
            bottom: 0;
            top: 50%;
            left: 0;
            right: 0;
        }
        .container-container {
            position: relative;
            width: 1230px;
            margin: 0px auto;
            padding: 140px 0px;
            z-index: 2;
            @media (max-width: 1230px) {
                width: calc(100% - 80px);
            }
            @media (max-width: 768px) {
                width: calc(100% - 40px);
            }
            @media (max-width: 992px) {
                padding: 60px 0px;
            }
            @media (max-width: 768px) {
                padding: 60px 0 20px;
            }
            .background-element {
                position: absolute;
                top: 0;
                left: -220px;
                right: -220px;
                bottom: 0;
                @media (max-width: 992px) {
                    left: 0;
                    right: 0;
                }
            }
            .items-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @media (max-width: 992px) {
                    padding: 0px 40px;
                }
                .item {
                    flex: 0 0 33%;
                    padding-right: 100px;
                    .item-icon {
                        margin-bottom: 35px;
                        max-width: 40px;
                    }
                    .big-text {
                        margin-bottom: 35px;
                        font-size: 36px;
                        font-weight: 600;
                        letter-spacing: 0px;
                    }
                    .small-text {
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        line-height: 28px;
                    }
                    @media (max-width: 992px) {
                        flex: 0 0 50%;
                        padding-right: 50px;
                        justify-content: center;
                        margin-bottom: 50px;
                    }
                    @media (max-width: 768px) {
                        flex: 0 0 100%;
                        padding-right: 0px;
                        margin-bottom: 48px;
                        .big-text {
                            font-size: 26px;
                            margin-bottom: 15px;
                        }
                        .item-icon {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        &.text-icons-small {
            .container-container {
                @media (max-width: 1460px) {
                    width: calc(100% - 80px);
                }
                @media (max-width: 992px) {
                    width: calc(100% - 40px);
                }
                .background-element {
                    left: -80px;
                    right: -80px;
                    @media (max-width: 1460px) {
                        left: 0px;
                        right: 0px;
                    }
                }
            }
            .items-container {
                @media (max-width: 1420px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }
                .item {
                    margin-top: 55px;
                    padding: 0px 40px !important;
                    @media (max-width: 1420px) {
                        padding: 0px 20px !important;
                    }
                    @media (max-width: 768px) {
                        margin-top: 57px;
                        margin-bottom: 0px;
                    }
                    &:first-child {
                        margin-top: 0px;
                    }
                    &:last-child {
                        margin-bottom: 40px;
                    }
                    @media (min-width: 768px) {
                        &:nth-child(2),
                        &:nth-child(3) {
                            margin-top: 0px;
                        }
                    }
                    .item-icon {
                        max-width: 34px;
                        margin-bottom: 24px;
                    }
                    .big-text { 
                        font-size: 21px;
                        line-height: 30px;
                        margin-bottom: 24px;
                        @media (max-width: 768px) {
                            font-size: 18px;
                            line-height: 28px;
                            margin-bottom: 12px;
                        }
                    }
                    .small-text {
                        font-size: 18px;
                        line-height: 32px;
                        @media (max-width: 768px) {
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }
}