.feedback {
    position: relative;
    .quote-wrapper {
        height: 145px;
        position: relative;
        .quote-background {
            background: #F6F8FB;
            height: 70px;
        }
        .quote {
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translateX(-50%);
        }
        @media (max-width: 768px) {
            height: 120px;
            .quote {
                svg, img {
                    width: 120px;
                }
            }
        }
    }
    .subheading {
        display: block;
        text-align: center;
    }
    > .text {
        text-align: center;
        margin: auto;
    }
    .feedback-blocks {
        display: flex;
        width: 1230px;
        @media (max-width: 1230px) {
            width: calc(100% - 80px);
        }
        margin: auto;
        margin-top: 95px;
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 0;
        }
        .feedback-block {
            background: #F6F8FB;
            border-radius: 8px;
            &:first-child {
                margin-right: 30px;
            }
            padding: 59px;
            display: flex;
            flex-direction: column;
            width: 100%;
            .text {
                color: rgb(73, 75, 80);
                font-family: Inter;
                font-size: 18px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 36px;
                margin-bottom: 60px;
            }
            .feedback-footer {
                margin-top: auto;
                display: flex;
                align-items: center;
                .icon {
                    width: 74px;
                    img {
                        max-height: 62px;
                        width: auto;
                        max-width: 100%;
                    }
                }
                .author {
                    .name {
                        color: rgb(51, 51, 51);
                        font-family: Inter;
                        font-weight: bold;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 27px;
                    }
                    .role {
                        color: rgb(73, 75, 80);
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 24px;
                    }
                }
                .icon {
                    flex-shrink: 0;
                }
            }
        }
    }
    .summary {
        margin: 80px auto;
        .rating {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 21px;
            .stars {
                display: flex;
                .star {
                    position: relative;
                    margin-right: 5px;
                    .filled-star {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        overflow: hidden;
                    }
                    .empty-star {
                        opacity: 0.1;
                    }
                    @for $i from 0 through 10 {
                        &.fill-#{$i} {
                            .filled-star {
                                width: 10% * $i;
                            }
                        }
                    }
                }
            }
            .number {
                margin-left: 6px;
                color: rgb(43, 58, 90);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0px;
            }
        }
        .feedback-image {
            text-align: center;
            margin-bottom: 20px;
            img {
                width: 94px;
                display: inline-block;
                margin: 0 auto;
            }
        }
        .description {
            color: rgb(43, 58, 90);
            font-weight: 400;
            letter-spacing: 0px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            p {
                font-size: 14px;
                font-weight: 600;
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    @media (max-width: 768px) {
        > .text {
            width: 265px;
            margin: auto;
            margin-bottom: 30px;
        }
        .feedback-blocks {
            flex-direction: column;
            .feedback-block {
                margin: 20px 20px 0px 20px;
                width: calc(100% - 40px);
                padding: 35px;
                padding-bottom: 52px;
                .text {
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 30px;
                    margin-bottom: 38px;
                }
                .feedback-footer {
                    .author {
                        .name {
                            font-size: 14px;
                            line-height: 27px;
                        }
                        .role {
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        .summary {
            margin: 70px auto 70px;
            .rating {
                margin-bottom: 10px;
            }
            .description {
                font-size: 14px;
                font-weight: 600;
                height: 16px;
                letter-spacing: 0px;
            }
        }
    }
}