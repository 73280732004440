.campaign-page {
  .image-header-wrapper.wrapper {
    padding: 40px 20px 70px;
    @screen xl {
      padding: 0px 40px 240px;
    }

    span.squiggle {
      display: none;
      left: 0;
      bottom: 0;

      svg {
        width: 124px;
        height: 148px;
      }

      @screen lg {
        display: block;
        bottom: -10px;
        left: 70px;

        svg {
          transform: scale(1.2);
        }
      }
      @screen xl {
        left: 0;
        bottom: 0;
        transform: translate(-65%, -50%);

        svg {
          transform: scale(1.5);
        }
      }
    }

    .image-header {
      padding: 50px 0;
      &:after {
        display: none !important;
      }

      .image-header-image {
        width: 100% !important;
        padding: 0 0 20px;
      }
    }
    .image-header-content {
      padding: 0 !important;
      @media (min-width: 768px) and (max-width: 1023px) {
        h1 {
          font-size: 40px;
          line-height: 50px;
        }
      }
      p {
        padding: 0;
      }

      div {
        @screen xl {
          padding-left: 20px;
          margin-right: 110px;
        }
      }
    }

    span.arrows {
      top: -30px;
      left: -100px;

      svg path {
        fill: var(--theme-primary);
        &:last-child {
          fill: var(--theme-secondary);
        }
      }
    }
  }
  &.theme-apps {
    .image-header-wrapper.wrapper {
      .image-header {
        padding: 0;
        padding-bottom: 0 !important;
      }
    }
  }
  &.theme-master {
    .image-header-wrapper.wrapper {
      @screen lg {
        padding: 30px 20px 190px;
      }
      .image-header {
        padding: 0;
      }
    }
  }
  &.theme-funnel {
    .image-header {
      padding: 10px 0 0;
    }
  }
  &.theme-website {
    @screen lg {
      padding: 0px 20px 140px;
    }
  }
}
