.home-matrix-cta, .matrix-cta {
    background-color: #283a5d;
    padding: 178px 40px;
    @media (max-width: 768px) {
        padding: 178px 20px;
    }
    .home-cta {
        width: 100%;
        max-width: 844px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        * {
            color: white;
        }
        .digistorm-logo {
            margin-bottom: 65px;
            @media (max-width: 768px) {
                margin-bottom: 32px;
            }
            .new-digi-logo {
                max-width: 60px;
                max-height: 60px;
            }
        }
        .button {
            position: relative;
            z-index: 1;
            color: white;
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0px;
            line-height: 16px;
            text-align: center;
            border-radius: 4px;
            padding: 17px 28px;
            overflow: hidden;
            border-radius: 4px;
            margin-top: 46px;
            height: 50px;
            @media (max-width: 768px) {
                margin-top: 30px;
            }
            .button-text {
                position: relative;
            }
            .button-bg {
                position: absolute;
                border-radius: 4px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #3e8af6;
                filter: brightness(100%);
                transition: filter .3s;
            }
            &:hover {
                .button-bg {
                    filter: brightness(92%);
                }
            }
        }
        .cta-text {
            * {
                font-family: Barlow;
            }
        }
    }
}