.matrix-careers {
    .module-careers {
        .container {
            padding-top: 50px;
            @screen md {
                padding-top: 180px;
            }
        }
    }
    .career-header {
        .details {
            padding-right: 40px;
            h2 {
                font-size: 30px;
                letter-spacing: 0px;
                line-height: 36px;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 30px;
            }
        }
        .career-image {
            @apply flex-shrink-0;
        }
    }
    .jobs {
        padding: 120px 0 150px;
        .job-item {
            width: 390px;
            padding: 130px 58px 55px;
            transition: background 0.4s;
            margin-bottom: 30px;
            h4 {
                font-size: 28px;
                letter-spacing: 0px;
                margin-top: 32px;
                margin-bottom: 105px;
            }
            .job-position {
                font-size: 12px;
                letter-spacing: 2px;
            }
            .job-link {
                letter-spacing: 0px;
            }
            &:hover {
                .job-link {
                    &:after {
                        transform: translate(0px);
                        opacity: 1;
                    }
                }
            }
        }
    }
}