.matrix-twoColumnText {
    .two-columns-content {
        width: 1230px;
        margin: 100px auto;
        @media (max-width: 1230px) {
            width: calc(100% - 80px);
        }
        @media (max-width: 768px) {
            width: calc(100% - 40px);
        }
        .columns {
            display: flex;
            flex-wrap: wrap;
            .column {
                .lead {
                    font-size: 26px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 40px;
                    @media (max-width: 768px) {
                        font-size: 21px;
                        letter-spacing: 0px;
                        line-height: 32px;
                    }
                }
                flex-basis: 50%;
                @media (max-width: 768px) {
                    flex-basis: 100%;
                }
                &.column-one {
                    padding-right: 100px;
                    @media (max-width: 768px) {
                        padding-right: 0px;
                        margin-bottom: 25px;
                    }
                }
                &.column-two {
                    p {
                        font-size: 18px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        line-height: 32px;
                        @media (max-width: 768px) {
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: 0px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }
}