.campaign-page {
  .matrix-customerQuote {
    position: relative;
    padding: 70px 0;
    overflow: hidden;
    @screen md {
      background-color: transparent;
    }
    &.no-gap {
      @screen md {
      background-color: transparent;
      padding: 100px 0 0;
      }
      @screen xl {
        padding: 250px 0 0;
      }
    }
    &.sm-gap {
      @screen md {
      background-color: transparent;
      padding: 100px 0 50px;
      }
      @screen xl {
        padding: 250px 0 100px;
      }
    }
    &.md-gap {
      @screen md {
      background-color: transparent;
      padding: 100px 0 70px;
      }
      @screen xl {
        padding: 250px 0 150px;
      }
    }
    &.lg-gap {
      @screen md {
      background-color: transparent;
      padding: 100px 0 110px;
      }
      @screen xl {
        padding: 250px 0 240px;
      }
    }
    .wrapper {
      padding: 0;
      @screen md {
        background-color: #f6f8fb;
      }
      @screen xl {
        max-width: 1630px;
      }
    }
    .inner-wrapper {
      margin: auto;
      overflow: hidden;
      align-items: flex-end;
      @screen md {
        display: grid;
        overflow: visible;
        padding: 0 40px;
        grid-template-columns: .75fr 1fr;
        grid-template-columns: repeat(12, 1fr);
      }
      @screen xl {
        max-width: 1270px;
      }
    }
    .image {
      position: relative;
      z-index: 1;
      padding: 0 20px;
      @screen md {
        height: 100%;
        padding: 0;
            grid-column: 1/6;
      }
      @screen xl {
        padding: 0;
            grid-column: 1/7;
      }
      img {
        border-radius: 8px;
        width: 100%;
        @screen md {
          height: calc(100% - 70px);
          object-fit: cover;
          max-width: 85.36%;
        }
      }
      &.desktop-img {
        display: none;
        @screen md {
          display: block;
          padding-top: 9.6px;
        }
        @screen xl {
          padding-top: 0;
        }
      }
      &.mobile-img {
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 50%;
          bottom: 0;
          right: 0;
          background-color: #f6f8fb;
          display: block;
          z-index: -1;
          isolation: isolate;
          @screen md {
            display: none;
          }
        }
        @screen md {
          display: none;
        }
      }
    }
    .text {

      @screen md {
        padding: 0;
            grid-column: 6/13;
      }
      @screen xl {
        padding: 0;
            grid-column: 7/13;
      }
    }
    .heading-wrapper {
      position: relative;
      padding: 0 20px 30px;
      @screen md {
        padding: 0;
        margin-bottom: -19px;
      }
      @screen xl {
        padding: 0 0 65px;
        margin-bottom: 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 120vw;
        height: 200%;
        bottom: 19px;
        right: -20vw;
        background-color: #fff;
        display: none;
        @screen md {
          display: block;
        }
        @screen xl {
          bottom: 0;
        }
      }
      .subheading {
        margin: 50px auto 20px;
        display: block;
        text-align: center;
        color: var(--theme-primary);
        @screen md {
          text-align: left;
          margin: 27px 0 15px;
        }
        @screen xl {
          margin: 50px 0 24px;
        }
      }
      h2 {
        text-align: center;
        @screen md {
          text-align: left;
          max-width: 600px;
          font-size: 28px;
          line-height: 40px;
          margin-bottom: 0;
        }
        @screen lg {
          font-size: 32px;
          line-height: 44px;
        }
        @screen xl {
          font-size: 40px;
          line-height: 54px;
          margin-bottom: 0;
        }
      }
      * {
        position: relative;
        z-index: 2;
      }
      .quote {
        text-align: center;
        svg {
          height: 63.58px;
          margin-bottom: 40px;
          margin: 0 auto;
          > g {
            path {
              fill: var(--theme-primary);
              stroke: var(--theme-primary);
            }
            > g {
              fill: var(--theme-dark);
              path {
                stroke: var(--theme-dark);
                fill: var(--theme-dark)
              }
            }
          }
          @screen md {
            margin: 0 0 27.58px 0;
          }
          @screen xl {
            margin: 0 0 40px 0;
          }
        }
      }
    }
    .quote-wrapper {
      position: relative;
      padding: 50px 20px;
      background-color: #f6f8fb;
      @screen md {
        padding: 58px 0 80px;
        max-width: 600px;
      }
      @screen xl {
        padding: 65px 0;
      }

      .quote-logo {
        img {
          min-height: 36px;
          max-height: 50px;
          margin-bottom: 50px;
          @screen md {
            margin-bottom: 40px;
          }
        }
      }
      blockquote {
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
        @screen xl {
          font-size: 28px;
          line-height: 42px;
          margin-bottom: 15px;
        }
      }
      cite {
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        font-style: normal;
        max-width: 65%;
        @screen md {
          max-width: 100%;
        }
        b {
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }
}
