.campaign-page {
    .matrix-highlights {
        &.offers {
            .wrapper {
                .intro {
                    max-width: 810px;
                    margin: 0 auto;
                }
                .content {
                    @screen xl {
                        margin-top: 82px;
                    }
                    .features {
                        margin-top: 0;
                        @screen md {
                            max-width: calc(5 / 12 * 100%);
                            @apply ml-0 mr-auto;
                        }
                        &.col-r {
                            @apply mr-0 ml-auto;
                        }

                        .feature {
                            margin-top: 25px;
                            @screen lg {
                                margin-top: 40px;
                            }
                            &:not(:first-child) {
                                margin-top: 25px;
                                @screen lg {
                                    margin-top: 40px;
                                }
                            }
                            &:first-child {
                                @screen lg {
                                    margin-top: 0;
                                }
                            }
                            svg {
                                width: 36px;
                                height: 36px;
                                color: var(--theme-light);
                                margin-bottom: 16px;
                            }
                            .feature-text {
                                h3 {
                                    margin-bottom: 12px;
                                    @screen lg {
                                        margin-bottom: 16px;
                                    }
                                }
                                p {
                                    @screen md {
                                        min-height: 14em;
                                    }
                                    @screen lg {
                                        min-height: 11em;
                                    }
                                    @screen xl {
                                        min-height: 9em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
