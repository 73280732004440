.content {
    h1 {
        @apply font-barlow font-bold text-blue-800;
        font-size: 48px;
        letter-spacing: 0px;
        margin-bottom: 90px;
    }
    h2 {
        @apply text-grey-600 font-barlow font-semibold;
        font-size: 36px;
        line-height: 42px;
        letter-spacing: 0px;
        margin-bottom: 36px;
    }
    P, li {
        @apply text-grey-600;
        letter-spacing: 0px;
        line-height: 30px;
    }

    a {
        @apply text-blue-400;
        &:hover {
            @apply underline;
        }
    }


    .top-level {
        counter-reset: top-level;
        list-style: none inside;
        > li {
            margin-top: 100px;
            counter-increment: top-level;
            margin-bottom: 30px;
            h2 {
                &:before {
                    content: counter(top-level) ".";
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }
    }
    .second-level {
        counter-reset: second-level;
        list-style: none inside;
        > li {
            counter-increment: second-level;
            margin-bottom: 30px;
            > :first-child {
                &:before {
                    content: counter(second-level,lower-latin) ")";
                    display: inline-block;
                    min-width: 24px;
                }
            }
        }
    }
}

