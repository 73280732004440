.customer-stories-block {
    position: relative;
    &.no-click {
        .story-slide-controls {
            cursor: not-allowed;
            > div {
                pointer-events: none !important;
            }
        }
    }
    .quote-wrapper {
        height: 145px;
        position: relative;
        .quote-background {
            background: #F6F8FB;
            height: 70px;
        }
        .quote {
            position: absolute;
            left: 50%;
            top: 20px;
            transform: translateX(-50%);
        }
        @media (max-width: 768px) {
            height: 120px;
            .quote {
                svg, img {
                    width: 120px;
                }
            }
        }
    }
    .heading-wrapper {
        margin-bottom: 80px;
        text-align: center;
        @media (max-width: 992px) {
            margin-bottom: 57px;
        }
        h2 {
            margin-bottom: 24px;
            @media (max-width: 768px) {
                width: 265px;
                margin-left: auto;
                margin-right: auto;
            }
            span {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    pointer-events: none;
                }
                &.apps {
                    &:after {
                        top: -4px;
                        left: -6px;
                        bottom: -6px;
                        right: -6px;
                        // <svg width="96" height="51" viewBox="0 0 96 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M67.8113 2.42981C50.0559 -0.938573 9.7093 3.74221 3.06523 21.772C-1.47721 34.0985 5.2202 48.2227 35.6701 49.408C59.6246 50.3405 92.4157 48.1599 94.1642 27.8662C95.7037 10.0389 66.0101 3.36498 47.7041 5.75332" stroke="#2B3A5A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTYiIGhlaWdodD0iNTEiIHZpZXdCb3g9IjAgMCA5NiA1MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNjcuODExMyAyLjQyOTgxQzUwLjA1NTkgLTAuOTM4NTczIDkuNzA5MyAzLjc0MjIxIDMuMDY1MjMgMjEuNzcyQy0xLjQ3NzIxIDM0LjA5ODUgNS4yMjAyIDQ4LjIyMjcgMzUuNjcwMSA0OS40MDhDNTkuNjI0NiA1MC4zNDA1IDkyLjQxNTcgNDguMTU5OSA5NC4xNjQyIDI3Ljg2NjJDOTUuNzAzNyAxMC4wMzg5IDY2LjAxMDEgMy4zNjQ5OCA0Ny43MDQxIDUuNzUzMzIiIHN0cm9rZT0iIzJCM0E1QSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
                    }
                }
                &.funnel {
                    &:after {
                        top: -13px;
                        left: -4px;
                        bottom: -10px;
                        right: -4px;
                        // <svg width="133" height="52" viewBox="0 0 133 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M93.9475 2.47726C69.3489 -0.957168 13.452 3.81539 4.24721 22.1987C-2.04597 34.7669 7.23274 49.1681 49.4186 50.3766C82.6055 51.3274 128.035 49.1041 130.457 28.4124C132.59 10.2356 91.452 3.43077 66.0906 5.86594" stroke="#2B3A5A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMzIiBoZWlnaHQ9IjUyIiB2aWV3Qm94PSIwIDAgMTMzIDUyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik05My45NDc1IDIuNDc3MjZDNjkuMzQ4OSAtMC45NTcxNjggMTMuNDUyIDMuODE1MzkgNC4yNDcyMSAyMi4xOTg3Qy0yLjA0NTk3IDM0Ljc2NjkgNy4yMzI3NCA0OS4xNjgxIDQ5LjQxODYgNTAuMzc2NkM4Mi42MDU1IDUxLjMyNzQgMTI4LjAzNSA0OS4xMDQxIDEzMC40NTcgMjguNDEyNEMxMzIuNTkgMTAuMjM1NiA5MS40NTIgMy40MzA3NyA2Ni4wOTA2IDUuODY1OTQiIHN0cm9rZT0iIzJCM0E1QSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
                    }
                }
                &.websites {
                    &:after {
                        top: -6px;
                        left: -4px;
                        bottom: -5px;
                        right: -5px;
                        // <svg width="168" height="52" viewBox="0 0 168 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M118.671 2.47738C87.5988 -0.957046 16.9923 3.81552 5.36513 22.1988C-2.58415 34.7671 9.13633 49.1682 62.4237 50.3768C104.344 51.3275 161.729 49.1042 164.788 28.4125C167.483 10.2357 115.519 3.43089 83.4831 5.86606" stroke="#2B3A5A" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY4IiBoZWlnaHQ9IjUyIiB2aWV3Qm94PSIwIDAgMTY4IDUyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMTguNjcxIDIuNDc3MzhDODcuNTk4OCAtMC45NTcwNDYgMTYuOTkyMyAzLjgxNTUyIDUuMzY1MTMgMjIuMTk4OEMtMi41ODQxNSAzNC43NjcxIDkuMTM2MzMgNDkuMTY4MiA2Mi40MjM3IDUwLjM3NjhDMTA0LjM0NCA1MS4zMjc1IDE2MS43MjkgNDkuMTA0MiAxNjQuNzg4IDI4LjQxMjVDMTY3LjQ4MyAxMC4yMzU3IDExNS41MTkgMy40MzA4OSA4My40ODMxIDUuODY2MDYiIHN0cm9rZT0iIzJCM0E1QSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
                    }
                }
            }
        }
    }
    .stories-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        padding-bottom: 20px; // offset the overhang on the grey bg
        @media (max-width: 1280px) {
            margin-left: 40px;
            margin-right: 40px;
        }
        @media (max-width: 768px) {
            margin-left: 20px;
            margin-right: 20px;
        }
        .stories-wrapper-inner {
            position: relative;
            width: 100%;
            max-width: 1230px;
            min-height: 530px; // starting height, will be overriden by JS on page load
            $transitionTime: 0.8s;
            transition: height 0.4s ease-out;
            .background-element {
                z-index: 1;
                position: absolute;
                background: #F6F8FB;
                top: 71px;
                left: 0;
                right: 76px;
                bottom: -19px;
                @media (max-width: 992px) {
                    top: 58px;
                    right: 0px;
                    bottom: 0px;
                }
            }
            .story-item {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                z-index: 15;
                @media (min-width: 992px) {
                    width: 100%;
                }
                @media (max-width: 992px) {
                    flex-direction: column;
                    left: 20px;
                    right: 20px;
                }
                &.active {
                    pointer-events: initial;
                    z-index: 99;
                    .story-text {
                        opacity: 1;
                        .story-text-inner {
                            opacity: 1;
                            transform: translateY(0px);
                            transition: transform $transitionTime, opacity $transitionTime;
                            transition-delay: 0s;
                        }
                    }
                    .story-image {
                        .background-item {
                            opacity: 1;
                        }
                        .story-image-outer {
                            .story-image-inner {
                                clip-path: inset(0 0 0 0);
                                transition: 0.5s clip-path cubic-bezier(0.65, 0.05, 0.36, 1), transform 0.3s;
                            }
                        }
                    }
                    .story-slide-controls {
                        opacity: 1;
                    }
                }
                $imageWidth: 51.25%;
                .story-text {
                    flex-basis: calc(100% - #{$imageWidth});
                    flex-shrink: 0;
                    flex-grow: 0;
                    margin-top: 70px;
                    max-height: 480px;
                    padding: 80px 90px 100px 70px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    overflow: hidden;
                    opacity: 0;
                    transition: opacity 0.3s;
                    @media (max-width: 1280px) {
                        padding: 80px 45px 100px 70px;
                    }
                    @media (max-width: 992px) {
                        order: 2;
                        max-height: none;
                        margin-top: 0px;
                        padding: 36px 4px 88px;
                    }
                    .story-text-inner {
                        transition: transform 0s, opacity 0s;
                        opacity: 0;
                        transform: translateY(-30px);
                        transition-delay: $transitionTime;
                        @media (max-width: 992px) {
                            transform: translateY(-20px);
                        }
                        img {
                            min-height: 46px;
                            max-height: 60px;
                            display: inline-block;
                            margin-bottom: 28px;
                            @media (max-width: 992px) {
                                min-height: 36px;
                                margin-bottom: 25px;
                                max-height: 50px;
                            }
                        }
                        a {
                            h3 {
                                margin-bottom: 16px;
                                @media (max-width: 992px) {
                                    margin-bottom: 12px;
                                    font-size: 24px;
                                    line-height: 34px;
                                }
                                span {
                                    transition: border-color 0.4s;
                                    border-bottom: 2px solid rgba(43, 58, 90, 0);
                                }
                            }
                            &:hover {
                                h3 {
                                    span {
                                        border-bottom: 2px solid rgba(43, 58, 90, 1);
                                    }
                                }
                            }
                        }
                        p {
                            margin-bottom: 32px;
                            @media (max-width: 992px) {
                                margin-bottom: 20px;
                                font-size: 16px;
                                line-height: 28px;
                            }
                        }
                    }
                }
                .story-image {
                    flex-basis: $imageWidth;
                    flex-shrink: 0;
                    flex-grow: 0;
                    position: relative;
                    z-index: 10;
                    transition: opacity $transitionTime;
                    @media (max-width: 992px) {
                        order: 1;
                    }
                    .story-image-outer {
                        display: block;
                        height: 100%;
                        z-index: 10;
                        position: relative;
                        @media (max-width: 992px) {
                            padding-left: 4px;
                            padding-right: 4px;
                        }
                        a.story-image-mask {
                            display: block;
                            height: 87.2%;
                            border-radius: 4px;
                            overflow: hidden;
                            &:hover {
                                @media (min-width: 992px) {
                                    .story-image-inner {
                                        transform: scale(1.05);
                                        &:before {
                                            background-color: rgba(0,0,0,0.2);
                                        }
                                    }
                                }
                            }
                        }
                        .story-image-inner {
                            position: relative;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 100%;
                            height: 100%;
                            clip-path: inset(0 100% 0 0);
                            transition: 0s clip-path cubic-bezier(0.65, 0.05, 0.36, 1);
                            transition-delay: $transitionTime;
                            // this fixes some random scale glitchiness in chrome
                            -webkit-backface-visibility: hidden;
                            -webkit-transform: translateZ(0) scale(1, 1);
                            @media (max-width: 992px) {
                                padding-bottom: 76.5%;
                            }
                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0,0,0,0);
                                transition: background-color 0.3s;
                            }
                        }
                    }
                }
                .story-slide-controls {
                    position: absolute;
                    bottom: 32px;
                    right: 52px;
                    z-index: 15;
                    width: 155px;
                    height: 53px;
                    display: flex;
                    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.06));
                    @media (max-width: 992px) {
                        bottom: -14px;
                        right: -4px;
                        opacity: 0;
                        transition: opacity $transitionTime;
                    }
                    &.controls-disabled {
                        pointer-events: none;
                        svg {
                            opacity: 0.3;
                        }
                    }
                    > div {
                        width: 33.333%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFF;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        letter-spacing: 2px;
                        &.controls-index {
                            color: rgba(#494B50, 0.9);
                            background: #FFF;
                        }
                        &.controls-button {
                            position: relative;
                            cursor: pointer;
                            z-index: 10;
                            &:before {
                                z-index: 1;
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0,0,0,0);
                                transition: background-color 0.3s;
                            }
                            &:hover {
                                &:before {
                                    background-color: rgba(0,0,0,0.125);
                                }
                            }
                        }
                        svg {
                            z-index: 10;
                            max-width: 22px;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
    .summary {
        margin: 80px auto 130px;
        .rating {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 21px;
            .stars {
                display: flex;
                .star {
                    position: relative;
                    margin-right: 5px;
                    .filled-star {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        overflow: hidden;
                    }
                    .empty-star {
                        opacity: 0.1;
                    }
                    @for $i from 0 through 10 {
                        &.fill-#{$i} {
                            .filled-star {
                                width: 10% * $i;
                            }
                        }
                    }
                }
            }
            .number {
                margin-left: 6px;
                color: rgb(43, 58, 90);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0px;
            }
        }
        .feedback-image {
            text-align: center;
            margin-bottom: 20px;
            img {
                width: 84px;
                display: inline-block;
                padding-right: 20px;
                margin: 0 auto;
                &:last-child {
                    padding-right: 0px;
                    width: 64px;
                }
            }
        }
        .description {
            color: rgb(43, 58, 90);
            font-weight: 400;
            letter-spacing: 0px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            p {
                font-size: 14px;
                font-weight: 600;
            }
            a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .summary {
            margin: 70px auto 70px;
            .rating {
                margin-bottom: 10px;
            }
            .description {
                font-size: 14px;
                font-weight: 600;
                height: 16px;
                letter-spacing: 0px;
            }
        }
    }
}