.matrix-modules {
    background: #F6F8FB;
    .modules-content {
        width: 1230px;
        padding: 190px 0 80px;
        @media (min-width: 768px) and (max-width: 1300px) {
            padding-left: 40px;
            padding-right: 40px;
        }
        margin: auto;
        display: flex;
        .text {
            padding-top: 66px;
            width: 495px;
            .subheading {
                margin-bottom: 16px;
            }
        }
        .modules {
            width: 600px;
            margin-top: -30px;
            margin-left: 60px;
            display: flex;
            flex-direction: row-reverse;
            position: relative;
            .squiggle {
                position: absolute;
                top: 0;
                left: 0;
                margin-top: -60px;
                margin-left: -100px;
            }
            .module-column {
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                &:nth-last-child(1) {
                    padding-top: 150px
                }
                &:nth-last-child(2) {
                    padding-top: 81px;
                }
                &:nth-last-child(4) {
                    padding-top: 66px;
                }
                .module {
                    margin-bottom: 20px;
                    width: 120px;
                    height: 120px;
                    background: #FFFFFF;
                    box-shadow: 0 5px 27px 0 rgba(10,63,138,0.05);
                    border-radius: 8px;
                    font-size: 10px;
                    color: #2B3A5A;
                    letter-spacing: 0.6px;
                    text-align: center;
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .label {
                        margin-top: 14px;
                        margin-bottom: -14px;
                        font-weight: 500;
                        line-height: 14px;
                        padding: 0 10px;
                        @media (max-width: 768px) {
                            margin-top: 4px;
                        }
                    }
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                    .streamline-stroke-icon {
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        text-align: center;
                    }
                }
            }
        }
        @media (max-width: 1230px) {
            width: 100%;
            justify-content: space-around;
            .modules {
                width: auto;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            padding: 20px 0;
            .modules {
                width: 100%;
                margin-left: 0px;
                justify-content: center;
                margin-bottom: 60px;
                margin-top: 30px;
                .module-column {
                    margin-left: 13px;
                    &:last-child {
                        margin-left: 0px;
                    }
                    &:nth-last-child(1) {
                        padding-top: 91px;
                    }
                    &:nth-last-child(2) {
                        padding-top: 49px;
                    }
                    &:nth-last-child(4) {
                        padding-top: 39px;
                    }
                    .module {
                        height: 74px;
                        width: 74px;
                        border-radius: 6px;
                        box-shadow: 0px 3px 19px 0px rgba(10, 63, 138, 0.05);
                        font-size: 6px;
                        margin-bottom: 13px;
                        svg {
                            width: 18px;
                            max-height: 20px;
                        }
                    }
                }
            }
            .squiggle {
                position: absolute;
                top: 0;
                left: 0;
                margin-top: -60px;
                margin-left: -100px;
                width: 150px;
                @media (max-width: 768px) {
                    width: 120px;
                    top: 94% !important;
                    left: 83% !important;
                    transform: rotate(142deg);
                }
                svg {
                    width: 100%;
                }
            }
            .text {
                width: 100%;
                padding: 20px;
            }
        }
    }
}